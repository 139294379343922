import {createStore} from 'redux';

let initState = {
	applicant : null,
	auth : {
		authEnabled : null,
		applicantId : '',
	}
};

export function applicantInfo (applicant) {
	
	return {
		type      : 'APPLICANT_INFO',
		applicant : applicant,
	};
}

export function twoFactAuth (auth) {

	return {
		type : 'TWO_AUTH',
		auth : auth,
	};
}

function authReducer (authStore = initState, action) {
	
	switch (action.type) {

		case 'APPLICANT_INFO' :
			return {
				...authStore,
				applicant : action.applicant,
			};

		case 'TWO_AUTH' :
			return {
				...authStore,
				auth : {
					authEnabled : action.auth.authEnabled,
					applicantId : action.auth.applicantId,
				}
			};

		default :
			return authStore;
	}
}

const authStore = createStore (authReducer, initState);

export default authStore;
