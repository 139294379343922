import React       from 'react';
import Grid        from '@material-ui/core/Grid';
import Typography  from '@material-ui/core/Typography';
import {styles}    from './style';

function Deadline () {
	
	return (
		<Grid container spacing  = {3} alignItems = 'center' className = 'parent-content p-24' justify = 'center' style = {styles.mainGrid}>
			<Typography variant = 'h4'> Deadline for this application form has been ended. Kindly contact admin to get new application form.</Typography>
		</Grid>
	);
}

export default Deadline;
