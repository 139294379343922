import React                  from 'react';
import ReactHtmlParser        from 'react-html-parser';
import Grid                   from '@material-ui/core/Grid';
import Button                 from '@material-ui/core/Button';
import Typography             from '@material-ui/core/Typography';
import Paper                  from '@material-ui/core/Paper';
import Divider                from '@material-ui/core/Divider';

import { styles }             from './style';

function Instruction (props) {

	const {introText, introVideo, onStartExam} = props;

	return (
		<Paper variant = 'outlined' className = 'p-12' style = {{width : '100%'}}>
			<Grid>
				<Typography variant = 'h5'> Introduction </Typography>
				<Divider className = 'mt-8 mb-8'/>
				<Typography variant = 'body1'> {ReactHtmlParser (introText)}</Typography>
			</Grid>
			{introVideo ? <Grid className = 'mt-12'>
				<Typography variant = 'h5'> Introduction Video </Typography>
				<Divider className = 'mt-8 mb-8'/>
				<Grid container justify = 'center'>
					<Grid item xs = {false} sm = {false} md = {2} lg = {3} xl = {4}>
					</Grid>
					<Grid item xs = {12} sm = {12} md = {8} lg = {6} xl = {4}>
						<video width = "100%" height = "300" autoPlay controls>
							<source src = {introVideo} type="video/mp4" />
						</video>
					</Grid>
					<Grid item xs = {false} sm = {false} md = {2} lg = {3} xl = {4}>
					</Grid>
				</Grid>
			</Grid> : null}
			<Grid container className = 'mt-24' justify = 'center'>
				<Button onClick = {onStartExam} color = 'primary' variant = 'contained' style = {styles.mainBtn}> Start </Button>
			</Grid>
		</Paper>
	);
}

export default Instruction;
