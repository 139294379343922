const fonts = {

	h1 : {
		fontFamily    : 'var(--primary-bold)',
		fontSize      : '3rem',
		fontStretch   : 'normal',
		lineHeight    : 'normal',
		letterSpacing : 'normal',
	},
	h2 : {
		fontFamily    : 'var(--primary-bold)',
		fontSize      : '2.750rem',
		fontStretch   : 'normal',
		lineHeight    : 'normal',
		letterSpacing : 'normal',
	},
	h3 : {
		fontFamily    : 'var(--primary-bold)',
		fontSize      : '2rem',
		fontStretch   : 'normal',
		lineHeight    : 'normal',
		letterSpacing : 'normal',
	},
	h4 : {
		fontFamily    : 'var(--primary-medium)',
		fontSize      : '1.50rem',
		fontStretch   : 'normal',
		lineHeight    : 'normal',
		letterSpacing : 'normal',
	},
	h5 : {
		fontFamily    : 'var(--primary-medium)',
		fontSize      : '1.25rem',
		fontStretch   : 'normal',
		lineHeight    : 'normal',
		letterSpacing : 'normal',
	},
	h6 : {
		fontFamily    : 'var(--primary-medium)',
		fontSize      : '1rem',
		fontStretch   : 'normal',
		lineHeight    : 'normal',
		letterSpacing : 'normal',
	},
	subtitle1 : {
		fontSize : '1rem',
		fontFamily : 'var(--primary-regular)',
		position   : 'relative',
	},
	subtitle2 : {
		fontSize   : '0.875rem',
		fontFamily : 'var(--primary-medium)',
		fontWeight : '600',
		position   : 'relative',
	},
	body1 : {
		fontFamily    : 'var(--primary-regular)',
		fontSize      : '0.875rem',
		lineHeight    : '1.88',
		fontStretch   : 'normal',
		letterSpacing : 'normal',
	},
	body2 : {
		fontFamily    : 'var(--primary-regular)',
		fontSize      : '0.875rem',
		lineHeight    : '1.88',
		fontStretch   : 'normal',
		letterSpacing : 'normal',
	},
	button : {
		fontFamily    : 'var(--primary-medium)',
		fontSize      : '14px',
		fontStretch   : 'normal',
		lineHeight    : 'normal',
		letterSpacing : 'normal',
		textTransform : 'none',
	},
	caption : {
		fontFamily    : 'var(--primary-regular)',
		fontSize      : '12px',
		lineHeight    : '1.88',
		fontStretch   : 'normal',
		letterSpacing : 'normal',
	}
};

export default fonts;
