import React             from 'react';
import moment            from 'moment';
import Grid              from '@material-ui/core/Grid';
import Typography        from '@material-ui/core/Typography';
import AppBar            from '@material-ui/core/AppBar';
import Toolbar           from '@material-ui/core/Toolbar';
import IconButton        from '@material-ui/core/IconButton';
import Close             from '@material-ui/icons/Close';

function TopBar ({endTime, title, onExitTest}) {
	
	const styles = {
		exitBtn : {
			color: 'white',
			border: '2px solid white',
			padding: '0px 6px',
			borderRadius: '10px',
			height : '32px',
			width : '32px'
		},
		exitGrid : {
			textAlign : 'right',
		},
		text : {
			fontSize : '1.1rem',
		},
		appBar : {
			maxHeight : '64px',
			overflow : 'auto',
		},
	};

	return (
		<AppBar position = 'fixed' color = 'secondary' style = {styles.appBar}>
			<Toolbar style = {{justifyContent : 'flex-end'}} disableGutters = {false}>
				<Grid container spacing = {3} alignItems = 'center'>
					<Grid item sm = {3}>
						<Typography variant = 'h5' align = 'left'> {title}</Typography>
					</Grid>
					<Grid item sm = {6}>
						<Typography variant = 'h5' align = 'center'> Deadline: {endTime ? moment (endTime).format ('lll') : ''}</Typography>
					</Grid>
					<Grid item sm = {3} style = {styles.exitGrid}>
						<IconButton size = 'small' onClick = {onExitTest} style = {styles.exitBtn}> <Close /> </IconButton>
					</Grid>
				</Grid>
			</Toolbar>
		</AppBar>
	);
}

export default TopBar;
