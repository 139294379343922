import React                                          from 'react';
import swal                                           from 'sweetalert';
import {withSnackbar}                                 from 'notistack';
import Grid                                           from '@material-ui/core/Grid';
import Typography                                     from '@material-ui/core/Typography';
import Hidden                                         from '@material-ui/core/Hidden';

import TopNav                                         from 'components/molecules/TopNav';
import MobileNav                                      from 'components/molecules/MobileNav';
import apis                                           from 'common/apis';
import Log                                            from 'common/log';
import utility                                        from 'common/utility';
import {errorMsg}                                     from 'common/errors';
import interviewStore, {interviewsList, updateRating} from 'redux/interviewStore';
import { updateReadyStatus }                          from 'redux/interviewStore';
import commonStore                                    from 'redux/commonStore';
import TestModal                                      from 'components/molecules/TestModal';
import Skeleton                                       from './Skeleton';
import CompanyCard                                    from './CompanyCard';
import Header                                         from './Header';
import RankModal                                      from './RankModal';

const log = Log ('Interview', 'info');

class Interview extends React.Component {

	constructor (props) {
		super (props);

		this.state = {
			interviews : [],
			mobileNav  : false,
			loading    : false,
			isFetched  : false,
			langCode   : 'en',
			rankModal  : {
				isOpen   : false,
				eventId  : null,
				jobId    : null,
				jobIndex : null,
				ratings  : null,
			},
			testModal     : null,
		};
		this.notify = this.props.enqueueSnackbar;
	}

	componentDidMount = () => {
		this.commonUnsub = commonStore.subscribe (this.getLanguage);
		this.interviewUnsub = interviewStore.subscribe (this.getInterviews);
		this.getLanguage ();
		this.getInterviews ();
	}

	componentWillUnmount = () => {
		this.commonUnsub ();
	}

	getLanguage = () => {
		let commonInfo = commonStore.getState ();
		let langCode = commonInfo.langCode;
		this.setState ({
			langCode
		});
	}

	handleMobileNav = () => {

		this.setState ({
			mobileNav : !this.state.mobileNav,
		});
	}

	closeModal = () => {
		
		this.setState ({
			rankModal  : {
				isOpen   : false,
				eventId  : null,
				jobId    : null,
				jobIndex : null,
				ratings  : null,
			}
		});
	}

	onUpdateRank = (jobId, eventId, jobIndex, ratings) => {
		
		this.setState ({
			rankModal : {
				isOpen : true,
				jobId,
				eventId,
				jobIndex,
				ratings,
			}
		});
	}

	getInterviews = async () => {
		
		this.setState ({
			loading : true,
		});

		try {
			let	result = await apis.getInterviews ();
			if (!result) {
				return;
			}
			if (result.errors) {
				this.notify (result.errors, {variant : 'error'});
				return;
			}
			log.info ({interviews : result}, 'interviews get ok');
			this.setState ({
				interviews : result,
				loading    : false,
				isFetched  : true,
			});
		}
		catch (err) {
			log.error ({err}, 'error in fetching job interviews');
			if (err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			return;
		}
	}

	onConfirmDateTime = async (jobId, interviewId, interview_time_confirm_status) => {
		
		if (!interviewId) {
			this.notify ('You do not have any interview', {variant : 'info'});
			return;
		}

		let conirmStatus = !interview_time_confirm_status ? 1 : 0;
		let data = {
			interview : {
				id : interviewId,
				interview_time_confirm_status : conirmStatus
			}
		};
		let result;
		try {
			result = await apis.confirmDateAndTime (data);
			log.info ({ready : result}, 'imready okk');
		}
		catch (err) {
			log.error ({err}, 'error in confirm status');
			if (err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify('Something went wrong', {variant : 'error'});
			return;
		}
		if (result.errors) {
			this.notify (result.errors, {variant : 'error'});
			return;
		}
		this.getInterviews ();
	}

	onReady = async (jobId, interviewId, ready_status, jobIndex) => {
		
		if (!interviewId) {
			this.notify ('You do not have any interview scheduled for today', {variant : 'info'});
			return;
		}

		swal ({
			text : 'Are you ready for this interview?',
			icon : 'warning',
			buttons : true,
		}).then (async(onOk) => {
			if (onOk) {
				let readyStatus = ready_status === false ? 1 : 0;
				let data = {
					interview : {
						id : interviewId,
						i_am_ready : 1,
						ready_status : readyStatus,
					}
				};
				let result;
				try {
					result = await apis.imready (data);
					log.info ({ready : result}, 'imready okk');
				}
				catch (err) {
					log.error ({err}, 'error in imready');
					if (err.response.status === 401) {
						this.notify (errorMsg.logoutMsg, {variant : 'error'});
						utility.redirectLogin ();
						return;
					}
					this.notify('Something went wrong', {variant : 'error'});
					return;
				}
				if (result.errors) {
					this.notify (result.errors, {variant : 'error'});
					return;
				}

				interviewStore.dispatch(updateReadyStatus(jobId, interviewId, !ready_status, jobIndex));
			}
		});
	}

	onSubmitRating = async (data) => {
	
		let result;
		try {
			result = await apis.submitRating (data);
			log.info ({ratings : result}, 'submit rating ok');
		}
		catch (err) {
			log.error ({err}, 'error submiting ratings');
			if (err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify ('Something went wrong', {variant : 'error'});
			return;
		}
		this.notify (result.message, {variant : 'success'});
		let updated = {
			ratings : {
				rate    : result.rate,
				rank    : result.rank,
				comment : result.comment,
			},
			job_id  : result.job_id,
		};

		interviewStore.dispatch (updateRating (updated, this.state.rankModal.jobIndex));
	}

	onStartTest = (job) => {
		this.setState ({
			testModal : job,
		});
	}

	handleCheckbox = (event, que) => {
		let {checked, value} = event.target;
		let _job = this.state.testModal;
		let questions = _job.questions;
		let queIndex = questions.findIndex (q => q.question_id === que.question_id);
		if (queIndex < 0) {
			return;
		}
		que.answer = checked ? [...que.answer, value] : que.answer.filter (item => item !== value);
		questions[queIndex] = que;
		_job.questions = questions;

		this.setState ({
			testModal : _job,
		});
	}

	handleDropDown = (name, selected, que) => {

		let _job = this.state.testModal;
		let questions = _job.questions;
		let queIndex = questions.findIndex (q => q.question_id === que.question_id);
		if (queIndex < 0) {
			return;
		}

		que.answer = selected.value;

		questions[queIndex] = que;
		_job.questions = questions;
		this.setState ({
			testModal : _job,
		});
	}

	handleChange = (event, que) => {

		let _job = this.state.testModal;
		let questions = _job.questions;
		let queIndex = questions.findIndex (q => q.question_id === que.question_id);
		if (queIndex < 0) {
			return;
		}

		if (que.question_type === 'radio_button') {
			que.answer = [event.target.value];
		}

		if (que.question_type === 'free_text') {
			que.answer = event.target.value;
		}

		questions[queIndex] = que;
		_job.questions = questions;
		this.setState ({
			testModal : _job,
		});
	}

	submitAnswer = async () => {
		if (!this.state.testModal) {
			return;
		}
		let _job = this.state.testModal;
		let question = _job.questions;

		let dataArray = [];
		for (let i = 0; i < question.length; i++) {
			let answer = question[i].answer;

			if (question[i].question_type === 'check_box') {
				let ans = [];
				for (let j = 0; j < question[i].answer.length; j++) {
					let _op = question[i].options.find (q => q.option_name === question[i].answer[j]);
					if (_op) {
						ans.push (_op.option_id);
					}
				}
				answer = ans.join (',');
			}

			if (question[i].question_type === 'radio_button') {
				let _op = question[i].options.find (q => q.option_name === question[i].answer[0]);
				if (_op) {
					answer = `${_op.option_id}`;
				}
			}

			if (question[i].question_type === 'drop_down') {
				let _op = question[i].options.find (q => q.option_name === question[i].answer);
				if (_op) {
					answer = `${_op.option_id}`;
				}
			}

			let jsonData = {
				selection : {
					selection_id      : _job.selection_id,
					question_id       : question[i].question_id,
					answer            : answer,
				}
			};

			dataArray.push (jsonData);
		}

		this.setState ({
			submitLoading : true,
		});

		let result;
		try {
			for(let i = 0; i< dataArray.length; i++) {
				result = await apis.submitSelection (dataArray[i]);
				log.info ({answer : result}, 'answer submitted ok');
			}
		}
		catch (err) {
			log.error ({err}, 'err submitting answer');
			if (err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify ('Failed to submit answer, Please try again', {variant : 'error'});
			this.setState ({
				submitLoading : false,
			});
			return;
		}

		this.setState ({
			submitLoading : false,
		});

		let finalSubmit;
		let finalData = {
			selection : {
				selection_id  : _job.selection_id,
				mark_as_done  : true,

			}
		};

		try {
			finalSubmit = await apis.submitSelection (finalData);
			if (finalSubmit.success)
				this.notify (finalSubmit.message, {variant : 'success'});
		}
		catch (err) {
			log.error ({err}, 'err submitting answer');
			if (err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify ('Failed to submit answer, Please try again', {variant : 'error'});
			this.setState ({
				submitLoading : false,
			});
			return;
		}

		let	_interviews = await apis.getInterviews ();
		if (!_interviews) {
			return;
		}
		if (_interviews.errors) {
			this.notify (_interviews.errors, {variant : 'error'});
			return;
		}
		this.setState ({
			interviews : _interviews,
			loading    : false,
			isFetched  : true,
			submitLoading : false,
			testModal : null,
		});
	}

	onSubmit = () => {
		swal ({
			title   : 'Submit',
			text    : 'Are you sure, you want to submit the answer?',
			icon    : 'info',
			buttons : ['Cancel', 'OK']
		}).then (async (res) => {
			if (!res)  {
				return;
			}
			this.submitAnswer ();
		});
	}

	renderInterviews = () => {
		
		if (!this.state.interviews.length) {
			return (
				<Typography variant = 'h4' align = 'center' style = {{color : '#6b6b6b'}}> {this.state.langCode === 'en' ? 'There are currently no interviews' : ' 現時点で面接の予定は入っておりません'}  </Typography>
			);
		}

		let __interviews= [];
		for (let i = 0; i<this.state.interviews.length; i++) {
			if (!this.state.interviews[i].length) {
				continue;
			}
			__interviews = [...__interviews, ...this.state.interviews[i]];
		}

		if (!__interviews.length) {
			return (
				<Typography variant = 'h4' align = 'center' style = {{color : '#6b6b6b', marginTop : '30px'}}> {this.state.langCode === 'en' ? 'There are currently no interviews' : ' 現時点で面接の予定は入っておりません'} </Typography>
			);
		}

		return (
			this.state.interviews.map ((companies, index) => (
				<Grid key = {index}>
					{Array.isArray(companies) &&  companies.map (company => (
						<CompanyCard
							langCode          = {this.state.langCode}
							startTest         = {this.onStartTest}
							company           = {company}
							key               = {company.job_id}
							onReady           = {this.onReady}
							onConfirmDateTime = {this.onConfirmDateTime}
							jobIndex          = {index}
							onUpdateRank      = {this.onUpdateRank}
						/>
					))}
				</Grid>
			))
		);
	}

	render () {

		return (
			<Grid className = 'parent-content'>
				<TopNav name = '' mobileNav = {this.handleMobileNav}/>
				<Hidden only = {['md', 'lg', 'xl']}>
					{this.state.mobileNav ? <MobileNav /> : null}
				</Hidden>
				<Grid className = {`actual-content ${this.state.mobileNav ? 'mobile-nav' : ''}`}>
					<Header langCode = {this.state.langCode} />
					{this.state.loading ?
						<Skeleton />
						:
						this.renderInterviews ()}
				</Grid>
				{this.state.rankModal.isOpen ?
					<RankModal
						jobId          = {this.state.rankModal.jobId}
						eventId        = {this.state.rankModal.eventId}
						onClose        = {this.closeModal}
						onSubmitRating = {this.onSubmitRating}
						ratings        = {this.state.rankModal.ratings}
					/>
					: null
				}
				{this.state.testModal ?
					<TestModal
						questions      = {this.state.testModal.questions}
						jobId          = {this.state.testModal.job_review_id}
						handleChange   = {this.handleChange}
						handleCheckbox = {this.handleCheckbox}
						handleDropDown = {this.handleDropDown}
						submitAnswer   = {this.onSubmit}
						submitLoading  = {this.state.submitLoading}
						submitted      = {this.state.testModal.submitted}
						onCancel       = {() => this.setState ({testModal : null})}
					/>
					: null}
			</Grid>
		);
	}
}

export default withSnackbar(Interview);
