export const styles = {

	instructionContainer : {
		position  : 'absolute',
		top       : '50%',
		left      : '50%',
		width     : '80%',
		height    : '100%',
		transform : `translate(-50%, -50%)`,
	},
	mainContainer : {
		height : 'calc(100% - 64px)',
		position : 'absolute',
		top : '74px',
		padding : '12px',
	}
};
