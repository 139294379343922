import React             from 'react';
import ReactHtmlParser   from 'react-html-parser';
import {Link as NavLink} from 'react-router-dom';
import Grid              from '@material-ui/core/Grid';
import Typography        from '@material-ui/core/Typography';
import Button            from '@material-ui/core/Button';

import {styles}          from './style';

function Deadline ({msg, btn, to}) {
	
	return (
		<Grid container spacing  = {3} alignItems = 'center' className = 'parent-content p-24' justify = 'center' style = {styles.mainGrid}>
			<Grid style = {styles.container}>
				<Typography variant = 'h4' align = 'center'> {ReactHtmlParser (msg) }</Typography>
				<Button variant = 'contained' color = 'secondary' component = {NavLink} to = {to} className = 'mt-24'> {btn} </Button>
			</Grid>
		</Grid>
	);
}

export default Deadline;
