import React           from 'react';
import ReactHtmlParser from 'react-html-parser';
import Grid            from '@material-ui/core/Grid';
import Paper           from '@material-ui/core/Paper';
import Divider         from '@material-ui/core/Divider';
import Typography      from '@material-ui/core/Typography';

import translations    from 'common/translations';
import { styles }      from './style';

function CompanyNotice ({customFields, langCode}) {

	const renderValues = (field, value) => {
		if (Array.isArray (value) && value.length) {
			return (
				<Typography variant = 'body1'> {value.join (', ')} </Typography>
			);
		}

		if (Array.isArray (value) && !value.length) {
			return (
				<Typography variant = 'body1'> {translations.profile.not_available[langCode]}</Typography>
			);
		}

		return (
			<Typography variant = 'body1'> {value ? ReactHtmlParser(value) : translations.profile.not_available[langCode]} </Typography>
		);
	};

	return (
		<Paper className = 'p-24' variant = 'outlined'>
			<Typography variant = 'h4' align = 'center' color = 'primary'> {translations.onboarding.notification[langCode]} </Typography>
			{customFields.map ((category, index) => (
				<Grid key = {index}>
					<Typography variant = 'subtitle1' className = 'mt-16 mb-16' align = 'center' style = {styles.categoryName}> {category.category_name} </Typography>
					{Object.keys (category.fields).map ((p, i) => (
						<Grid container spacing = {4} key = {i} alignItems = 'center'>
							<Grid container item xs = {5} sm = {4} md = {3} lg = {3} xl = {3}>
								<Typography variant = 'subtitle2'> {p} </Typography>
							</Grid>
							<Grid item xs = {7} sm = {8} md = {9} lg = {9} xl = {9} className = 'pl-8'>
								{renderValues (p, customFields[index].fields[p])}
								<Divider className = 'mt-8'/>
							</Grid>
						</Grid>
					))}
				</Grid>
			))}
		</Paper>

	);
}

export default CompanyNotice;