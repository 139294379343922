import React      from 'react';
import {Link}     from 'react-router-dom';
import Grid       from '@material-ui/core/Grid';
import Button     from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Space      from './Space';
import path       from 'common/path';
import './style.css';

function error404 () {

	return (
		<Grid container spacing = {3} className = 'parent-content' style = {{background : 'var(--white)'}}>
			<Grid container item xs = {12} sm = {6} md = {6} lg = {6} xl = {6} className = 'p-24' alignItems = 'center'>
				<Space />
			</Grid>
			<Grid item xs = {12} sm = {6} md = {6} lg = {6} xl = {6} className = 'p-24' container alignItems = 'center'>
				<Grid>
					<Typography variant = 'h1'> 404 </Typography>
					<Typography variant = 'h4'> UH OH! You're lost.</Typography>
					<Typography variant = 'body1'> The page you are looking for does not exist. How you got here is a mystery. But you can click the button below to go back to the homepage. </Typography>
					<Button className="btn green" component = {Link} to = {path.login}>HOME</Button>
				</Grid>
			</Grid>
		</Grid>
	);
}

export default error404;
