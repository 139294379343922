export const styles = {
	
	mainGrid : {
		background : 'var(--white)',
		color      : 'red',
		margin     : '0px',
	},

	container : {
		textAlign : 'center',
	},
};
