import React                from 'react';
import {SnackbarProvider}   from 'notistack';
import Grid                 from '@material-ui/core/Grid';
import {createMuiTheme}     from '@material-ui/core/styles';
import {makeStyles}         from '@material-ui/core/styles';
import {ThemeProvider}      from '@material-ui/styles';
import CssBaseline          from "@material-ui/core/CssBaseline";

import Routes               from 'components/molecules/Routes';
import globalTheme          from 'styles/muiTheme/globalTheme';
import {app as styles}      from 'styles/global';
import {light}              from 'styles/muiTheme/colors';
import "moment/locale/ja";

const theme = createMuiTheme ({
	...globalTheme
});

const snackbarLightStyle = makeStyles ({

	success : {
		backgroundColor : light.success.main,
	},
	error : {
		backgroundColor : light.error.main
	},
	warning : {
		backgroundColor : light.warning.main,
	},
	info : {
		backgroundColor : light.info.main,
	}
});

function App () {

	const snackbarLightClasses = snackbarLightStyle ();

	function getSnackbarClasses () {

		return {
			variantSuccess : snackbarLightClasses.success,
			variantError   : snackbarLightClasses.error,
			variantWarning : snackbarLightClasses.warning,
			variantInfo    : snackbarLightClasses.info
		};
	}

	return (
		<ThemeProvider theme = {theme}>
			<CssBaseline />
			<SnackbarProvider
				maxSnack         = {2}
				anchorOrigin     = {{horizontal : 'right', vertical : 'top'}}
				preventDuplicate = {true}
				autoHideDuration = {5000}
				classes          = {getSnackbarClasses ()}>
				<Grid style = {styles.parentGrid}>
					<Routes />
				</Grid>
			</SnackbarProvider>
		</ThemeProvider>
	);
}

export default App;
