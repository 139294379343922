import React, {useState, useEffect} from 'react';
import {Redirect}                   from 'react-router-dom';
import Grid                         from '@material-ui/core/Grid';
import Link                         from '@material-ui/core/Link';
import Typography                   from '@material-ui/core/Typography';
import FormHelperText               from '@material-ui/core/FormHelperText';
import TextField                    from '@material-ui/core/TextField';
import Button                       from '@material-ui/core/Button';
import Phone                        from '@material-ui/icons/Phone';
import MenuItem                     from '@material-ui/core/MenuItem';

import {styles }                    from './style';
import Logo                         from 'assets/Logo.png';
import FastLogo                     from 'assets/Fast.png';
import authStore, {applicantInfo}   from 'redux/authStore';
import commonStore,{changeLanguage} from 'redux/commonStore';
import {fastOffer }                 from 'common/config';
import path                         from 'common/path';
import translations                 from 'common/translations';
import Loader                       from 'components/atoms/Loader';
import {countries}                  from 'common/countries';
import apis                         from 'common/apis';
import Log                          from 'common/log';

const log = Log('Login', 'info');

function TwoFactorAuth() {

	const RESEND_TIME = 30;

	const [langCode, setLangCode]         = useState (fastOffer ? 'jp' : 'jp');
	const [cellphone, setCellphone]       = useState ('');
	const [countryCode, setCountryCode]   = useState ('91');
	const [otp, setOtp]                   = useState ('');
	const [errors, setErrors]             = useState ({phone : false, otp : false, countryCode : false});
	const [isLoading, setIsLoading]       = useState (false);
	const [errorMsg, setErrorMsg]         = useState ("");
	const [isOtp, setIsOtp]               = useState (false);
	const [loginSuccess, setLoginSuccess] = useState (false);
	const [applicantId, setApplicantId]   = useState ('');
	const [otpTimeout, setOtpTimeout]     = useState (RESEND_TIME);

	const otpInterval = React.useRef(null);
	
	const t = translations.auth;

	const [redirectLogin, setRedirectLogin] = useState (false);

	useEffect (() => {
		let authInfo = authStore.getState ();
		const {applicantId, authEnabled} = authInfo.auth;
		if (!applicantId || authEnabled === null) {
			setRedirectLogin (true);
			return;
		}
		if (applicantId && authEnabled) {
			setIsOtp (true);
			sendOtp (applicantId);
		}
		setApplicantId (applicantId);
	}, []);

	useEffect(() => {
		return () => clearInterval(otpInterval.current);
	}, []);

	useEffect(() => {
		if (otpTimeout === 0) {
			clearInterval(otpInterval.current);
			otpInterval.current = null;
			return;
		}
	},
	[otpTimeout]);

	const sendOtp = async(applicantId) => {
		let data = {
			id : applicantId
		};
		let result;
		try {
			result = await apis.sendToken (data);
			setErrorMsg ("");
			setOtpTimeout(RESEND_TIME);
			log.info ({result});
		}
		catch (err) {
			log.error ({err}, 'error in token');
			return;
		}
		if (!result.success && result.msg?.includes ('DoS')) {
			setErrorMsg (t.too_many_resend[langCode]);
			setOtpTimeout (0);
			return;
		}
		toggleTimer();
	};

	const toggleTimer = () => {
		otpInterval.current = setInterval(
			() => {
				setOtpTimeout((otpTimeout) => otpTimeout - 1);
			},
			1000
		);
	};

	const handleChange = (event) => {
		const {name, value} = event.target;
		if (name === 'cellphone') {
			setCellphone (value);
			setErrors ({...errors, cellphone : false});
			setErrorMsg ("");
			return;
		}
		if (name === 'countryCode') {
			setCountryCode (value);
			setErrors ({...errors, countryCode : false});
			setErrorMsg ("");
			return;
		}

		if (name === 'otp') {
			setOtp (value);
			setErrors ({...errors, otp : false});
			setErrorMsg ("");
			return;
		}
	};

	const onSubmit = async() => {
		if (isOtp){
			submitOtp ();
			return;
		}
		let isErrored = false;
		if (!countryCode) {
			setErrorMsg (t.country_code_error[langCode]);
			setErrors ({...errors, countryCode : true});
			isErrored = true;
		}
		if (!cellphone || cellphone.trim () === "") {
			setErrorMsg (t.phone_number_error[langCode]);
			setErrors ({...errors, cellphone : true });
			isErrored = true;
		}

		if (isErrored) {
			return;
		}

		const data = {
			id : applicantId,
			cellphone : cellphone,
			country_code : countryCode,
		};

		let result;

		try {
			setIsLoading(true);
			setErrorMsg ("");
			result = await apis.enableAuthy(data);
			log.info({data : result}, 'authy enabled');
			setIsLoading(false);
		}
		catch (err) {
			log.error ({err}, 'error in authy enabled');
			setIsLoading(false);
			return;
		}

		if (!result.success) {
			setIsLoading (false);
			try {
				let msg = JSON.parse(result.msg?.error);
				setErrorMsg(msg.message);
				return;
			}
			catch (err) {
				return;
			}
		}

		setIsLoading(false);
		setIsOtp (true);
	};

	const submitOtp = async() => {
		if (!otp || otp.trim() === "") {
			setErrorMsg (t.invalid_otp[langCode]);
			setErrors({...errors, otp : true});
			return;
		}
		let data = {
			token : otp,
			id : applicantId
		};

		setIsLoading (true);
		let result;

		try {
			result = await apis.verifyToken (data);
			log.info ({result});
		}
		catch (err) {
			log.error ('error in verify token');
			setIsLoading(false);
			return;
		}

		if (!result.success) {
			setErrorMsg (t.invalid_otp[langCode]);
			setIsLoading(false);
			return;
		}
		
		let token   = result.access_token.token;
		let loginTs = new Date ().getTime (); //maintaining when the user first login to the browser.

		localStorage.setItem ('token', token);
		localStorage.setItem ('lts', loginTs);

		authStore.dispatch (applicantInfo (result.applicant));
		let isFastOffer =  result.applicant['is_fastoffer'];
		if (isFastOffer) {
			setLangCode ('jp');
			commonStore.dispatch (changeLanguage ('jp'));
		}
		setLoginSuccess (true);
		setIsLoading (false);
	};

	const handleEnterPress = (event) => {
		if (event.keyCode !== 13) {
			return;
		}
	};

	const renderStartAdornment = () => {
		return <Phone style = {styles.icon}/>;
	};

	if (loginSuccess) {
		return (
			<Redirect to = {path.landing} push = {true}/>
		);
	}

	if (redirectLogin) {
		return (
			<Redirect to = {path.login} push = {false}/>
		);
	}

	return (
		<Grid container spacing={3} style={styles.mainContainer} alignItems='center' justify='center'>
			<Grid item xs={12} sm={8} md={6} lg={4} xl={4}>
				<Grid style={styles.loginContainer}>
					<Grid container justify='center'>
						<img alt='Logo' src={fastOffer ? FastLogo : Logo} height={65} width='auto' className='mb-24' />
					</Grid>
					<Typography variant='h4' align='center' className='mb-16'> {isOtp ? t.enter_otp[langCode] : t.enter_phone_number[langCode]} </Typography>
					{isOtp ?
						<Grid style={{...styles.inputContainer, textAlign : 'center'}}>
							<TextField
								value = {otp}
								name = "otp"
								onChange = {handleChange}
								error = {errors.otp}
								variant = "outlined"
								onKeyUp={handleEnterPress}
								placeholder = {t.enter_otp_placeholder[langCode]}
								inputProps={{ maxLength: 8 }}
								style={{margin : '0px auto'}}
							/>
							<Grid>
								{otpTimeout === 0 ? <Link className = 'mt-12' component="button" onClick={() => sendOtp(applicantId)}> {t.resend_otp[langCode]} </Link> :
									<FormHelperText error={false} style={styles.loginErrMsg}> {t.wait_for_n_sec(otpTimeout)[langCode]} </FormHelperText> }
							</Grid>
						</Grid> :
						<Grid style={styles.inputContainer} container>
							<Grid item sm = {3}>
								<TextField
									variant='outlined'
									value={countryCode}
									error={errors.countryCode}
									select
									name='countryCode'
									onChange={handleChange}
									fullWidth
									style={{width : '90%'}}
									SelectProps={{
										renderValue : (v) => `+${v}`
									}}
								>
									{countries.map ((country, index) => (
										<MenuItem value = {country.number} key = {index}>
											<img src = {country.flag} width = {24} height = {16} className = 'mr-12'/> {country.name}&nbsp; (+{country.number})
										</MenuItem>
									))}
								</TextField>
							</Grid>
							<Grid item sm = {9}>
								<TextField
									placeholder={t.enter_phone_number[langCode]}
									variant='outlined'
									value={cellphone}
									error={errors.cellphone}
									fullWidth={true}
									name='cellphone'
									onChange={handleChange}
									onKeyUp={handleEnterPress}
									inputProps = {{maxLength : 13}}
									InputProps={{
										style: styles.inputProps,
										startAdornment: renderStartAdornment()
									}}
								/>
							</Grid>
							{errors.phone ? <FormHelperText error={true}> {t.invalid_phone_number[langCode]} </FormHelperText> : null}
						</Grid>}
					<Button variant='contained' color='secondary' fullWidth onClick={onSubmit} disabled={isLoading}>
						{isLoading ? <Loader /> : t.submit[langCode]}
					</Button>
				</Grid>
				{errorMsg ? <FormHelperText error={true} style={styles.loginErrMsg}> {errorMsg} </FormHelperText> : null}
			</Grid>
		</Grid>
	);
}

export default TwoFactorAuth;