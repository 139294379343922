/* eslint-disable no-debugger, no-console, no-undef */

import React, { useState, useEffect }  from 'react';
import swal                            from 'sweetalert';
import Button                          from '@material-ui/core/Button';
import Grid                            from '@material-ui/core/Grid';

import './style.css';

function VideoRecorder ({ elementId, width, height, payload, saveProfile, requestType, onSaveOnlineVideo, timeLimit }) {

	const [disableRec, setDisableRec] = useState (true);

	let pipeParams = {
		size           : {width, height},
		qualityurl     : "avq/720p.xml",
		accountHash    : "e3322119bc4af86100ec7b60c0eca180",
		eid            : "ArFCax",
		mrt            : timeLimit ? timeLimit : "65",
		payload        : JSON.stringify(payload),
		showMenu       : true,
		asv            : 0,
	};

	useEffect (() => {
		PipeSDK.insert(elementId, pipeParams, function (recorder) {
			if (requestType === 'pr_video') {
				recorder.onReadyToRecord = () => {
					document.getElementById (`save-profile`).onclick = () => onSaveRecording(recorder);
					return;
				};
				document.getElementById(`save-profile`).onclick = () => saveProfile();
			}
			if (requestType === 'online_session') {
				recorder.onReadyToRecord = () => {
					let el = document.getElementById (`online_session_video`);
					if (el) {
						el.onclick = () => onSaveOnlineVideo(recorder);
					}
				};
			}

			/*
		recorder.onSaveOk = () => {
			setDisableRec (false);
		};
		*/

			recorder.onRecordingStarted = () => {
				setDisableRec (true);
			};
		});
	}, []);



	const onSaveRecording = (recorder) => {
		swal ({
			title   : 'Save Profile',
			text    : 'Are you sure, you want to save all your changes along with the video?',
			icon    : 'info',
			buttons : ['Cancel','Ok']
		}).then((res) => {
			if (res) {
				recorder.save ();
				if (saveProfile) {
					saveProfile();
				}
			}
		});
	};

	return (
		<Grid>
			<Grid id = {elementId} className = 'video-rec'></Grid>
		</Grid>
	);
}

export default VideoRecorder;
