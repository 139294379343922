import React         from 'react';
import Dialog        from '@material-ui/core/Dialog';
import DialogTitle   from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid          from '@material-ui/core/Grid';
import TextField     from '@material-ui/core/TextField';
import Button        from '@material-ui/core/Button';
import Select        from '@material-ui/core/Select';
import MenuItem      from '@material-ui/core/MenuItem';
import InputLabel    from '@material-ui/core/InputLabel';
import FormControl   from '@material-ui/core/FormControl';

import Loader        from 'components/atoms/Loader';

class RankModal extends React.Component {

	constructor (props) {
		super (props);
		
		let ratings = props.ratings;
		this.state = {
			rank    : ratings && ratings.rank    ? ratings.rank    : '',
			rate    : ratings && ratings.rate    ? ratings.rate    : '',
			comment : ratings && ratings.comment ? ratings.comment : '',
		};
		this.rankOptions = [
			"A - will definitely join",
			"B - will most likely join",
			"C - will think about it when I get an offer",
			"D - not really interested, but will try the interview",
			"E - would not like to do the interview",
		];
	}

	handleTextField = (event) => {
		const {name, value} = event.currentTarget;

		if (name === 'rate' && value > 99) {
			return;
		}

		if (name === 'comment' && value.length > 1000) {
			return;
		}

		this.setState ({
			[name] : value,
		});
	}

	handleRank = (event) => {
		
		this.setState ({
			rank : event.target.value
		});
	}

	handleClose = () => {
	
		this.props.onClose ();
	}

	onSubmit = async () => {
		
		let data = {
			rating : {
				rate          : this.state.rate,
				rank          : this.state.rank,
				comment       : this.state.comment,
				job_id        : this.props.jobId,
				sgwj_event_id : this.props.eventId,
			}
		};

		this.setState ({
			loading : true,
		});
		await this.props.onSubmitRating (data);
		this.handleClose();
	}

	render () {
		
		return (
			<Dialog open = {true} maxWidth = 'sm' fullWidth>
				<DialogTitle>
					Update your rank
				</DialogTitle>
				<DialogContent>
					<Grid container spacing = {2}>
						<Grid item sm = {12} xs = {12} md = {6} lg = {6} xl = {6} className = 'rank-select'>
							<FormControl fullWidth>
								{!this.state.rank ? <InputLabel disableAnimation = {true} shrink = {true} > Rank </InputLabel> : null}
								<Select value = {this.state.rank} onChange = {this.handleRank} variant = "outlined" fullWidth>
									{this.rankOptions.map (option => (
										<MenuItem key = {option} value = {option}> {option} </MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						<Grid item sm = {12} xs = {12} md = {6} lg = {6} xl = {6}>
							<TextField
								placeholder = 'Rate'
								value       = {this.state.rate}
								name        = 'rate'
								type        = 'number'
								onChange    = {this.handleTextField}
								variant     = 'outlined'
								fullWidth
							/>
						</Grid>
					</Grid>
					<Grid className = 'mt-12'>
						<TextField
							placeholder = 'Reason (Char limit 1000)'
							value       = {this.state.comment}
							name        = 'comment'
							onChange    = {this.handleTextField}
							variant     = 'outlined'
							rows        = {3}
							rowsMax     = {5}
							fullWidth
							multiline
						/>
					</Grid>
				</DialogContent>
				<DialogActions className = 'pr-24'>
					<Button variant = 'outlined'  color = 'secondary' onClick = {this.handleClose}> Cancel </Button>
					<Button variant = 'contained' color = 'primary' onClick = {this.onSubmit}>
						{this.state.loading ? <Loader color = 'secondary'/> : 'Submit'}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default RankModal;
