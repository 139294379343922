import React, {useEffect, useState}   from 'react';
import ReactHtmlParser                from 'react-html-parser';
import Grid                           from '@material-ui/core/Grid';
import Link                           from '@material-ui/core/Link';
import Typography                     from '@material-ui/core/Typography';
import Paper                          from '@material-ui/core/Paper';
import Divider                        from '@material-ui/core/Divider';

import authStore                      from 'redux/authStore';

function ViewQuestion (props) {

	const [name, setName] = useState ('');
	const [studentId, setStudentId] = useState ('');

	const {examData} = props;

	useEffect (() => {
		getProfile();
	}, []);

	const getProfile = () => {
		let profileInfo = authStore.getState ().applicant;
		setName (profileInfo && profileInfo.name);
		setStudentId (profileInfo && profileInfo.id);
	};

	const renderAnswer = (question) => {
		if (!question) {
			return;
		}

		let answer = question.answer || question.correct_answer;

		if (answer && question.question_type === 'video') {
			return (
				<Grid container justify = 'center'>
					<video width = "auto" height = "350" autoPlay controls style = {{maxWidth : '100%'}}>
						<source src = {answer} type="video/mp4" />
					</video>
				</Grid>
			);
		}

		if (answer && question.question_type === 'document') {
			return (
				<div>
					<strong className = 'mr-24'>Ans. </strong>
					<Link href = {answer} target = '_blank'>View Document </Link>
				</div>
			);
		}

		if (Array.isArray (answer) && answer.length) {
			return (
				<Typography variant = 'body1'> <strong className = 'mr-24'> Ans. </strong> {answer.join (', ')} </Typography>
			);
		}
		return (
			<Typography variant = 'body1'> <strong className = 'mr-24'>Ans. </strong>{answer} </Typography>
		);
	};
	
	const renderAnswerImage = (question) => {
		if (question && question.answer_image) {
			return (
				<>
					<Divider className = 'mt-8 mb-8'/>
					<Grid container justify = 'center'>
						<img alt = 'answer' src = {question.display_image} width = 'auto' height = '350' style = {{maxWidth : '100%'}}/>
					</Grid>
					<Divider className = 'mt-8 mb-8'/>
				</>
			);
		}
	};

	const renderAnswerVideo = (question) => {
		if (question && question.answer_video) {
			return (
				<>
					<Divider className = 'mt-8 mb-8'/>
					<Grid container justify = 'center'>
						<video width = "auto" height = "350" autoPlay controls style = {{maxWidth : '100%'}}>
							<source src = {question.display_video} type="video/mp4" />
						</video>
					</Grid>
				</>
			);
		}
	};

	const questionCard = (question, i) => {
		return (
			<Paper variant = 'outlined' className = 'p-12 mt-12 mb-12' style = {{width : '100%'}}>
				<Grid key = {question.question_id}>
					<Grid container alignItems = 'center'>
						<Typography variant = 'h6' className = 'mr-24'> Q. {i+1} </Typography>
						<Typography variant = 'body1'> {ReactHtmlParser (question.question_name)} </Typography>
					</Grid>
					{question.display_image ?
						<>
							<Divider className = 'mt-8 mb-8'/>
							<Grid container justify = 'center'>
								<img alt = 'question' src = {question.display_image} width = 'auto' height = '350' style = {{maxWidth : '100%'}}/>
							</Grid>
							<Divider className = 'mt-8 mb-8'/>
						</>
						:
						null}

					{question.display_video ?
						<>
							<Divider className = 'mt-8 mb-8'/>
							<Grid container justify = 'center'>
								<video width = "auto" height = "350" autoPlay controls style = {{maxWidth : '100%'}}>
									<source src = {question.display_video} type="video/mp4" />
								</video>
							</Grid>
						</>
						:
						null}
					<Grid className = 'mt-12'>
						{renderAnswer (question)}
					</Grid>
					<Grid className = 'mt-12'>
						{renderAnswerImage (question)}
					</Grid>
					<Grid className = 'mt-12'>
						{renderAnswerVideo (question)}
					</Grid>
					{question.correct_answer_details ?
						<Grid className = 'mt-12'>
							<Divider className = 'mt-16 mb-16'/>
						Explanation.
							<Typography variant = 'body2' style = {{color : '#6b6b6b'}}>
								{question.correct_answer_details || 'No explanation for this question'}
							</Typography>
						</Grid> : null}
				</Grid>
			</Paper>
		);
	};

	return (
		<Grid className = 'pt-24'>
			<Grid container>
				<Grid item sm = {6}>
					<Typography variant = 'h6'> Name : {name}</Typography>
				</Grid>
				<Grid item sm = {6}>
					<Typography variant = 'h6' align ='right'> ID : {studentId}</Typography>
				</Grid>
			</Grid>
			<Grid container className = 'mt-12'>
				<Grid item sm = {6}>
					{examData.score ? <Typography variant = 'h6'> 結果 : {examData.score}</Typography> : null}
				</Grid>
			</Grid>
			{examData.questions.map ((que, i) => (
				<Grid key = {i}>
					{questionCard (que, i)}
				</Grid>
			))}
		</Grid>
	);
}

export default ViewQuestion;
