import React                 from 'react';
import {withSnackbar}        from 'notistack';
import Dialog                from '@material-ui/core/Dialog';
import DialogTitle           from '@material-ui/core/DialogTitle';
import IconButton            from '@material-ui/core/IconButton';
import InputAdornment        from '@material-ui/core/InputAdornment';
import DialogContent         from '@material-ui/core/DialogContent';
import FormControl           from '@material-ui/core/FormControl';
import TextField             from '@material-ui/core/TextField';
import FormHelperText        from '@material-ui/core/FormHelperText';
import DialogActions         from '@material-ui/core/DialogActions';
import Button                from '@material-ui/core/Button';
import Visibility            from '@material-ui/icons/Visibility';
import VisibilityOff         from '@material-ui/icons/VisibilityOff';

import Loader                from 'components/atoms/Loader';
import Log                   from 'common/log';
import apis                  from 'common/apis';
import {errorMsg}            from 'common/errors';
import utility               from 'common/utility';

const log = Log ('ChangePassword', 'info');

class ChangePassword extends React.Component {
	constructor (props) {
		super (props);

		this.state = {
			isDisable   : false,
			oldPass     : {
				value : '',
				error : false,
			},
			newPass     : {
				value : '',
				show  : false,
				error : false,
			},
		};
		this.notify = this.props.enqueueSnackbar;
	}

	handleTextChange = (event) => {
	
		const {name, value} = event.currentTarget;

		this.setState ({
			[name] : {
				value : value,
				error : false,
			}
		});
	}

	onPressEnter =  (event) => {

		if (event.keyCode !== 13) {
			return;
		}

		this.onUpdatePassword ();
	}

	onCloseModal = () => {
		
		this.props.closeModal();
	}

	hidePassword = () => {
		
		this.setState ({
			newPass : {
				...this.state.newPass,
				show : false,
			}
		});
	}

	showPassword = () => {
		
		this.setState ({
			newPass : {
				...this.state.newPass,
				show : true,
			}
		});
	}

	onUpdatePassword = async () => {

		let oldPassword = this.state.oldPass.value;
		let newPassword = this.state.newPass.value;

		if (!oldPassword) {
			
			this.setState ({
				oldPass : {
					...this.state.oldPass,
					error : true,
				}
			});
		}

		if (!newPassword || newPassword.trim () === '') {
			
			this.setState ({
				newPass : {
					...this.state.newPass,
					error : true,
				}
			});
			return;
		}

		if (oldPassword ===  newPassword) {
			
			this.notify ('Old password and New password cannot be same', {variant : 'error'});
			this.setState ({
				oldPass : {
					...this.state.oldPass,
					value : '',
				},
				newPass : {
					...this.state.newPass,
					value : '',
				}
			});
			return;
		}

		let data = {
			applicant : {
				old_password : this.state.oldPass.value,
				new_password : this.state.newPass.value
			}
		};

		this.setState ({
			isDisable : true,
		});

		let result;
		try {
			result = await apis.resetPassword (data);
			log.info ({result}, 'password saved successfully');
		}

		catch (err) {
			if (err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
			}
			log.error ({err}, 'error updating password');
			this.setState ({
				isDisable : false,
			});
			return;
		}

		if (result.error) {
			this.notify (result.error, {variant : 'error'});
			this.setState ({
				isDisable : false,
			});
			return;
		}

		this.notify (result.message, {variant : 'success'});
		this.onCloseModal ();
	}

	renderShowButton = () => {
		
		return (
			<InputAdornment position = "end">
				<IconButton
					size        = 'small'
					onMouseUp   = {this.hidePassword}
					onMouseDown = {this.showPassword}
				>
					{this.state.newPass.show ? <Visibility /> : <VisibilityOff />}
				</IconButton>
			</InputAdornment>
		);
	}

	render () {
		
		return (
			<Dialog open = {true} maxWidth = 'xs' fullWidth = {true}>
				<DialogTitle style = {{textAlign : 'center', color : '#5E5E5E', paddingBottom : '0px'}}> Change Password </DialogTitle>
				<DialogContent>
					<FormControl fullWidth = {true}>
						<TextField
							autoFocus   = {true}
							margin      = "dense"
							variant     = 'outlined'
							type        = 'password'
							placeholder = "Old Password"
							name        = 'oldPass'
							onKeyUp     = {this.onPressEnter}
							onChange    = {this.handleTextChange}
							value       = {this.state.oldPass.value}
							error       = {this.state.oldPass.error}
							fullWidth   = {true}
						/>
						{this.state.oldPass.error ? <FormHelperText error = {true}> Old password cannot be empty </FormHelperText> : null}
					</FormControl>
					<FormControl fullWidth = {true}>
						<TextField
							margin      = "dense"
							variant     = 'outlined'
							type        = {!this.state.newPass.show ? 'password' : 'text'}
							placeholder = "New Password"
							name        = 'newPass'
							onKeyUp     = {this.onPressEnter}
							onChange    = {this.handleTextChange}
							value       = {this.state.newPass.value}
							error       = {this.state.newPass.error}
							fullWidth   = {true}
							InputProps  = {{
								endAdornment : this.renderShowButton()
							}}
						/>
						{this.state.newPass.error ? <FormHelperText error = {true}> New password cannot be empty </FormHelperText> : null}
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Button color = "secondary" onClick = {this.onCloseModal}> Cancel </Button>
					<Button color = "primary"   onClick = {this.onUpdatePassword} disabled = {this.state.isDisable}>
						{this.state.isDisable ? <Loader size = {20} loading = {true} /> : `Update Password`}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default withSnackbar(ChangePassword);
