import React, { useState } from 'react';
import Slider              from '@material-ui/core/Slider';

import {styles}            from './style';

function ImageContainer ({imgurl, zoom, height, width}) {

	const [zoomVal, setZoomVal] = useState (0);

	const handleSlideChange = (ev, val) => {
		setZoomVal (val);
	};

	return (
		<div style = {styles.parentContainer}>
			<div style = {styles.imageContainer(height, width)} id = 'imageZoom'>
				<div style = {styles.imageBackground (imgurl, zoomVal)}> </div>
			</div>
			{zoom ?
				<div>
					<strong style = {styles.plus}> + </strong>
					<Slider
						orientation  = "vertical"
						defaultValue = {1}
						onChange     = {handleSlideChange}
						value        = {zoomVal}
						style        = {styles.slider}
					/>
					<strong style = {styles.minus}> - </strong>
				</div>
				: null}
		</div>
	);
}

export default ImageContainer;
