import React       from 'react';
import Grid        from '@material-ui/core/Grid';
import BackdropMui from '@material-ui/core/Backdrop';
import Loader      from "react-spinners/FadeLoader";
import Typography  from "@material-ui/core/Typography";

function Backdrop () {

	const styles = {
		main : {
			zIndex : 1000,
			background : 'rgba(40, 40, 40, 0.85)',
		},
		text : {
			position : 'absolute',
			top : '60%',
			textAlign : 'center',
			color : '#fff',
		},
	};

	return (
		<BackdropMui open = {true} style = {styles.main}>
			<Grid>
				<Loader color = '#8191BE'/>
			</Grid>
			<Grid style = {styles.text}>
				<Typography variant = 'h6'> Please wait while we are processing your application... </Typography>
			</Grid>
		</BackdropMui>
	);
}

export default Backdrop;
