import React              from 'react';
import moment             from 'moment';
import {Redirect}         from 'react-router-dom';
import {Link as Navlink}  from 'react-router-dom';
import ReactHtmlParser    from 'react-html-parser';
import { serialize }      from 'object-to-formdata';
import {withSnackbar}     from 'notistack';
import swal               from '@sweetalert/with-react';
import Grid               from '@material-ui/core/Grid';
import Link               from '@material-ui/core/Link';
import FormHelperText     from '@material-ui/core/FormHelperText';
import Typography         from '@material-ui/core/Typography';
import TextField          from '@material-ui/core/TextField';
import Divider            from '@material-ui/core/Divider';
import Paper              from '@material-ui/core/Paper';
import RadioGroup         from '@material-ui/core/RadioGroup';
import Radio              from '@material-ui/core/Radio';
import Button             from '@material-ui/core/Button';
import FormControlLabel   from '@material-ui/core/FormControlLabel';
import FormControl        from '@material-ui/core/FormControl';
import FormGroup          from '@material-ui/core/FormGroup';
import Checkbox           from '@material-ui/core/Checkbox';
import Stepper            from '@material-ui/core/Stepper';
import Step               from '@material-ui/core/Step';
import StepLabel          from '@material-ui/core/StepLabel';
import Tooltip            from '@material-ui/core/Tooltip';
import InfoIcon           from '@material-ui/icons/InfoOutlined';

import apis               from 'common/apis';
import Log                from 'common/log';
import path               from 'common/path';
import translations       from 'common/translations';
import utility            from 'common/utility';
import commonStore        from 'redux/commonStore';
import {changeLanguage}   from 'redux/commonStore';
import AutoSelect         from 'components/atoms/AutoSelect';
import Loader             from 'components/atoms/Loader';
import Backdrop           from 'components/atoms/Backdrop';
import Error404           from 'components/molecules/404';
import VimeoVideoUploader from 'components/atoms/VimeoVideoUploader';
import { light }          from 'styles/muiTheme/colors';
import Skeleton           from './Skeleton';
import Deadline           from './Deadline';
import {styles}           from './style';

const log = Log ('Apply', 'info');

class Apply extends React.Component {

	constructor (props) {
		super (props);

		this.state = {
			customFields    : null,
			templates       : [],
			permanentFields : {
				name                   : '',
				hiragana               : '',
				email                  : '' ,
				date_of_birth          : '',
				country_id             : null,
				confirmEmail           : '',
				banner                 : '',
			},
			countries       : [],
			universities    : [],
			education_backgrounds : {},
			faculties             : {},
			applicantUniversities  : [],
			universityError        : [],
			loading         : true,
			errors : {
				name          : false,
				hiragana      : false,
				date_of_birth : false,
				email         : false,
				confirmEmail  : false,
				country_id    : false,
			},
			submitLoading : false,
			finalSubmit   : false,
			redirectLogin : false,
			form          : null,
			deadlinePage  : false,
			notFoundPage  : false,
			skeletons     : false,
			currentStep : 0,
			tos         : "",
			agreeTos    : false,
			alreadyRegistered : false,
			langCode : 'en',
		};
		this.notify = this.props.enqueueSnackbar;
	}

	componentDidMount = () => {
		this.commonUnsub = commonStore.subscribe (this.getLanguage);
		this.getLanguage     ();
		this.getTemplates    ();
		this.getUniversity   ();
		this.getCountry      ();
		this.addEducation ();
	}

	getLanguage = () => {
		let commonInfo = commonStore.getState ();
		let langCode = commonInfo.langCode;
		this.setState ({
			langCode
		});
	}

	componentWillUnmount = () => {
		this.commonUnsub ();
	}

	verifyEmail = async () => {
		let permanentFields = this.state.permanentFields;
		let email        = permanentFields.email;
		let confirmEmail = permanentFields.confirmEmail;

		if (!utility.validateEmail (email)) {
			this.setState ({
				errors : {
					...this.state.errors,
					email : true,
				}
			});
			return;
		}

		if (email !== confirmEmail) {
			this.setState ({
				errors : {
					...this.state.errors,
					confirmEmail : true,
				}
			});
			return;
		}

		let data = {
			applicant : {
				email,
			}
		};

		this.setState ({
			submitLoading : true,
		});

		let result;

		try {
			result = await apis.verifyEmail (data);
		}
		catch (err) {
			log.error ({err}, 'error verifying email');
			if (err.response && err.response.status === 422) {
				this.notify (err.response.data.errors, {variant : 'error'});
			}
			if(err.response && err.response.status === 422) {
				this.setState ({
					alreadyRegistered : true,
				});
			}
			this.setState ({
				submitLoading : false,
			});
			return;
		}

		if (result.success) {
			this.setState ({
				submitLoading : false,
				currentStep : 1,
			});
			return;
		}
		this.setState ({
			submitLoading : false,
		});
	}

	disableKeyBoard = (event) => {
		event.preventDefault ();
		return false;
	}

	disableCopyPaste = (event) => {
		event.preventDefault ();
	}

	handleAgreeTos = (event) => {
		this.setState ({
			agreeTos : event.target.checked,
		});
	}

	handlePermanentFields = (event) => {
	
		const {name, value} = event.currentTarget;

		this.setState ({
			permanentFields : {
				...this.state.permanentFields,
				[name] : value,
			},
			errors : {
				...this.state.errors,
				[name] : false,
			}
		});
	}

	handleDocChange = (event) => {

		const name   = event.currentTarget.name;
		let files    = event.target.files[0];
		if (!files) {
			return;
		}
		const fileSize = event.target.files[0].size;
		if (fileSize > 10*1024*1024) { // files size should not be greater than 10 mb
			this.setState ({
				errors : {
					...this.state.errors,
					[name] : true,
				}
			});
			return;
		}
		this.setState ({
			customFields : {
				...this.state.customFields,
				[name] : {
					...this.state.customFields[name],
					value : files,
				}
			},
			errors : {
				...this.state.errors,
				[name] : false,
			}
		});
	}

	handleTranslation = (lan) => {
		commonStore.dispatch (changeLanguage (lan));
	}

	handleVideoCustomFields = (fieldName, videoUrl) => {

		this.setState ({
			customFields : {
				...this.state.customFields,
				[fieldName] : {
					...this.state.customFields[fieldName],
					value : videoUrl,
				},
			},
			errors : {
				...this.state.errors,
				[fieldName] : false,
			}
		});
	}

	handleCustomFields = (event) => {
		
		const {name, value}  = event.currentTarget;

		this.setState ({
			customFields : {
				...this.state.customFields,
				[name] : {
					...this.state.customFields[name],
					value : value,
				},
			},
			errors : {
				...this.state.errors,
				[name] : false,
			}
		});
	}

	handlePermanentSelect = (name, value) => {
	
		this.setState ({
			permanentFields : {
				...this.state.permanentFields,
				[name] : value
			},
			errors : {
				...this.state.errors,
				[name] : false,
			}
		});
	}

	handleCustomSelect = (name, value) => {
	
		this.setState ({
			customFields : {
				...this.state.customFields,
				[name] : {
					...this.state.customFields[name],
					value : value,
				}
			},
			errors : {
				...this.state.errors,
				[name] : false,
			}
		});
	}

	handleCustomCheckbox = (event) => {

		const {name, checked, value} = event.target;

		this.setState ({
			customFields : {
				...this.state.customFields,
				[name] : {
					...this.state.customFields[name],
					value : checked ? [...this.state.customFields[name].value, value] : this.state.customFields[name].value.filter (v => v!== value)
				}
			},
			errors : {
				...this.state.errors,
				[name] : false,
			}
		});
	}

	getTemplates = async () => {
		
		let result;
		try {
			result = await apis.getTemplates ();
			log.info ({templates : result}, 'templates get ok');
		}
		catch (err) {
			log.error ({err}, 'error getting templates');
			this.setState ({
				loading : false,
			});
			return;
		}

		if (result.errors) {
			this.notify (result.errors, {variant : 'error'});
			return;
		}

		let formNumber = this.props.match.params.form_number;
		if (!formNumber) {
			this.notify ('Something went wrong!', {variant : 'error'});
			this.setState ({
				loading : false
			});
			return;
		}

		if (!result.application_forms.length) {
			this.notify ('There are currently no form templates', {variant : 'info'});
			this.setState ({
				loading : false,
			});
			return;
		}

		let form = result.application_forms.find (temp => temp.name === formNumber);

		if (!form) {
			this.setState ({
				loading      : false,
				notFoundPage : true,
			});
			return;
		}

		if (!form['is_valid?']) {
			this.setState ({
				loading      : false,
				deadlinePage : true,
			});
			return;
		}

		let langCode =  form['is_fastoffer?'] ? 'jp' : 'en';
		commonStore.dispatch (changeLanguage (langCode));
		this.setState ({
			loading   : false,
			templates : result.application_forms,
			form      : form,
			skeletons : true,
		});
		this.getCustomFields (form);
	}

	getCountry = async () => {
		
		let result;
		try {
			result = await apis.getCountryList ();
			log.info ({countries : result}, 'get country list');
		}
		catch (err) {
			log.error ({err}, 'error getting country list');
			this.setState ({
				loading : false,
			});
			return;
		}

		if (result.errors) {
			this.notify (result.errors, {variant : 'error'});
			this.setState ({
				loading : false,
			});
			return;
		}
		this.setState ({
			countries : result.countries,
		});
	}

	getUniversity = async () => {
		
		let result;
		try {
			result = await apis.getUniversityList ();
			log.info ({universities : result}, 'get country list');
		}
		catch (err) {
			log.error ({err}, 'error getting country list');
			this.setState ({
				loading : false,
			});
			return;
		}

		if (result.errors) {
			this.notify (result.errors, {variant : 'error'});
			this.setState ({
				loading : false,
			});
			return;
		}
		let universities = [...result.universities];
		let education_backgrounds = result.education_backgrounds;
		let faculties = result.faculties;
		this.setState ({
			universities,
			education_backgrounds,
			faculties,
		});
	}

	handleUniversityFieldSelChange = (name, value, id) => {
		let _applicantUniversities = this.state.applicantUniversities;
		let _univeristy = _applicantUniversities.find(uni => uni.id === id);
		if (name === 'is_latest') {
			_applicantUniversities = _applicantUniversities.map(uni => (
				{
					...uni,
					is_latest : uni.id === id ? true : false
				}
			));
		}
		/**For these fields we are directly set the value to that field */
		if (name === 'is_latest' || name === 'department' || name === '_destroy' || name === 'other_university_name')
			_univeristy[name] = value;
		/**These fields are for dropdowns*/
		else {
			_univeristy[name] = value?.value;
		}

		if (name === 'university_id' && value.label?.toLowerCase().includes('other')) {
			_univeristy['otherUniversity'] = true;
		}
		if (name === 'university_id' && !value.label?.toLowerCase().includes('other')) {
			_univeristy['otherUniversity'] = false;
			_univeristy['other_university_name'] = '';
		}
		if ( name === 'other_university_name') {
			_univeristy['otherUniversity'] = true;
		}
		this.setState({
			applicantUniversities : [..._applicantUniversities],
		});
	}

	addEducation = () => {
		let uniData = {
			university_id        : null,
			faculty              : null,
			education_background : null,
			department           : '',
			start_month          : null,
			start_year           : null,
			graduated_month      : null,
			graduated_year       : null,
			_destroy             : false,
			is_latest            : false,
			id                   : utility.generateRandomInteger(5),
			new                  : true,
		};
		this.setState ({
			applicantUniversities : [...this.state.applicantUniversities, uniData]
		});
	}

	handleRemoveEducation = (id) => {
		let _applicantUniversities = this.state.applicantUniversities;
		let _univeristy = _applicantUniversities.find(uni => uni.id === id);
		if (_univeristy.id) {
			_univeristy['_destroy'] = true;
		}
		else {
			_applicantUniversities = _applicantUniversities.filter(uni => uni.id === id);
		}
		this.setState ({
			applicantUniversities : [..._applicantUniversities],
		});
	}

	getCustomFields = async (form) => {
		
		let data = {
			application_form : {
				form_id : form.id,
			}
		};

		let isFastOffer = form ['is_fastoffer?'];
		let result;
		let tos;
		try {
			result = await apis.getForm (data);
			tos    = isFastOffer ? await apis.getFastTos () :  await apis.getTos ();
			log.info ({customFields : result}, 'get custom_fields ok');
			log.info ({tos}, 'get TOS');
		}
		catch (err) {
			log.error ({err}, 'error getting custom fields');
			this.setState ({
				skeletons : false,
			});
			return;
		}

		if (result.errors) {
			this.notify (result.errors, {variant : 'error'});
			this.setState ({
				skeletons : false,
			});
			return;
		}

		let __customFields = Object.keys(result.custom_fields);
		let customFields = {};
		let customErrors = {};

		for (let i = 0; i < __customFields.length; i++) {
			let __inputType = result.custom_fields[__customFields[i]].input_type;
			let __options   = result.custom_fields[__customFields[i]].options;
			customFields[__customFields[i]] = {
				...result.custom_fields[__customFields[i]],
				value   : __inputType === 'check_box' ? [] : '',
				options : __options.length ? __options.map ((item, index) => {
					return {
						name : item,
						id   : index
					};
				})  : __options
			};
			customErrors[__customFields[i]] = false;
		}

		this.setState ({
			customFields   : customFields,
			loading        : false,
			skeletons      : false,
			tos            : tos ? tos.content : '',
			banner         : result.banner_image,
		});
	}

	onSubmit = async () => {
		
		let errors = {};
		let permanentFields = this.state.permanentFields;
		let applicantUniversities = this.state.applicantUniversities;
		let customFields    = this.state.customFields;
		let __customFields  = Object.keys(customFields);

		let customFieldsData = {};
		let documents_attributes = {};

		/* Validate all fields first based upon mandatory or not*/

		if (!utility.validateTextField (permanentFields.name)) {
			errors['name'] = true;
		}

		if (!utility.validateTextField (permanentFields.name)) {
			errors['hiragana'] = true;
		}

		if (!permanentFields.date_of_birth) {
			errors['date_of_birth'] = true;
		}

		if (!utility.validateEmail (permanentFields.email)) {
			errors['email'] = true;
		}

		if (!permanentFields.country_id) {
			errors['country_id'] = true;
		}

		if (permanentFields.email !== permanentFields.confirmEmail) {
			errors['confirmEmail'] = true;
		}

		let _universityError = [];

		applicantUniversities.forEach(uni => {
			if (uni._destroy) {
				return;
			}
			let error = {
				university_id : false,
				faculty : false,
				education_background : false,
				start_month : false,
				start_year : false,
				graduated_month : false,
				graduated_year : false,
				otherUniversity : false,
			};
			Object.keys(error).forEach(key => {
				if (!uni[key] && key !== 'otherUniversity') {
					error[key] = true;
					errors['applicant_universities_attributes'] = true;
				}
				if (uni.otherUniversity && (!uni.other_university_name || uni.other_university_name.trim() === "")) {
					error['otherUniversity'] = true;
					errors['applicant_universities_attributes'] = true;
				}
			});
			_universityError.push(error);
		});

		for (let i = 0; i < __customFields.length; i++) {
			let __inputType   = customFields[__customFields[i]].input_type;
			let __isMandatory = customFields[__customFields[i]]['is_mandatory?'];
			let __value = __inputType === 'drop_down' ? customFields[__customFields[i]].value ? customFields[__customFields[i]].value.label : null : customFields[__customFields[i]].value; // for dropdown value is saved in label
			if (!__isMandatory) {
				if (__inputType === 'document') {
					if (!__value) {
						continue;
					}
					let __document = customFields[__customFields[i]];
					documents_attributes = {
						...documents_attributes,
						[__document.document_name_id] : {
							document_name_id : __document.document_name_id,
							upload : __value,
						}
					};
					continue;
				}

				customFieldsData[__customFields[i]] = __value;
				continue;
			}

			switch (__inputType) {
				case 'text' :
					if (!utility.validateTextField (__value)) {
						errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'number' :
					if (!utility.validateNumber (__value)) {
						errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'date' :
					if (!__value) {
						errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'email' :
					if (!utility.validateEmail (__value)) {
						errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'phone_number' :
					if (!utility.validatePhoneNumber (__value)) {
						errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'url' :
					if (!utility.validateUrl (__value)) {
						errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'location' :
					if (!utility.validateTextField (__value)) {
						errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'drop_down':
					if (!__value) {
						errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'text_area':
					if (!utility.validateTextField (__value)) {
						errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'currency' :
					if (!utility.validateCurrency (__value)) {
						errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'check_box' :
					if (!__value.length) {
						errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'radio_button':
					if (!__value) {
						errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'video' :
					if (!__value) {
						errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
				case 'document' : {
					if (!__value) {
						errors[__customFields[i]] = true;
						break;
					}
					let __document = customFields[__customFields[i]];
					documents_attributes = {
						...documents_attributes,
						[__document.document_name_id] : {
							document_name_id : __document.document_name_id,
							upload : __value,
						}
					};
					break;
				}
				default :
					if (!__value) {
						errors[__customFields[i]] = true;
						break;
					}
					customFieldsData[__customFields[i]] = __value;
					break;
			}
		}

		/*Return if there is any error field*/

		if (Object.keys (errors).length) {
			this.setState ({
				errors : {
					...this.state.errors,
					...errors,
				},
				universityError : _universityError,
			});
			log.info ({errors});
			swal ({
				title : 'Please fill all the required fields before proceeding.',
				icon  : 'error',
				button : 'Ok'
			}).then (() => {
				utility.scrollToTop (Object.keys (errors)[0]);
			});
			return;
		}

		let uniCount = applicantUniversities.filter(uni => !uni._destroy)?.length;
		if (uniCount < 1) {
			this.notify ('Please add atleast one education.',{variant : 'error'});
			return;
		}

		let _applicantUniversities = applicantUniversities.map (uni => {
			let _data = {
				university_id        : uni.university_id,
				faculty              : uni.faculty,
				education_background : uni.education_background,
				department           : uni.department,
				start_month          : uni.start_month,
				start_year           : uni.start_year,
				graduated_month      : uni.graduated_month,
				other_university_name: uni.other_university_name,
				graduated_year       : uni.graduated_year,
				_destroy             : uni._destroy || false,
				is_latest            : uni.is_latest || false,
			};
			/**for new added education */
			if (uni.new) {
				return _data;
			}
			/**To update education */
			return {
				..._data,
				id : uni.id,
			};
		});

		/*Preapare data to submit */

		let jsonData = {
			applicant : {
				name                   : permanentFields.name,
				japanese_name          : permanentFields.hiragana,
				date_of_birth          : permanentFields.date_of_birth,
				email                  : permanentFields.email,
				applicant_universities_attributes : _applicantUniversities,
				form_id                : this.state.form.id,
				country_id             : permanentFields.country_id.value,
				custom_fields          : {
					...customFieldsData,
				},
				documents_attributes : {
					...documents_attributes,
				}
			}
		};

		let data = serialize ({
			...jsonData,
		});

		let result;

		this.setState ({
			submitLoading : true,
			finalSubmit : true,
		});

		try {
			result = await apis.registerApplicant (data);
			log.info ({result}, 'applicant register ok');
		}

		catch (err) {
			log.error ({err}, 'error registering user');
			this.setState ({
				submitLoading : false,
				finalSubmit : false,
			});
			if (err.response && err.response.data.errors) {
				this.notify (err.response.data.errors, {variant : 'error'});
				return;
			}
			if (err.response) {
				this.notify ('Something went wrong, please try again', {variant : 'error'});
			}
			return;
		}

		if (result.errors) {
			this.setState ({
				submitLoading : false,
				finalSubmit : false,
			});
			this.notify (result.errors, {variant : 'error'});
			return;
		}

		this.setState ({
			submitLoading : false,
			finalSubmit : false,
			universityError : [],
		});

		swal ({
			title  : result.message,
			text   : result.welcome_message,
			icon   : 'success',
			button : 'Ok',
			content : result.consultation_url && (
				<Typography>
					Please book an online consultation through this
					<Link href={result.consultation_url} style={{ cursor : 'pointer'}}> link </Link>
				</Typography>
			)
		}).then (() => {
			this.setState ({
				redirectLogin : true,
			});
		});
	}

	renderRadio = () => {
		
		return (
			<Radio color = 'primary' size = 'small'/>
		);
	}

	renderFields = (field) => {
	
		let customField      = this.state.customFields[field];
		let error            = this.state.errors[field];
		let inputType        = customField.input_type;
		let langCode         = this.state.langCode;
		let placeholder      = customField[langCode === 'en' ? 'eng_placeholder' : 'ja_placeholder'] || customField['translated_name'];

		switch (inputType) {
			case 'text' :
				return (
					<TextField
						value       = {customField.value}
						error       = {error}
						variant     = 'outlined'
						name        = {field}
						id          = {field}
						type        = 'text'
						onChange    = {this.handleCustomFields}
						placeholder = {placeholder}
						fullWidth
					/>
				);
			case 'number' :
				return (
					<TextField
						value       = {customField.value}
						error       = {error}
						variant     = 'outlined'
						name        = {field}
						id          = {field}
						type        = 'number'
						onChange    = {this.handleCustomFields}
						placeholder = {placeholder}
						fullWidth
					/>
				);
			case 'date' :
				return (
					<TextField
						value       = {customField.value}
						error       = {error}
						variant     = 'outlined'
						name        = {field}
						id          = {field}
						type        = 'date'
						onChange    = {this.handleCustomFields}
						onKeyDown   = {this.disableKeyBoard}
						placeholder = {placeholder}
						fullWidth
					/>
				);
			case 'email' :
				return (
					<TextField
						value       = {customField.value}
						error       = {error}
						variant     = 'outlined'
						name        = {field}
						id          = {field}
						type        = 'email'
						onChange    = {this.handleCustomFields}
						placeholder = {placeholder}
						fullWidth
					/>
				);
			case 'phone_number' :
				return (
					<TextField
						value       = {customField.value}
						error       = {error}
						variant     = 'outlined'
						name        = {field}
						id          = {field}
						type        = 'text'
						onChange    = {this.handleCustomFields}
						placeholder = {placeholder}
						fullWidth
					/>
				);
			case 'url' :
				return (
					<TextField
						value       = {customField.value}
						error       = {error}
						variant     = 'outlined'
						name        = {field}
						id          = {field}
						type        = 'url'
						onChange    = {this.handleCustomFields}
						placeholder = {placeholder}
						fullWidth
					/>
				);
			case 'location' :
				return (
					<TextField
						value       = {customField.value}
						error       = {error}
						variant     = 'outlined'
						name        = {field}
						id          = {field}
						type        = 'text'
						onChange    = {this.handleCustomFields}
						placeholder = {placeholder}
						fullWidth
					/>
				);
			case 'drop_down':
				return (
					<AutoSelect
						value       = {customField.value}
						className   = 'basic-single'
						classNamePrefix="select"
						options     = {utility.autoSelectOptions (customField.options, 'name', 'id')}
						name        = {field}
						id          = {field}
						error       = {error}
						searchable  = {true}
						placeholder = {placeholder}
						onChange    = {this.handleCustomSelect}
					/>
				);
			case 'text_area':
				return (
					<TextField
						value       = {customField.value}
						error       = {error}
						variant     = 'outlined'
						name        = {field}
						id          = {field}
						type        = 'text'
						multiline   = {true}
						rows        = {3}
						onChange    = {this.handleCustomFields}
						placeholder = {placeholder}
						fullWidth
					/>
				);
			case 'currency' :
				return (
					<TextField
						value       = {customField.value}
						error       = {error}
						variant     = 'outlined'
						name        = {field}
						id          = {field}
						type        = 'text'
						onChange    = {this.handleCustomFields}
						placeholder = {placeholder}
						fullWidth
					/>
				);
			case 'document' :
				return (
					<TextField
						type       = 'file'
						name       = {field}
						id         = {field}
						error      = {error}
						onChange   = {this.handleDocChange}
						helperText = {error ?
							"Doc size  is greater than 10"
							:
							"Upload docx, pdf, png/jpeg, pptx or txt of max size 10mb"}
						inputProps = {{
							accept : '.pdf,.txt,.pptx,.doc,.docx,image/png,image/jpeg,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
						}}
					/>
				);
			case 'check_box' :
				return (
					<FormGroup row = {true}>
						{customField.options.map ((item, index) => (
							<FormControlLabel
								label   = {item.name}
								key     = {index}
								control = {<Checkbox checked = {customField.value.includes(item.name) ? true : false} name = {field} value = {item.name} onChange = {this.handleCustomCheckbox}/>}/>
						))}
					</FormGroup>
				);
			case 'radio_button':
				return (
					<FormControl component = "fieldset">
						<RadioGroup value = {customField.value} onChange = {this.handleCustomFields} row = {true} name = {field} id = {field}>
							{customField.options.map ((item, index) => (
								<FormControlLabel value = {item.name}  label = {item.name} control = {this.renderRadio ()} key = {index}/>
							))}
						</RadioGroup>
					</FormControl>
				);
			case 'video':
				return (
					<VimeoVideoUploader
						videoName         = { this.state.permanentFields?.email || ''}
						onUploadCompleted = { this.handleVideoCustomFields }
						fieldName         = { field }
					/>
				);
			default :
				return;
		}
	}

	renderPermanentFields = () => {
		
		let permanent        = this.state.permanentFields;
		let errors           = this.state.errors;
		let langCode         = this.state.langCode;
		let form             = this.state.form;
		let isFastOffer      = form['is_fastoffer?'];

		const renderName = () => {
			return (
				<Grid container spacing = {2} alignItems = 'center'>
					<Grid container item xs = {12} sm = {12} md = {2} lg = {2} xl = {2}>
						<Typography variant = 'subtitle2'> {isFastOffer ? translations.apply.hiragana[langCode] : translations.apply.name[langCode]} <span style = {styles.required}>*</span></Typography>
					</Grid>
					<Grid item xs = {12} sm = {12} md = {9} lg = {9} xl = {9} className = 'pl-8'>
						<TextField
							value       = {permanent.name}
							error       = {errors.name}
							variant     = 'outlined'
							name        = 'name'
							id          = 'name'
							onChange    = {this.handlePermanentFields}
							placeholder = {isFastOffer ? translations.apply.hiragana[langCode] : translations.apply.name[langCode]}
							fullWidth
						/>
					</Grid>
				</Grid>
			);
		};
		
		const renderDob = () => {
			return (
				<Grid container spacing = {2} alignItems = 'center'>
					<Grid container item xs = {12} sm = {12} md = {2} lg = {2} xl = {2}>
						<Typography variant = 'subtitle2'> {translations.apply.date_of_birth[langCode]} <span style = {styles.required}>*</span></Typography>
					</Grid>
					<Grid item xs = {12} sm = {12} md = {9} lg = {9} xl = {9} className = 'pl-8'>
						<TextField
							value       = {permanent.date_of_birth}
							error       = {errors.date_of_birth}
							variant     = 'outlined'
							type        = 'date'
							name        = 'date_of_birth'
							id          = 'date_of_birth'
							inputProps  = {{max : moment(new Date()).format('YYYY-MM-DD')}}
							onChange    = {this.handlePermanentFields}
							onKeyDown   = {this.disableKeyBoard}
							placeholder = {translations.apply.date_of_birth[langCode]}
							fullWidth
						/>
					</Grid>
				</Grid>
			);
		};

		const renderEmail = () => {
			return (
				<Grid container spacing = {2} alignItems = 'center'>
					<Grid container item xs = {12} sm = {12} md = {2} lg = {2} xl = {2}>
						<Typography variant = 'subtitle2'> {translations.apply.email[langCode]} <span style = {styles.required}>*</span> </Typography>
					</Grid>
					<Grid item xs = {12} sm = {12} md = {9} lg = {9} xl = {9} className = 'pl-8'>
						<TextField
							value       = {permanent.email}
							error       = {errors.email}
							variant     = 'outlined'
							name        = 'email'
							id          = 'email'
							disabled
							onChange    = {this.handlePermanentFields}
							placeholder = {translations.apply.email[langCode]}
							fullWidth
						/>
					</Grid>
				</Grid>
			);
		};

		const renderConfirmEmail = () => {
			return (
				<Grid container spacing = {2} alignItems = 'center'>
					<Grid container item xs = {12} sm = {12} md = {2} lg = {2} xl = {2}>
						<Typography variant = 'subtitle2'> {translations.apply.confirm_email[langCode]} <span style = {styles.required}>*</span> </Typography>
					</Grid>
					<Grid item xs = {12} sm = {12} md = {9} lg = {9} xl = {9} className = 'pl-8'>
						<TextField
							value       = {permanent.confirmEmail}
							error       = {errors.confirmEmail}
							variant     = 'outlined'
							name        = 'confirmEmail'
							id          = 'confirmEmail'
							disabled
							onChange    = {this.handlePermanentFields}
							placeholder = {translations.apply.confirm_email[langCode]}
							fullWidth
						/>
					</Grid>
				</Grid>
			);
		};

		const renderCountry = () => {
			return (
				<Grid container spacing = {2} alignItems = 'center'>
					<Grid container item xs = {12} sm = {12} md = {2} lg = {2} xl = {2}>
						<Typography variant = 'subtitle2'> {translations.apply.country[langCode]} <span style = {styles.required}>*</span> </Typography>
					</Grid>
					<Grid item xs = {12} sm = {12} md = {9} lg = {9} xl = {9} className = 'pl-8'>
						<AutoSelect
							value       = {permanent.country_id}
							options     = {utility.autoSelectOptions (this.state.countries, 'name', 'id')}
							name        = 'country_id'
							id          = 'country_id'
							error       = {errors.country_id}
							searchable  = {true}
							onChange    = {this.handlePermanentSelect}
							placeholder = {translations.apply.country[langCode]}
						/>
					</Grid>
				</Grid>
			);
		};

		const renderUniveristy = () => {
			return (
				<Grid style = {{maxWidth:'100%'}}>
					<Typography variant='h6' style = {{color : light.error.main}} className='mt-12 mb-12' align='center'> {translations.apply.only_add_msg[this.state.langCode]}</Typography>
					{this.state.applicantUniversities.filter(uni => !uni._destroy).map ((university, index) => (
						<Grid key = {university.id} className='mb-8'>
							<Grid container spacing={2} alignItems='center'>
								<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
									<Typography variant='subtitle2'>
										{translations.apply.university[langCode]} <span style={styles.required}>*</span>
									</Typography>
									<Button
										onClick   = {() => this.handleRemoveEducation (university.id)}
										disabled  = {this.state.applicantUniversities.filter(uni => !uni._destroy).length === 1}
										className = 'mt-4'
										variant   = 'outlined'
										style     = {styles.deleteButton}
										size      = 'small'
									>
										{translations.apply.remove_ed[langCode]}
									</Button>
								</Grid>
								<Grid item xs = {12} sm = {12} md = {9} lg = {9} xl = {9} className = 'pl-8'>
									<AutoSelect
										value={university.university_id}
										options={utility.autoSelectOptions(this.state.universities, 'name', 'id')}
										name='university_id'
										searchable={true}
										onChange={(name, value) => this.handleUniversityFieldSelChange (name, value, university.id)}
										placeholder={translations.apply.university[langCode]}
										error={this.state.universityError[index]?.university_id}
									/>
									<FormHelperText style = {{fontSize : 13}}> {translations.apply.select_other_uni_msg[langCode]} </FormHelperText>
									{(university.otherUniversity || university.other_university_name) &&
										<Grid className='mt-16'>
											<TextField
												value={university.other_university_name || ''}
												name='other_university_name'
												onChange={(e) => this.handleUniversityFieldSelChange(e.currentTarget.name, e.currentTarget.value, university.id)}
												placeholder={translations.apply.otherUniversity[langCode]}
												variant='outlined'
												error={this.state.universityError[index]?.otherUniversity}
												fullWidth
											/>
										</Grid>
									}
								</Grid>
							</Grid>
							<Grid container spacing={2} alignItems='center'>
								<Grid container item xs={1} sm={1} md={2} lg={2} xl={2}>
								</Grid>
								<Grid item spacing = {2} container xs = {11} sm = {11} md = {9} lg = {9} xl = {9} className = 'pl-8' style = {{paddingRight : '0px'}}>
									<Grid item xs = {12} sm = {12} md = {3}>
										<Typography variant='subtitle2'> {translations.apply.education_backgrounds[langCode]} </Typography>
										<AutoSelect
											value={university.education_background}
											options={utility.autoSelectOptions(Object.keys(this.state.education_backgrounds).map(item => {
												return {
													name : this.state.education_backgrounds[item],
													value : item,
												};
											}), 'name', 'value')}
											name='education_background'
											searchable={false}
											error = {this.state.universityError[index]?.education_background}
											onChange={(name, value) => this.handleUniversityFieldSelChange(name, value, university.id)}
											placeholder={translations.apply.education_backgrounds[langCode]}
										/>
									</Grid>
									<Grid item xs = {12} sm = {12} md = {3}>
										<Typography variant='subtitle2'> {translations.apply.faculty[langCode]} </Typography>
										<AutoSelect
											value={university.faculty}
											options={utility.autoSelectOptions(Object.keys(this.state.faculties).map(item => {
												return {
													name : this.state.faculties[item],
													value : item,
												};
											}), 'name', 'value')}
											name='faculty'
											searchable={true}
											onChange={(name, value) => this.handleUniversityFieldSelChange(name, value, university.id)}
											error = {this.state.universityError[index]?.faculty}
											placeholder={translations.apply.faculty[langCode]}
										/>
									</Grid>
									<Grid item xs = {12} sm = {12} md = {3}>
										<Typography variant='subtitle2'> {translations.apply.department[langCode]} </Typography>
										<TextField
											value={university.department || ''}
											name='department'
											onChange={(e) => this.handleUniversityFieldSelChange(e.currentTarget.name, e.currentTarget.value, university.id)}
											placeholder={translations.apply.department[langCode]}
											variant = 'outlined'
											fullWidth
										/>
									</Grid>
									<Grid item xs = {12} sm = {12} md = {3}>
										<Typography variant='subtitle2' className = 'mr-16'> {translations.apply.present[langCode]} </Typography>
										<Radio
											checked  = {university.is_latest || false}
											onChange = {(e) => this.handleUniversityFieldSelChange('is_latest', e.target.checked, university.id)}
											name     = 'is_latest'
											value    = {university.is_latest || false}
											size     = 'small'
										/>
									</Grid>
									<Grid item xs = {12} sm = {12} md = {3}>
										<Typography variant='subtitle2'> {translations.apply.start_month[langCode]} </Typography>
										<AutoSelect
											value={university.start_month}
											options={utility.autoSelectOptions(utility.arrayRange(1, 12).map(item => {
												return {
													name : item,
													value : item,
												};
											}), 'name', 'value')}
											onChange={(name, value) => this.handleUniversityFieldSelChange(name, value, university.id)}
											name='start_month'
											searchable={true}
											error = {this.state.universityError[index]?.start_month}
											placeholder={translations.apply.start_month[langCode]}
										/>
									</Grid>
									<Grid item xs = {12} sm = {12} md = {3}>
										<Typography variant='subtitle2'> {translations.apply.start_year[langCode]} </Typography>
										<AutoSelect
											value={university.start_year}
											options={utility.autoSelectOptions(utility.arrayRange(1970, 2040).map(item => {
												return {
													name : item,
													value : item,
												};
											}), 'name', 'value')}
											name='start_year'
											onChange={(name, value) => this.handleUniversityFieldSelChange(name, value, university.id)}
											searchable={true}
											placeholder={translations.apply.start_year[langCode]}
											error = {this.state.universityError[index]?.start_year}
										/>
									</Grid>
									<Grid item xs = {12} sm = {12} md = {3}>
										<Typography variant='subtitle2'> {translations.apply.graduated_month[langCode]} </Typography>
										<AutoSelect
											value={university.graduated_month}
											options={utility.autoSelectOptions(utility.arrayRange(1, 12).map(item => {
												return {
													name : item,
													value : item,
												};
											}), 'name', 'value')}
											name='graduated_month'
											searchable={true}
											onChange={(name, value) => this.handleUniversityFieldSelChange(name, value, university.id)}
											placeholder={translations.apply.graduated_month[langCode]}
											error = {this.state.universityError[index]?.graduated_month}
										/>
									</Grid>
									<Grid item xs = {12} sm = {12} md = {3}>
										<Typography variant='subtitle2'> {translations.apply.graduation_year[langCode]} </Typography>
										<AutoSelect
											value={university.graduated_year}
											options={utility.autoSelectOptions(utility.arrayRange(1970, 2040).map(item => {
												return {
													name : item,
													value : item,
													isDisabled: item <= university.start_year ? true : false,
												};
											}), 'name', 'value')}
											name='graduated_year'
											onChange={(name, value) => this.handleUniversityFieldSelChange(name, value, university.id)}
											searchable={true}
											placeholder={translations.apply.graduation_year[langCode]}
											error = {this.state.universityError[index]?.graduated_year}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					))}
					<Grid className='mt-24' container justify='flex-start'>
						<Button variant='outlined' color='primary' onClick={this.addEducation}> {translations.apply.add_education[langCode]}</Button>
					</Grid>
				</Grid>
			);
		};

		const renderJapaneseName = () => {
			if (!isFastOffer) {
				return;
			}

			return (
				<Grid container spacing = {2} alignItems = 'center'>
					<Grid container item xs = {12} sm = {12} md = {2} lg = {2} xl = {2}>
						<Typography variant = 'subtitle2'> {translations.apply.name[langCode]} <span style = {styles.required}>*</span></Typography>
					</Grid>
					<Grid item xs = {12} sm = {12} md = {9} lg = {9} xl = {9} className = 'pl-8'>
						<TextField
							value       = {permanent.hiragana}
							error       = {errors.hiragana}
							variant     = 'outlined'
							name        = 'hiragana'
							id          = 'hiragana'
							onChange    = {this.handlePermanentFields}
							placeholder = {translations.apply.name[langCode]}
							fullWidth
						/>
					</Grid>
				</Grid>
			);
		};

		if (isFastOffer) {
			return (
				<React.Fragment>
					{renderJapaneseName ()}
					{renderName ()}
					{renderEmail ()}
					{renderConfirmEmail ()}
					{renderDob ()}
					{renderCountry ()}
					{renderUniveristy ()}
				</React.Fragment>
			);
		}

		return (
			<React.Fragment>
				{renderName ()}
				{renderJapaneseName ()}
				{renderDob ()}
				{renderEmail ()}
				{renderConfirmEmail ()}
				{renderCountry ()}
				{renderUniveristy ()}
			</React.Fragment>
		);
	}

	renderCustomFields = () => {
		
		let customFields = this.state.customFields;

		return (
			Object.keys (customFields).map ((field, index) => (
				<Grid container spacing = {2} alignItems = 'center' key = {index}>
					<Grid container item xs = {12} sm = {12} md = {2} lg = {2} xl = {2}>
						<Typography variant = 'subtitle2'>
							{this.state.langCode === 'en' ? customFields[field]['translated_name'] : field} {customFields[field]['is_mandatory?'] ?
								<span style = {styles.required}>*</span>
								:
								null}
						</Typography>
					</Grid>
					<Grid item xs = {11} sm = {11} md = {9} lg = {9} xl = {9} className = 'pl-8'>
						{this.renderFields (field)}
					</Grid>
					<Grid container alignItems = 'center' justify = 'flex-end' item xs = {1} sm = {1} md = {1} lg = {1} xl = {1} className = 'pl-8'>
						{customFields[field]['help_text'] &&
							<Tooltip title={customFields[field]['help_text']} arrow = {true}>
								<InfoIcon />
							</Tooltip>
						}
					</Grid>
				</Grid>
			))
		);
	}

	renderForm = () => {

		if (this.state.skeletons) {
			return (
				<Skeleton />
			);
		}
		let langCode = this.state.langCode;
		return (
			<div>
				<Stepper activeStep = {this.state.currentStep}>
					<Step>
						<StepLabel> {translations.apply.email_confirmation[langCode]} </StepLabel>
					</Step>
					<Step>
						<StepLabel> {translations.apply.registration_form[langCode]} </StepLabel>
					</Step>
				</Stepper>
				{this.renderSteps ()}
			</div>
		);
	}

	renderSteps = () => {
		switch (this.state.currentStep) {
			case 0 :
				return this.renderStepOne ();
			case 1 :
				return this.renderStepTwo ();
			default :
				return;
		}
	}

	renderStepOne = () => {
		
		let permanent        = this.state.permanentFields;
		let errors           = this.state.errors;
		let langCode         = this.state.langCode;
		return (
			<>
				<Typography align = 'center' color = 'error'> {translations.apply.primary_email_msg[langCode]}</Typography>
				<Grid container justify = 'center'>
					<Grid item xs = {12} sm = {12} md = {1} lg = {1} xl = {1} className = 'p-0'>
					</Grid>
					<Grid item xs = {12} sm = {12} md = {10} lg = {10} xl = {10} className = 'pt-24 pl-12 pr-12' container direction='column' style = {{gap : 20}}>
						<Grid container spacing = {2} alignItems = 'center'>
							<Grid container item xs = {12} sm = {12} md = {4} lg = {3} xl = {3}>
								<Typography variant = 'subtitle2'> {translations.apply.email[langCode]} <span style = {styles.required}>*</span> </Typography>
							</Grid>
							<Grid item xs = {12} sm = {12} md = {8} lg = {9} xl = {9} className = 'pl-8'>
								<TextField
									value       = {permanent.email}
									error       = {errors.email}
									variant     = 'outlined'
									name        = 'email'
									onPaste     = {this.disableCopyPaste}
									onCopy      = {this.disableCopyPaste}
									onChange    = {this.handlePermanentFields}
									placeholder = {translations.apply.email[langCode]}
									fullWidth
								/>
							</Grid>
						</Grid>
						<Grid container spacing = {2} alignItems = 'center'>
							<Grid container item xs = {12} sm = {12} md = {4} lg = {3} xl = {3}>
								<Typography variant = 'subtitle2'> {translations.apply.confirm_email[langCode]} <span style = {styles.required}>*</span> </Typography>
							</Grid>
							<Grid item xs = {12} sm = {12} md = {8} lg = {9} xl = {9} className = 'pl-8'>
								<TextField
									value       = {permanent.confirmEmail}
									error       = {errors.confirmEmail}
									variant     = 'outlined'
									onPaste     = {this.disableCopyPaste}
									onCopy      = {this.disableCopyPaste}
									name        = 'confirmEmail'
									onChange    = {this.handlePermanentFields}
									placeholder = {translations.apply.confirm_email[langCode]}
									fullWidth
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs = {12} sm = {12} md = {1} lg = {1} xl = {1} className = 'p-0'>
					</Grid>
					<div className = 'mt-24'>
						<Button color = 'primary' variant = 'contained' onClick = {this.verifyEmail}>
							{this.state.submitLoading ? <Loader color = 'secondary' /> : translations.apply.verify_email[langCode]}
						</Button>
					</div>
					<Grid className = 'mt-12' item xs = {12}>
						<Typography variant = 'body1' align = 'center'>
							{translations.apply.already_registered[langCode]}
							<Link component = {Navlink} to = {path.login}> {translations.apply.login_here[langCode]} </Link>
						</Typography>
					</Grid>
				</Grid>
			</>
		);
	}

	renderStepTwo = () =>{
	
		let langCode = this.state.langCode;

		return (
			<Grid container direction='column' style = {{gap : 20}}>
				{this.renderPermanentFields ()}
				{this.renderCustomFields ()}
				{this.renderTos ()}
				<Grid container justify = 'center' className = 'mt-12'>
					<Grid item xs = {12} sm = {12} md = {1} lg = {2} xl = {3} className = 'p-0'>
					</Grid>
					<Grid item xs = {12} sm = {12} md = {10} lg = {8} xl = {6} className = 'pt-24 pl-12 pr-12'>
						<Button variant = 'contained' color = 'primary' onClick = {this.onSubmit} fullWidth disabled = {!this.state.agreeTos || this.state.submitLoading}>
							{this.state.submitLoading ? <Loader color = 'secondary' /> : translations.apply.register[langCode]}
						</Button>
					</Grid>
					<Grid item xs = {12} sm = {12} md = {1} lg = {2} xl = {3} className = 'p-0'>
					</Grid>
				</Grid>
			</Grid>
		);
	}

	renderTos = () => {
		let langCode = this.state.langCode;
		return (
			<Grid style = {{maxWidth : '100%'}}>
				<div style = {styles.tosGrid}>
					{ReactHtmlParser (this.state.tos)}
				</div>
				<Grid className = 'mt-12'>
					<FormControlLabel
						label   = {translations.apply.i_agree[langCode]}
						control = {<Checkbox checked = {this.state.agreeTos} onChange = {this.handleAgreeTos}/>}
					/>
				</Grid>
			</Grid>
		);
	}

	renderActual = () => {
		
		if (this.state.loading || !this.state.customFields) {
			return (
				<div> </div>
			);
		}

		let langCode = this.state.langCode;
		return (
			<React.Fragment>
				<Grid container spacing = {2} alignItems = 'center' wrap='nowrap'>
					<Grid item xs = {12} sm = {4} md = {3} lg = {3} xl = {3}>
						<Typography variant = 'h3'> {translations.apply.apply_here[langCode]} </Typography>
					</Grid>
					<Grid item xs = {12} sm = {4} md = {6} lg = {7} xl = {7} style = {styles.banner (this.state.banner)}>
					</Grid>
					<Grid item xs = {12} sm = {4} md = {3} lg = {2} xl = {2} container justify = 'flex-end' wrap='nowrap'>
						<span className = 'mr-24' style = {this.state.langCode !== 'en' ? styles.langOn : styles.langOff} onClick = {() => this.handleTranslation ('jp')}> JP </span>
						<span variant = 'h5' style = {this.state.langCode === 'en' ? styles.langOn : styles.langOff} onClick = {() => this.handleTranslation ('en')}> EN </span>
					</Grid>
				</Grid>
				<Divider className = 'mt-12 mb-12'/>
				<Paper className = 'p-24' variant = 'outlined'>
					{this.renderForm ()}
				</Paper>
			</React.Fragment>
		);
	}

	render () {

		if (this.state.deadlinePage) {
			return (
				<Deadline />
			);
		}

		if (this.state.redirectLogin) {
			return (
				<Redirect to = {path.login}/>
			);
		}

		if (this.state.notFoundPage) {
			return (
				<Error404 />
			);
		}

		return (
			<React.Fragment>
				<Grid container spacing = {2} style = {styles.container}>
					<Grid item xs = {12} sm = {12} md = {1} lg = {2} xl = {3} className = 'p-0'>
					</Grid>
					<Grid item xs = {12} sm = {12} md = {10} lg = {8} xl = {6} className = 'pt-24 pl-12 pr-12'>
						{this.renderActual ()}
					</Grid>
					<Grid item xs = {12} sm = {12} md = {1} lg = {2} xl = {3} className = 'p-0'>
					</Grid>
				</Grid>
				{this.state.finalSubmit ? <Backdrop /> : null}
			</React.Fragment>

		);
	}
}

export default withSnackbar(Apply);
