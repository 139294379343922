import {light} from 'styles/muiTheme/colors';
export const styles = {
	
	avatar : {
		width    : '30px',
		height   : '30px',
		fontSize : '0.875em',
		backgroundColor : '#8191BE'
	},

	toolbar : {
		justifyContent : 'flex-end',
		paddingRight : '0px',
	},

	navLink : {
		color       : '#fff',
		fontSize    : '1rem',
		marginRight : '30px',
	},

	popper : {
		zIndex : 1000
	},

	langOn : {
		'color'          : 'white',
		'border'         : `1px solid ${light.primary.main}`,
		'padding'        : '4px 6px',
		'display'        : 'flex',
		'justifyContent' : 'center',
		'alignItems'     : 'center',
		'borderRadius'   : '12px',
		'width'          : '32px',
		'background'     : light.primary.main,
		'cursor'         : 'pointer',
		'height'         : '36px',
	},
	
	langOff : {
		'color'          : light.primary.main,
		'border'         : `1px solid ${light.primary.main}`,
		'display'        : 'flex',
		'justifyContent' : 'center',
		'alignItems'     : 'center',
		'padding'        : '4px 6px',
		'borderRadius'   : '12px',
		'width'          : '32px',
		'cursor'         : 'pointer',
		'height'         : '36px',
	},
	langBtnGrid : {
		padding : '16px 2px',
	}
};
