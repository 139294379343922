import React, { useState, useEffect } from 'react';
import ReactHtmlParser                from 'react-html-parser';
import Grid                           from '@material-ui/core/Grid';
import Button                         from '@material-ui/core/Button';
import Typography                     from '@material-ui/core/Typography';
import Paper                          from '@material-ui/core/Paper';
import Divider                        from '@material-ui/core/Divider';
import TextField                      from '@material-ui/core/TextField';
import RadioGroup                     from '@material-ui/core/RadioGroup';
import Radio                          from '@material-ui/core/Radio';
import FormControlLabel               from '@material-ui/core/FormControlLabel';
import FormControl                    from '@material-ui/core/FormControl';
import FormGroup                      from '@material-ui/core/FormGroup';
import Checkbox                       from '@material-ui/core/Checkbox';

import ImageContainer                 from 'components/atoms/ImageContainer';
import utility                        from 'common/utility';
import Skeleton                       from './Skeleton';
import Loader                         from 'components/atoms/Loader';
import { styles }                     from './style';

function Question (props) {

	const {questionNo, questionsData, totalQuestion, questionName,  displayImage, displayVideo, questionGroupId, timelimit, backLoading, remainingTime, exitTest} = props;
	const {questionLoading, handleQuestionBack, handleQuestionChange, onSubmitExam, submitLoading, handleChange, handleCheckbox, isSubmitted, onExitTest} = props;

	const [ minutes, setMinutes ] = useState(null);
	const [seconds, setSeconds ] =  useState(null);
	const [stopTime, setStopTime] = useState (false);

	useEffect(() => {
		let initialMinute = Math.floor(remainingTime / 60);
		let initialSeconds = remainingTime - initialMinute * 60;

		setStopTime (false);
		setSeconds (initialSeconds);
		setMinutes (initialMinute);
	}, [questionGroupId, remainingTime]);

	useEffect (() => {
		if (exitTest) {
			onExitTest (getRemainingTime ());
			return;
		}

	}, [exitTest]);

	useEffect(()=>{

		if (!remainingTime || stopTime) {
			return;
		}

		let myInterval = setInterval(() => {
			if (seconds === 0 && minutes === 0) {
				handleQuestionChange ('next');
				clearInterval (myInterval);
				return;
			}

			if (seconds > 0) {
				setSeconds(seconds - 1);
			}
			if (seconds === 0) {
				if (minutes === 0) {
					clearInterval(myInterval);
				} else {
					setMinutes(minutes - 1);
					setSeconds(59);
				}
			}
		}, 1000);

		return ()=> {
			clearInterval(myInterval);
		};
		// eslint-disable-next-line
	}, [seconds, minutes, stopTime]);

	const renderAnswers = (question) => {
 
		let questionType = question.question_type;
		let answer = question.answer;
		let options = question.options;
		let questionId = question.question_id;

		switch (questionType) {
			case 'free_text' :
				return (
					<TextField
						value     = {answer || ''}
						variant   = 'outlined'
						multiline = {true}
						placeholder = 'Write your answer'
						fullWidth
						rows      = {3}
						disabled  = {isSubmitted}
						onChange  = {(ev) => handleChange (ev, questionGroupId, questionId)}
					/>
				);
 
			case 'check_box' :
				return (
					<FormGroup row = {false}>
						{options.map ((item, index) => (
							<FormControlLabel
								label   = {item.option_name}
								key     = {index}
								control = {<Checkbox
									disabled = {isSubmitted}
									checked = {answer.includes(item.option_name)}
									onChange = {(ev) => handleCheckbox(ev, questionGroupId, questionId)}
									value = {item.option_name}
								/>}
							/>
						))}
					</FormGroup>
				);
 
			case 'radio_button' :
				return (
					<FormControl component = "fieldset">
						<RadioGroup value = {answer[0] || ''} onChange = {(ev) => handleChange (ev ,questionGroupId, questionId)}>
							{options.map ((item, index) => (
								<FormControlLabel value = {item.option_name}  label = {item.option_name} control = {renderRadio ()} key = {index}/>
							))}
						</RadioGroup>
					</FormControl>
				);
 
			case 'image' :
				return (
					<TextField
						type = 'file'
						name = 'video'
						disabled = {isSubmitted}
						onChange = {(ev) => handleChange (ev, questionGroupId, questionId)}
						inputProps = {{
							accept : "image/*"
						}}
					/>
				);

			default :
				return;
		}
	};

	const getRemainingTime = () => {
		return  (minutes * 60) + seconds;
	};

	const getTotalTime = () => {
		if (!timelimit) {
			return;
		}
		let initialMinute = Math.floor(timelimit / 60);
		let initialSeconds = timelimit - initialMinute * 60;
		return `${initialMinute < 10 ? '0' + initialMinute : initialMinute}:${initialSeconds < 10 ? '0' + initialSeconds : initialSeconds}`;
	};

	const renderRadio = () => {
		return (
			<Radio color = 'primary' size = 'small' disabled = {isSubmitted}/>
		);
	};

	const onQuestionChange = (where) => {
		setStopTime (true);
		if (where === 'next') {
			handleQuestionChange ('next', getRemainingTime ());
			return;
		}
		if (where === 'back') {
			handleQuestionBack ('back', getRemainingTime ());
			return;
		}
	};

	if (questionLoading) {
		return (
			<Skeleton />
		);
	}

	return (
		<Paper variant = 'outlined' className = 'p-12' style = {{width : '100%'}}>
			<Grid container spacing = {2}>
				<Grid item sm = {3}>
					<Typography variant = 'h5'> Question {questionNo}. </Typography>
				</Grid>
				<Grid item sm = {6}>
					{remainingTime ?
						<Typography variant = 'h5' align = 'center'>
							Time remaining/total:  {minutes < 10 ? '0' + minutes : minutes}:{seconds < 10 ? '0' + seconds : seconds} / {getTotalTime ()}
						</Typography>
						: null }
				</Grid>
				<Grid item sm = {3}>
					<Typography variant = 'h5' align = 'right'> {questionNo} of {totalQuestion} </Typography>
				</Grid>
			</Grid>
			<Divider className = 'mt-8 mb-8'/>
			<Grid className = 'mt-12'>
				<Typography variant = 'body1'> {ReactHtmlParser (questionName)} </Typography>
			</Grid>

			{displayImage ?
				<>
					<Divider className = 'mt-8 mb-8'/>
					<Grid>
						<ImageContainer
							height  = {350}
							width   = {'100%'}
							imgurl  = {displayImage}
							zoom    = {true}
						/>
					</Grid>
					<Divider className = 'mt-8 mb-8'/>
				</>
				:
				null}

			{displayVideo ?
				<>
					<Divider className = 'mt-8 mb-8'/>
					<Grid container justify = 'center'>
						<video width = "auto" height = "350" autoPlay controls controlsList = "nodownload" style = {{maxWidth : '100%'}}>
							<source src = {displayVideo} type="video/mp4" />
						</video>
					</Grid>
				</>
				:
				null}

			<Grid className = 'mt-24'>
				{questionsData.map ((que, j) => (
					<Grid key = {que.question_id}>
						<Grid className = 'mt-12'>
							<Typography variant = 'body1'> <strong className = 'mr-24'>({utility.romanNumber (j+1)}) </strong> {ReactHtmlParser (que.question_name)} </Typography>
						</Grid>
						<Grid className = 'mt-24'>
							{renderAnswers (que)}
						</Grid>
					</Grid>
				))}
			</Grid>
			<Divider className = 'mt-8 mb-8'/>
			<Grid container className = 'mt-24' spacing = {3}>
				<Grid item xs = {12} sm = {6} lg = {6} xl = {6} md = {6} container justify = 'center'>
					{questionNo === 1 ?
						null
						:
						<Button onClick = { () => onQuestionChange ('back')} color = 'secondary' variant = 'outlined' style = {styles.mainBtn} disabled = {backLoading}>
							{backLoading ? <Loader /> : 'Back'}
						</Button>
					}
				</Grid>
				<Grid item xs = {12} sm = {6} lg = {6} xl = {6} md = {6} container justify = 'center'>
					{questionNo === totalQuestion ?
						<Button onClick = {onSubmitExam} color = 'primary' variant = 'contained' style = {styles.mainBtn} disabled = {submitLoading}>
							{submitLoading ? <Loader /> : 'Submit'}
						</Button>
						:
						<Button onClick = {() => onQuestionChange ('next')} color = 'secondary' variant = 'contained' style = {styles.mainBtn} disabled = {submitLoading}>
							{submitLoading ? <Loader /> : 'Next'}
						</Button>
					}
				</Grid>
			</Grid>
		</Paper>
	);
}

export default Question;
