import {light} from 'styles/muiTheme/colors';

export const styles = {
	
	uploadButton : {
		height : '30px',
	},

	completeIcon : {
		color : light.success.main
	}
};