import React                         from 'react';
import swal                          from 'sweetalert';
import {withSnackbar}                from 'notistack';
import moment                        from 'moment';
import Paper                         from '@material-ui/core/Paper';
import TextField                     from '@material-ui/core/TextField';
import Divider                       from '@material-ui/core/Divider';
import Collapse                      from '@material-ui/core/Collapse';
import Grid                          from '@material-ui/core/Grid';
import Button                        from '@material-ui/core/Button';
import Typography                    from '@material-ui/core/Typography';

import Log                           from 'common/log';
import apis                          from 'common/apis';
import {errorMsg}                    from 'common/errors';
import utility                       from 'common/utility';
import authStore                     from 'redux/authStore';
import Loader                        from 'components/atoms/Loader';
import jclassStore, {saveReflection} from 'redux/jclassStore';
import translations from 'common/translations';

const log = Log ('ClassCard', 'info');

function ClassCard (props) {

	const {lesson, langCode} = props;

	const notify                      = props.enqueueSnackbar;
	const [openView, setOpenView]     = React.useState (false);
	const [openAdd, setOpenAdd]       = React.useState (false);
	const [reflection, setReflection] = React.useState ('');
	const [charLeft, setCharLeft]     = React.useState (1000);
	const [errors, setErrors]         = React.useState (false);
	const [loading, setLoading]       = React.useState (false);
	const [reopen, setReopen]         = React.useState (false);

	const handleViewReflection = () => {
		
		setOpenView (!openView);
	};

	const handleAddReflection = () => {
		
		setOpenAdd (!openAdd);
	};

	const onChangeReflection = (event) => {
		
		let value = event.target.value;
		let charLeft = 1000 - value.length;
		if (charLeft <0) {
			return;
		}
		setReflection (value);
		setCharLeft (charLeft);
	};

	const handleReopen = () => {
		swal ({
			title   : 'Notice',
			text    : lesson['show_late_submission_message'],
			icon    : 'warning',
		}).then (async()=> {
			setReopen (true);
			handleAddReflection ();
		});
	};

	const handleHideReopen = () => {
		setReopen (false);
		handleAddReflection();
	};

	const actionButtons = () => {

		if (lesson.comment) {
			return (
				<Button variant = 'contained' color = 'primary' onClick = {handleViewReflection}>
					{openView ? translations.button.hide_reflection[langCode] : translations.button.view_reflection[langCode]}
				</Button>
			);
		}

		if (reopen) {
			return (
				<Button variant = 'outlined' color = 'secondary' onClick = {handleHideReopen}>{translations.button.hide[langCode]}</Button>
			);
		}

		if (lesson.validity && lesson.reopen) {
			return (
				<Button variant = 'outlined' color = 'secondary' onClick = {handleReopen}>{translations.button.reopen[langCode]}</Button>
			);
		}

		if (lesson.validity && !lesson.comment) {
			return (
				<Button variant = 'outlined' color = 'secondary' onClick = {handleAddReflection}>
					{openAdd ? translations.button.hide_reflection[langCode] : translations.button.add_reflection[langCode]}
				</Button>
			);
		}

		if (!lesson.validity && !lesson.comment) {
			return (
				<Button color = 'secondary' disabled> {translations.button.incomplete[langCode]} </Button>
			);
		}
	};

	const onSaveReflection = async () => {
		
		if (!reflection && reflection.trim() === "" ) {
			setErrors (true);
			return;
		}

		let authInfo  = authStore.getState ();
		let applicant = authInfo.applicant;

		if (!applicant) {
			notify (translations.message.something_went_wrong[langCode], {variant : 'error'});
			return;
		}

		setLoading (true);

		let data = {
			reflection : {
				lesson_id    : lesson.lesson_id,
				applicant_id : applicant.id,
				comment      : reflection,
			}
		};

		let result;
		try {
			result = await apis.saveReflection (data);
			log.info ({result}, 'save reflection okk');
		}
		catch (err) {
			log.error ({err}, 'error saving reflection');
			if (err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			setLoading (false);
			return;
		}
		if (result.errors) {
			setLoading (false);
			notify (result.errors, {variant : 'error'});
			return;
		}
		jclassStore.dispatch (saveReflection (data, props.jclassId));
		setOpenView (true);
		setOpenAdd (false);
		setLoading (false);
		notify (result.message, {variant : 'success'});
	};

	return (
		<Paper variant = 'outlined' className = 'p-24 mt-12 mb-12'>
			<Grid container spacing = {3} alignItems = 'center'>
				<Grid item xs = {7} sm = {6}>
					<Typography variant = 'body1' style = {{color : '#5b5b5b'}}> {translations.jClass.lesson_start_date[langCode]}</Typography>
					<Typography variant = 'h4' style = {{color : '#5b5b5b'}}> {moment(moment (lesson.date)).locale(langCode === 'jp' ? 'ja' : langCode).format ("lll")} </Typography>
				</Grid>
				<Grid item sm = {6} xs = {5} container justify = 'flex-end'>
					{actionButtons ()}
				</Grid>
			</Grid>
			<Collapse in = {openView} timeout = "auto" unmountOnExit>
				<Divider className = 'mt-8 mb-8'/>
				<Typography variant = 'h6'> {translations.jClass.reflection[langCode]} :  </Typography>
				<Typography variant = 'body2'> {lesson.comment} </Typography>
			</Collapse>
			<Collapse in = {openAdd} timeout = "auto" unmountOnExit>
				<Grid className = 'mt-8 mb-8'>
					<TextField
						onChange    = {onChangeReflection}
						placeholder = 'Start typing your reflection here...'
						multiline   = {true}
						rows        = {5}
						rowsMax     = {8}
						value       = {reflection}
						variant     = 'outlined'
						error       = {errors}
						fullWidth   = {true}
					/>
				</Grid>
				<Grid container spacing = {2} alignItems = 'center'>
					<Grid item sm = {6}>
						<Typography variant = 'body2'> {translations.jClass.characters_left[langCode]} : {charLeft} </Typography>
					</Grid>
					<Grid item sm = {6} container justify = 'flex-end'>
						<Button variant = 'contained' color = 'primary' onClick = {onSaveReflection} style = {{minWidth : '130px'}}>
							{loading ? <Loader color = 'secondary' /> : translations.button.save_reflection[langCode]}
						</Button>
					</Grid>
				</Grid>
			</Collapse>
		</Paper>
	);
}

export default withSnackbar(ClassCard);
