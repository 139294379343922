import React                  from 'react';
import ReactHtmlParser        from 'react-html-parser';
import moment                 from 'moment';
import Paper                  from '@material-ui/core/Paper';
import Link                   from '@material-ui/core/Link';
import Button                 from '@material-ui/core/Button';
import Grid                   from '@material-ui/core/Grid';
import Divider                from '@material-ui/core/Divider';
import Typography             from '@material-ui/core/Typography';
import Collapse               from '@material-ui/core/Collapse';

import {hostname, int_result} from 'common/config';

function CompanyCard (props) {

	const {company, langCode} = props;
	const [view, setView] = React.useState (false);

	const onView = () => {
		setView (!view);
	};

	const jobDescription = company.job_description;

	const renderJobDescription = () => {

		const descriptionView = [];
		for  (let i = 0; i < jobDescription.length; i++) {
			let __categoryContent = jobDescription[i].category_content;
			let categoryContent = [];
			for (let j = 0; j < __categoryContent.length; j++) {
				if (Array.isArray (__categoryContent[j].question_ans) && !__categoryContent[j].question_ans.length) {
					continue;
				}
				if (!__categoryContent[j].question_ans) {
					continue;
				}
				categoryContent.push (
					<Grid key  = {j} className = 'mt-12'>
						<Typography variant = 'h6'> {__categoryContent[j].question_name}</Typography>
						<Typography variant = 'body2' style = {{opacity : 0.8, whiteSpace : 'pre-wrap'}}> {ReactHtmlParser(__categoryContent[j].question_ans)} </Typography>
					</Grid>
				);
			}
			if (!categoryContent.length) {
				continue;
			}
			
			descriptionView.push (
				<Grid key = {i} className = 'mt-16'>
					<Typography variant = 'subtitle1' align = 'center' style = {{backgroundColor : '#5367a0', color : '#fff'}}> {jobDescription[i].category_name} </Typography>
					{categoryContent}
				</Grid>
			);
		}
		return descriptionView;
	};

	if (!Array.isArray(company.interview_details) || !company.interview_details.length) {
		return <></>;
	}

	return (
		<Paper variant = 'outlined' className = 'p-24 mt-24'>
			<Grid container spacing = {3}>
				<Grid item xs = {4} sm = {4} md = {3} lg = {2} xl = {2} container justify = 'center'>
					{company.company_logo ?
						<img src = {`${hostname}${company.company_logo}`} alt = 'company_logo' width = {100} height = {100}/> :
						<div style = {{width : '100px', height : '100px', backgroundColor : '#eee', display : 'flex', justifyContent : 'center', alignItems : 'center'}}> Not available</div> }
				</Grid>
				<Grid item xs = {8} sm = {8} md = {9} lg = {10} xl = {10} alignItems = 'center' container>
					<Grid item xs = {12} sm = {12} md = {6} lg = {6} xl = {6}>
						<Typography variant = 'h4'> {company.company_name} </Typography>
						<Typography variant = 'h6' className = 'mt-12' style = {{color : '#7b7b7b'}}> {company.job_title} </Typography>
					</Grid>
					<Grid item xs = {12} sm = {12} md = {6} lg = {6} xl = {6} container justify = 'flex-end'>
						{company.questions && company.questions.length?
							<Button variant = 'contained' color = 'secondary' onClick = {() => props.startTest (company)}> {company.submitted ? 'Show Review' :'Submit Review' }</Button>
							:
							null}
							
					</Grid>
					<Grid container spacing = {2} alignItems = 'center' className = 'pt-12 pb-12'>
						<Button variant = 'outlined' color = 'secondary' onClick = {onView} size = 'small'> {!view ? 'View' : 'Hide'} Description </Button>
						<Typography variant = "body2" className = "ml-24"> <strong>Interview URL</strong>:
							{company.interview_url ? <Link style = {{cursor : 'pointer'}} target = "_blank" href = {company.interview_url}> {company.interview_url} </Link> : ' Not available'}
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Divider className = 'mt-12 mb-12'/>
			<Paper variant = 'outlined' className = 'p-4 pl-12 pr-12 mt-12' style = {{backgroundColor : '#7b7b7b', color : '#fff'}}>
				<Grid container spacing = {3}>
					<Grid item sm = {2}>
						<Typography variant = 'body2'> Date </Typography>
					</Grid>
					<Grid item sm = {2}>
						<Typography variant = 'body2'> Time </Typography>
					</Grid>
					<Grid item sm = {2}>
						<Typography variant = 'body2'> Type </Typography>
					</Grid>
					<Grid item sm = {2}>
						<Typography variant = 'body2'> Result </Typography>
					</Grid>
					<Grid item sm = {4}>
					</Grid>
				</Grid>
			</Paper>
			{company.interview_details.map ((interview, index) => (
				<Grid container spacing = {3} key  ={index} className = 'mt-4'>
					<Grid item sm = {2}>
						<Typography variant = 'subtitle2'> {moment(moment (interview.start_time)).locale(langCode === 'jp' ? 'ja' : 'en').format ('ll')} </Typography>
					</Grid>
					<Grid item sm = {2}>
						<Typography variant = 'subtitle2'> {moment.utc(interview.start_time).local ().format ('LT')} </Typography>
					</Grid>
					<Grid item sm = {2}>
						<Typography variant = 'subtitle2'> {interview.step_name} </Typography>
					</Grid>
					<Grid item sm = {2}>
						<Typography variant = 'subtitle2'> {int_result[interview.result] ? int_result[interview.result] : interview.result} </Typography>
					</Grid>
					<Grid item sm = {4} container justify= 'flex-end'>
						<Button variant='outlined' color = 'secondary' disabled = {!interview.start_time || interview.interview_time_confirm_status} onClick={() => props.onConfirmDateTime (company.job_id, interview.id, interview.interview_time_confirm_status, props.jobIndex)}> {interview.interview_time_confirm_status ? 'Confirmed' : 'Confirm date and time' } </Button>
						<Button disabled = {interview.ready_status} variant = 'contained' color = 'primary' className = 'ml-24' onClick = {() => props.onReady (company.job_id, interview.id, interview.ready_status, props.jobIndex)}> I'm ready </Button>
					</Grid>
				</Grid>
			))}
			<Divider className = 'mt-12 mb-12'/>
			<Grid container spacing = {3}>
				<Grid item sm = {3} xs = {12}>
					<Typography variant = 'body2'> <strong> Company Rank </strong> : {company.ratings && company.ratings.rank ?  company.ratings.rank : 'Not submitted'} </Typography>
					<Typography variant = 'body2'> <strong> Company Rate </strong> : {company.ratings && company.ratings.rate ?  company.ratings.rate : 'Not submitted'}</Typography>
				</Grid>
				<Grid item sm = {6} xs = {12}>
					<Typography variant = 'body2'> <strong> Reason </strong>: {company.ratings && company.ratings.comment ? company.ratings.comment : 'Not submitted'} </Typography>
				</Grid>
				<Grid item sm = {3} xs = {12} container spacing = {3} alignItems = 'center' justify = 'flex-end' style = {{paddingRight : '0px'}}>
					<Button variant = 'outlined' color = 'primary' onClick = {() => props.onUpdateRank(company.job_id, company.sgwj_event_id, props.jobIndex, company.ratings)}> Update your rank </Button>
				</Grid>
			</Grid>
			<Collapse in = {view} timeout = "auto" unmountOnExit>
				{renderJobDescription ()}
			</Collapse>
		</Paper>
	);
}

export default CompanyCard;
