/*This redux contains events related informations*/

import {createStore} from 'redux';

let initState = {
	events : [],
	appliedEvents : [],
};

export function eventsList (events) {
	
	return {
		type   : 'EVENTS_LIST',
		events : events,
	};
}

export function appliedEventsList (appliedEvents) {
	
	return {
		type          : 'APPLIED_EVENTS',
		appliedEvents : appliedEvents,
	};
}

export function newAppliedEvent (eventId) {
	
	return {
		type    : 'NEW_APPLIED_EVENT',
		eventId : eventId,
	};
}

function eventReducer (eventStore = initState, action) {
	
	switch (action.type) {

		case 'EVENTS_LIST' :
			return {
				...eventStore,
				events : action.events,
			};

		case 'APPLIED_EVENTS' :
			return {
				...eventStore,
				appliedEvents : action.appliedEvents,
			};

		case 'NEW_APPLIED_EVENT': {
			let event = eventStore.events.find (e => e.id === action.eventId);
			return {
				...eventStore,
				appliedEvents : [...eventStore.appliedEvents, event]
			};
		}

		default :
			return eventStore;
	}
}

const eventStore = createStore (eventReducer, initState);

export default eventStore;
