import React       from 'react';
import Grid        from '@material-ui/core/Grid';
import Typography  from '@material-ui/core/Typography';

import translations from 'common/translations';
import {fastOffer}  from 'common/config';
import Logo         from 'assets/Logo.png';
import FastLogo     from 'assets/Fast.png';
import {styles}     from './style';

function ResetMessage ({langCode}) {
	return (
		<Grid container spacing = {3} style = {styles.mainContainer} alignItems = 'center' justify = 'center'>
			<Grid item xs = {12} sm = {8} md = {6} lg = {4} xl = {4}>
				<Grid style = {styles.loginContainer}>
					<Grid container justify = 'center'>
						<img alt= 'Logo' src = {fastOffer ? FastLogo : Logo} height = {65} width = "auto" className = 'mb-24'/>
					</Grid>
					<Typography variant = 'h6' align = 'center'> {translations.reset.reset_message_login[langCode]}</Typography>
				</Grid>
			</Grid>
		</Grid>

	);
}

export default ResetMessage;
