const translations = {
	apply : {
		apply_here : {
			jp : '登録',
			en : 'Apply Here',
		},
		email_confirmation : {
			jp : 'メール確認',
			en : 'Email Confirmation',
		},
		registration_form : {
			jp : '登録フォーム',
			en : 'Registration Form',
		},
		verify_email : {
			jp : '確定',
			en : 'Verify Email',
		},
		already_registered : {
			jp : 'すでに登録されていますか？',
			en : 'Already registered?'
		},
		login_here : {
			jp : 'ログイン',
			en : 'Login Here',
		},
		i_agree : {
			jp : '利用規約とプライバシーポリシーに同意',
			en : 'I agree to the terms of use and privacy policy',
		},
		register : {
			jp : '登録',
			en : 'Register'
		},
		name :{
			jp : '名前（フルネーム）',
			en : 'Name',
		},
		email : {
			jp : 'Eメール',
			en : 'Email'
		},
		bachelor_university : {
			jp : '大学',
			en : 'Bachelor University',
		},
		master_university : {
			jp : '大学院',
			en : 'Master University'
		},
		university : {
			jp : '大学',
			en : 'University',
		},
		education_backgrounds : {
			jp : "大学",
			en : "Degree"
		},
		faculty : {
			jp : '学部',
			en : "Faculty",
		},
		graduated_month : {
			jp : '卒業月',
			en : 'Graduation Month',
		},
		graduation_year : {
			jp : '卒業年',
			en : 'Graduation Year'
		},
		start_month : {
			jp : '入学月',
			en : 'Start Month',
		},
		start_year : {
			jp : '入学年',
			en : 'Start Year'
		},
		department : {
			jp : '学科',
			en : 'Specialization'
		},
		education_details : {
			jp : '教育の詳細',
			en : 'University Details'
		},
		only_add_msg : {
			jp : '学部以上の学位のみを追加してください',
			en : 'Only add Undergraduate Degree and above'
		},
		remove_ed : {
			jp : '大学削除',
			en : 'Remove University'
		},
		present : {
			jp : '現在・最新',
			en : 'Latest/Present'
		},
		country  : {
			jp : '国・地域',
			en : 'Country/Region'
		},
		confirm_email : {
			jp : 'Eメール確認',
			en : 'Confirm Email'
		},
		date_of_birth : {
			jp : '誕生日',
			en : 'Date of Birth'
		},
		add_education : {
			jp : '大学追加',
			en : 'Add University'
		},
		hiragana : {
			jp : '名前（ローマ字・フルネーム）',
			en : 'Name (Romaji)'
		},
		profile_created : {
			jp : 'プロフィールが正常に作成されました。',
			en : 'Profile created successfully'
		},
		OK : {
			jp : 'OK',
			en : 'ok'
		},
		otherUniversity : {
			jp : '大学名に所属大学がない場合はこちらに入力',
			en : 'If there is no affiliated university in the university name, enter it here'
		},
		primary_email_msg : {
			jp : "連絡可能なメールアドレスを入力してください",
			en : "Enter your primary email address through which we can contact you."
		},
		select_other_uni_msg : {
			jp : "大学名が見つからない場合は「その他」を選択してください",
			en : "Select “Others” if you cannot find your university"
		}
	},
	nav : {
		home : {
			jp : 'ホーム',
			en : 'Home'
		},
		profile : {
			jp : 'プロフィール',
			en : 'Profile',
		},
		japanese_class : {
			jp : '日本語授業',
			en : 'Japanese Class',
		},
		interview : {
			jp : '面接',
			en : 'Interview',
		},
		online_session : {
			jp : 'オンラインセッション',
			en : 'Online Session',
		},
		last_login : {
			jp : '最終ログイン',
			en : 'Last Login',
		},
		change_password : {
			jp : 'パスワードを変更',
			en : 'Change Password',
		},
		logout : {
			jp : 'ログアウト',
			en : 'Logout',
		},
		onboarding : {
			jp : 'Onboarding',
			en : 'Onboarding',
		},
		change_lang : {
			jp : "言語を変更する",
			en : "Change language"
		}
	},
	home : {
		submit_review : {
			jp : '応募',
			en : 'Submit Review'
		},
		view : {
			jp : '応募済み',
			en : 'View',
		},
		view_description : {
			jp : ' 詳細を表示',
			en : 'Show Description'
		},
		hide_description : {
			jp : '詳細を非表示',
			en : 'Hide Description',
		},
		no_job_msg : {
			jp : '現在、アクティブなジョブはありません。',
			en : 'There are currently no active job survey for you.'
		},
		no_active_event : {
			jp : "表示するイベントはありません。",
			en : "There are currently no active events."
		},
		start_date : {
			jp : "開始日",
			en : "Start Date : "
		},
		end_date : {
			jp : "終了日",
			en : "End Date : "
		}
	},
	profile : {
		my_profile : {
			jp : 'プロフィール',
			en : 'My Profile',
		},
		edit : {
			jp : '編集',
			en : 'Edit',
		},
		student_id : {
			jp : '学生ID',
			en : 'Student ID'
		},
		cancel : {
			jp : 'キャンセル',
			en : 'Cancel',
		},
		save : {
			jp : '保存',
			en : 'Save'
		},
		record_video : {
			jp : 'ビデオを録画',
			en : 'Record Video',
		},
		not_available : {
			jp : '存在しません',
			en : 'Not available'
		},
		video : {
			jp : 'ビデオ',
			en : 'Video'
		},
		photo : {
			jp : '写真',
			en : 'Photograph'
		},
	},
	login : {
		login_to :  {
			jp : 'ログインしてください',
			en : 'Login to your account'
		},
		login : {
			jp : 'ログイン',
			en : 'Login',
		},
		email : {
			jp : 'Eメール',
			en : 'Email',
		},
		password : {
			jp : 'パスワード',
			en : 'Password',
		},
		forgot_password : {
			jp : 'パスワードをお忘れですか?',
			en : 'Forgot Password?'
		},
		password_required : {
			jp : 'パスワードをご入力ください。',
			en : 'Password is required',
		},
		invalid_email : {
			jp : '入力されたメールアドレスは正しくありません。',
			en : 'Email is not valid'
		},
		apply_fast_offer : {
			jp : '登録がまだの方はこちら',
			en : 'Click here if you have not registered yet',
		}
	},
	auth : {
		enter_phone_number : {
			jp : "電話番号を入力してください",
			en : "Enter your phone number",
		},
		enter_otp : {
			jp : "OTPコードの入力",
			en : "Enter OTP"
		},
		enter_otp_placeholder : {
			jp : "OTPコードを入力してください",
			en : "Enter OTP"
		},
		resend_otp : {
			jp : "OTPコードの再送信",
			en : 'Resend OTP'
		},
		submit : {
			jp : "送信",
			en : "Submit"
		},
		country_code_error :{
			jp : '国のコードを選択してください',
			en : 'Please select country code'
		},
		phone_number_error : {
			jp : "電話番号を入力してください",
			en : "Please enter your phone number"
		},
		invalid_otp : {
			jp : '正しいOTPコードを入力してください',
			en : 'You have entered wrong OTP'
		},
		too_many_resend : {
			jp : "再送信の試行回数の最大数に達しました",
			en : "You have reached maximum resend"
		},
		wait_for_n_sec: (n) => {
			return {
				jp: `再送信するまで${n}秒お待ちください`,
				en: `Wait for ${n} seconds to resend`,
			};
		},
	},
	reset : {
		set_account_pass : {
			jp : 'パスワードの設定',
			en : 'Set account password'
		},
		reset_account_pass : {
			jp : 'パスワードの再設定',
			en : 'Reset account password',
		},
		new_password : {
			jp : '新規パスワード',
			en : 'New Password'
		},
		confirm_pass : {
			jp : 'パスワードの確認',
			en : 'Confirm Password',
		},
		new_pass_empty : {
			jp : '新規パスワードを空白にできません。',
			en : 'New password cannot be empty'
		},
		confirm_pass_empty : {
			jp : 'パスワード確認を空白にできません。',
			en : 'Confirm password cannot be empty'
		},
		set_pass : {
			jp : 'パスワードの設定',
			en : 'Set Password',
		},
		reset_pass : {
			jp : 'パスワードの再設定',
			en : 'Reset Password',
		},
		cont : {
			jp : '続行',
			en : 'Continue',
		},
		reset_message_login : {
			jp : 'メールボックスをご確認の上、メールに記載されている手順に従って、パスワードの再設定をお願いします。',
			en : 'Please check your mailbox and follow the instructions sent on email to reset your password.'
		},
		
	},
	onboarding : {
		onboarding_title : {
			jp : "Onboarding",
			en : "Onboarding"
		},
		onboarding_doc : {
			jp : "Onboardingに関する資料",
			en : "Onboarding Documents"
		},
		download : {
			jp : "ダウンロード",
			en : "Download"
		},
		notification : {
			jp : "通知",
			en : "Notifications"
		}
	},
	button : {
		submit : {
			jp : "送信",
			en : "Submit"
		},
		
		ok : {
			jp : "OK",
			en : "OK"
		},
		cancel : {
			jp : 'キャンセル',
			en : 'Cancel',
		},
		edit : {
			jp : '編集',
			en : 'Edit',
		},
		applied : {
			jp : "登録済み",
			en : "Applied"
		},
		apply_now : {
			jp : "今すぐ登録",
			en : "Apply Now"
		},
		reopen : {
			jp : "リオープン",
			en : "Reopen"
		},
		hide : {
			jp : "非表示",
			en : "Hide",
		},
		hide_reflection : {
			jp : "Reflectionを非表示",
			en : "Hide Reflection",
		},
		add_reflection : {
			jp : "Reflectionを追加",
			en : "Add Reflection"
		},
		view_reflection : {
			jp : "Reflectionを表示",
			en : "View Reflection",
		},
		incomplete :{
			jp : "未完了",
			en : "Incomplete"
		},
		save_reflection : {
			jp : "Reflectionを保存",
			en : "Save Reflection"
		},
	},
	message : {
		are_you_sure_to_submit : {
			jp : "送信してもよろしいでしょうか？",
			en : "Are you sure, you want to submit the answer?"
		},
		fail_to_submit_answer : {
			jp : "送信に失敗しました。もう一度試してください。",
			en : "Failed to submit answer, Please try again"
		},
		fill_all_mandatory_fields : {
			jp : "必須項目を記入してください。",
			en : "Please fill all mandatory fields"
		},
		something_went_wrong : {
			jp : "エラー！もう一度試してください。",
			en : "Something went wrong, please try again"
		},
		upload_required_doc : {
			jp : "必要な資料をすべてアップロードしてください。",
			en : "Please upload all the required documents."
		},
	},
	jClass : {
		lesson_start_date : {
			jp : "授業の開始日",
			en : "LESSON START DATE"
		},
		reflection : {
			jp : "Reflection",
			en : "Reflection",
		},
		characters_left : {
			jp : "字数が残っています",
			en : "Characters left",
		},
	}
};
export default translations;
