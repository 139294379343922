import React                      from 'react';
import {Redirect, Route}          from 'react-router-dom';

import authentication             from 'common/auth';
import paths                      from 'common/path';
import authStore, {applicantInfo} from 'redux/authStore';
import commonStore, {changeLanguage} from 'redux/commonStore';

class PrivateRoute extends React.Component {

	constructor (props) {
		super (props);

		this.state = {
			loading         : true,
			isAuthenticated : false,
		};
	}

	componentDidMount = async () => {

		let authInfo = authStore.getState ();
		let applicant =  authInfo.applicant;

		if (!applicant) {
			let applicant;
			try {
				applicant = await authentication.verifyUser ();
			}
			catch (err) {
				this.setState ({
					loading : false,
				});
				return;
			}
			if (!applicant) {
				this.setState ({
					loading : false,
				});
				return;
			}
			let langCode = applicant['is_fastoffer'] ? 'jp' : 'en';
			commonStore.dispatch (changeLanguage (langCode));
			authStore.dispatch (applicantInfo (applicant));
			this.setState ({
				loading         : false,
				isAuthenticated : true,
			});
			return;
		}
		this.setState ({
			loading         : false,
			isAuthenticated : true,
		});
	}

	render () {

		const { component: Component, ...rest } = this.props;
		if (this.state.loading) {

			/*TODO 
			 *Add some loader page here
			 * */
			return (
				<div></div>
			);
		}

		return (
			<Route {...rest} render={(props) => (
				this.state.isAuthenticated === true
					? <Component {...props} />
					: <Redirect to = {{
						pathname : paths.login,
						state    : { from : props.location }
					}} />
			)} />
		);
	}
}

export default PrivateRoute;
