import React    from 'react';
import Grid     from '@material-ui/core/Grid';
import Paper     from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';

import utility  from 'common/utility';

function SkeletonLoader () {
	
	const rowsNumber = utility.getRandomNumber (1);
	const rows = [];

	for (let i = 0; i < rowsNumber; i++) {
		rows.push (
			<Grid key = {i} className = 'mt-12 bt-12'>
				<Skeleton variant = 'rect' height = {450}/>
			</Grid>
		);
	}

	return (
		<Paper variant = 'outlined' className = 'p-12' style = {{width : '100%'}}>
			<Grid container spacing = {2}>
				<Grid item sm = {3}>
					<Skeleton variant = 'text' height = {22}  width = {100}/>
				</Grid>
				<Grid item sm = {6}>
					<Skeleton variant = 'text' height = {22} width = {100}/>
				</Grid>
				<Grid item sm = {3}>
					<Skeleton variant = 'text' height = {22} width = {100}/>
				</Grid>
			</Grid>
			<Grid className = 'mt-24'>
				<Skeleton variant = 'rect' height = {100} />
			</Grid>
			<Grid className = 'mt-24'>
				<Skeleton variant = 'rect' height = {200} />
			</Grid>
			<Grid container className = 'mt-24' spacing = {3}>
				<Grid item xs = {12} sm = {6} lg = {6} xl = {6} md = {6} container justify = 'center'>
					<Skeleton variant = 'rect' height = {50}  width = {100}/>
				</Grid>
				<Grid item xs = {12} sm = {6} lg = {6} xl = {6} md = {6} container justify = 'center'>
					<Skeleton variant = 'rect' height = {50}  width = {100}/>
				</Grid>
			</Grid>
		</Paper>
	);
}

export default SkeletonLoader;
