import React, {useEffect}                  from 'react';
import moment                              from 'moment';
import {Redirect, NavLink}                 from 'react-router-dom';
import {withSnackbar}                      from 'notistack';
import Hidden                              from '@material-ui/core/Hidden';
import Link                                from '@material-ui/core/Link';
import IconButton                          from '@material-ui/core/IconButton';
import Typography                          from '@material-ui/core/Typography';
import AppBar                              from '@material-ui/core/AppBar';
import Grid                                from '@material-ui/core/Grid';
import Toolbar                             from '@material-ui/core/Toolbar';
import Button                              from '@material-ui/core/Button';
import ClickAwayListener                   from '@material-ui/core/ClickAwayListener';
import Grow                                from '@material-ui/core/Grow';
import Paper                               from '@material-ui/core/Paper';
import Popper                              from '@material-ui/core/Popper';
import MenuItem                            from '@material-ui/core/MenuItem';
import MenuList                            from '@material-ui/core/MenuList';
import ArrowDropDownIcon                   from '@material-ui/icons/ArrowDropDown';
import MenuIcon                            from '@material-ui/icons/Menu';

import {styles}                            from './style';
import ChangePassword                      from 'components/molecules/ChangePassword';
import commonStore, { changeLanguage }     from 'redux/commonStore';
import authStore                           from 'redux/authStore';
import apis                                from 'common/apis';
import path                                from 'common/path';
import translations                        from 'common/translations';
import {navigations, fastOfferNavigations} from 'common/config';
import utility                             from 'common/utility';
import {errorMsg}                          from 'common/errors';
import { language }                        from 'common/config';

function TopNav (props) {

	const [open, setOpen]                   = React.useState(false);
	const [passDialog, setPassDialog]       = React.useState (false);
	const [redirectLogin, setRedirectLogin] = React.useState (false);
	const anchorRef                         = React.useRef (null);
	const langMenuRef                       = React.useRef (null);
	const [name, setName]                   = React.useState ('');
	const [routes, setRoutes]               = React.useState ([]);
	const [lastLogin, setLastLogin]         = React.useState (false);
	const [langCode, setLangCode]           = React.useState ('en');
	const [langDropdown, setLangDropdown]   = React.useState (false);

	const getLanguage = () => {
		let commonInfo = commonStore.getState ();
		let langCode = commonInfo.langCode;
		setLangCode (langCode);
	};

	const commonUnsub  = commonStore.subscribe (getLanguage);

	const notify = props.enqueueSnackbar;

	useEffect (() => {
		getLanguage ();
		return commonUnsub;
	}, []);

	useEffect (() => {
		getProfile();
	}, [langCode]);

	const getProfile = () => {
		let profileInfo = authStore.getState ().applicant;
		let lastSignIn = profileInfo && profileInfo.last_sign_in_at;
		setName (profileInfo && profileInfo.name);
		setLastLogin (moment(moment(lastSignIn)).locale(langCode === 'jp' ? 'ja' : 'en' ).format ('lll'));
		let isFastOffer = profileInfo && profileInfo['is_fastoffer'];
		let isOnboarded = profileInfo && profileInfo['is_onboarded'];
		let __navigations = [...navigations];
		let onboardingPath = __navigations.find (nav => nav.link === path.onboarding);

		if (isFastOffer) {
			if (isOnboarded) {
				setRoutes([...fastOfferNavigations, onboardingPath]);
				return;
			}
			setRoutes (fastOfferNavigations);
			return;
		}
		if (isOnboarded) {
			setRoutes (__navigations);
			return;
		}
		setRoutes (__navigations.filter(nav => nav.link !== path.onboarding));
	};

	const handleToggle = () => {

		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {

		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	const handleListKeyDown = (event) => {

		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
			setLangDropdown (false);
		}
	};

	const onLogout = async () => {

		let result;
		try {
			result = await apis.logout ();
		}
		catch (err) {
			if (err.response.status === 401) {
				notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			return;
		}
		if (result.success) {
			localStorage.removeItem ('token');
			localStorage.removeItem ('lts');
			setRedirectLogin (true);
		}
		window.location.reload ();
	};

	const changePassword = () => {

		setPassDialog (true);
		setOpen (false);
	};

	const closePassModal = () => {
		
		setPassDialog (false);
	};

	const handleLangMenu = () => {
		setLangDropdown (!langDropdown);
	};

	if (redirectLogin) {
		return (
			<Redirect to = {path.login} />
		);
	}

	const handleTranslation = (langCode) => {
		commonStore.dispatch (changeLanguage (langCode));
		handleLangMenu();
	};

	return (
		<AppBar position = 'static' color = 'secondary'>
			<Toolbar style = {styles.toolbar} disableGutters = {false}>
				{passDialog ? <ChangePassword closeModal = {closePassModal}/> : null}
				<Grid container spacing = {2}>
					<Grid item xs = {2} sm = {1} md = {8} lg = {8} xl = {9} container alignItems = 'center'>
						<Hidden only = {['xs', 'sm']}>
							{routes.map ((nav, index) => (
								<Link component = {NavLink} exact to = {nav.link} style = {styles.navLink} key = {index}> {nav.name[langCode]} </Link>
							))}
						</Hidden>
						<Hidden only = {['md', 'lg', 'xl']}>
							<IconButton color = "inherit" edge = "start" onClick = {props.mobileNav}>
								<MenuIcon />
							</IconButton>
						</Hidden>
					</Grid>
					<Grid container justify = 'flex-end' alignItems = 'center' item xs = {10} sm = {11} md = {4} lg = {4} xl = {3}>
						<span className='mr-12' style={langCode !== 'en' ? styles.langOn : styles.langOff} onClick={() => handleTranslation('jp')}> JP </span>
						<span variant='h5' style={langCode === 'en' ? styles.langOn : styles.langOff} onClick={() =>handleTranslation('en')}> EN </span>
						<Button color="inherit" disableRipple onClick = {handleToggle} ref = {anchorRef} className = 'ml-12'>
							<Typography variant = 'body1'> {name} </Typography>
							<ArrowDropDownIcon />
						</Button>
						<Popper
							open     = {open}
							anchorEl = {anchorRef.current}
							transition
							disablePortal
							style    = {styles.popper}
						>
							{({TransitionProps, placement}) => (
								<Grow
									{...TransitionProps}
									style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
								>
									<Paper>
										<ClickAwayListener onClickAway={handleClose}>
											<MenuList autoFocusItem = {open} onKeyDown={handleListKeyDown}>
												<MenuItem> {translations.nav.last_login[langCode]} : {lastLogin} </MenuItem>
												<MenuItem onClick = {changePassword}>{translations.nav.change_password[langCode]}</MenuItem>
												<MenuItem onClick = {onLogout}> {translations.nav.logout[langCode]} </MenuItem>
											</MenuList>
										</ClickAwayListener>
									</Paper>
								</Grow>
							)}
						</Popper>
					</Grid>
				</Grid>
			</Toolbar>
		</AppBar>
	);
}

export default withSnackbar(TopNav);
