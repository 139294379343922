import React                from 'react';
import ReactHtmlParser      from 'react-html-parser';
import Dialog               from '@material-ui/core/Dialog';
import DialogContent        from '@material-ui/core/DialogContent';
import DialogActions        from '@material-ui/core/DialogActions';
import Typography           from '@material-ui/core/Typography';
import Grid                 from '@material-ui/core/Grid';
import Button               from '@material-ui/core/Button';
import TextField            from '@material-ui/core/TextField';
import Divider              from '@material-ui/core/Divider';
import RadioGroup           from '@material-ui/core/RadioGroup';
import Radio                from '@material-ui/core/Radio';
import FormControlLabel     from '@material-ui/core/FormControlLabel';
import FormControl          from '@material-ui/core/FormControl';
import FormGroup            from '@material-ui/core/FormGroup';
import Checkbox             from '@material-ui/core/Checkbox';

import AutoSelect           from 'components/atoms/AutoSelect';
import Loader               from 'components/atoms/Loader';
import utility              from 'common/utility';
import {light}              from 'styles/muiTheme/colors';

function TestModal (props) {

	const {questions, handleChange, handleCheckbox, handleDropDown, submitAnswer, submitted} = props;

	const renderAnswers = (question) => {

		let questionType = question.question_type;
		if (submitted) {
			if (Array.isArray (question.answer)) {
				return (
					<Typography variant = 'body1'> <strong className = 'mr-24'> Ans.</strong> {question.answer.join(', ')} </Typography>
				);
			}
			return (
				<Typography variant = 'body1'>  <strong className = 'mr-24'> Ans.</strong> {question.answer} </Typography>
			);
		}

		switch (questionType) {
			case 'free_text' :
				return (
					<TextField
						value     = {question.answer || ''}
						variant   = 'outlined'
						multiline = {true}
						placeholder = 'Write your answer'
						fullWidth
						rows      = {3}
						onChange  = {(ev) => handleChange (ev, question)}
					/>
				);

			case 'check_box' :
				return (
					<FormGroup row = {false}>
						{question.options.map ((item, index) => (
							<FormControlLabel
								label   = {item.option_name}
								key     = {index}
								control = {<Checkbox checked = {question.answer.includes(item.option_name)} onChange = {(ev) => handleCheckbox(ev, question)} value = {item.option_name}/>}/>
						))}
					</FormGroup>
				);

			case 'radio_button' :
				return (
					<FormControl component = "fieldset">
						<RadioGroup value = {question.answer[0] || ''} onChange = {(ev) => handleChange (ev, question)}>
							{question.options.map ((item, index) => (
								<FormControlLabel value = {item.option_name}  label = {item.option_name} control = {renderRadio ()} key = {index}/>
							))}
						</RadioGroup>
					</FormControl>
				);

			case 'drop_down' : {
				let answer =  Array.isArray(question.answer) ? question.answer[0] : question.answer;
				if (answer) {
					let option = question.options.find (o => o.option_name === answer);
					answer = option ? {label : option.option_name, value : option.option_name} : {};
				}

				return (
					<div style = {{width : '30%'}}>
						<AutoSelect
							options     = {utility.autoSelectOptions (question.options, 'option_name', 'option_name')}
							value       = {answer}
							searchable  = {false}
							onChange    = {(name, value) => handleDropDown(name, value, question)}
						/>
					</div>
				);
			}

			default :
				return;
		}
	};

	const renderRadio = () => {
		return (
			<Radio color = 'primary' size = 'small'/>
		);
	};

	return (
		<Dialog open = {true} maxWidth = 'lg' fullWidth = {true} onBackdropClick = {() => props.close(false)}>
			<Typography variant = 'h5' align = 'center' className = 'p-16'> 応募を完了する場合、Submitを押してください。 </Typography>
			<DialogContent>
				{questions.map ((question, i) => (
					<Grid key = {question.question_id}>
						<Grid container alignItems = 'center'>
							<Typography variant = 'h6' className = 'mr-24'>
							Q. {i+1}
								{question.is_mandatory ? <span style={{color : light.error.main, marginLeft : 4}}>*</span> : null}
							</Typography>
							<Typography variant = 'body1'> {ReactHtmlParser (question.question_name)} </Typography>
						</Grid>
						<Grid className = 'mt-12'>
							{renderAnswers (question)}
						</Grid>
						<Divider className = 'mt-16 mb-16'/>
					</Grid>
				))}
			</DialogContent>
			<DialogActions>
				<Button variant ='outlined' color = 'secondary' className = 'mr-24' onClick = {props.onCancel}> Cancel </Button>
				{!submitted ? <Button variant ='contained' color = 'primary' onClick = {submitAnswer}>
					{props.submitLoading ? <Loader /> : 'Submit'}
				</Button> : null}
			</DialogActions>
		</Dialog>
	);
}

export default TestModal;
