import {lighten} from '@material-ui/core/styles';
import {light}   from 'styles/muiTheme/colors';

export const styles = {
	
	paper : {
		backgroundColor : lighten (light.secondary.main, 0.05),
		padding         : '12px 24px',
		borderRadius    : '0px',
		color           : '#fff'
	},

	grid : {
		borderBottom : '1px solid #525252'
	},

	navLink : {
		color    : '#fff',
		fontSize : '1rem',
	}
};
