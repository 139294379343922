import React    from 'react';
import Grid     from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

import utility  from 'common/utility';

function SkeletonLoader () {
	
	const rowsNumber = utility.getRandomNumber (1, 2);
	const rows = [];

	for (let i = 0; i < rowsNumber; i++) {
		rows.push (
			<Grid key = {i} className = 'mt-12 bt-12'>
				<Skeleton variant = 'rect' height = {300}/>
			</Grid>
		);
	}

	return (
		<Grid>
			{rows}
		</Grid>
	);
}

export default SkeletonLoader;
