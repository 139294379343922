import {light} from 'styles/muiTheme/colors';
import { styles as profileStyle } from 'pages/Profile/style';

export const styles = {
	
	required : {
		color : light.error.main
	},

	langOn : {
		'color'          : 'white',
		'border'         : `1px solid ${light.primary.main}`,
		'padding'        : '4px 6px',
		'display'        : 'flex',
		'justifyContent' : 'center',
		'alignItems'     : 'center',
		'borderRadius'   : '12px',
		'width'          : '32px',
		'background'     : light.primary.main,
		'cursor'         : 'pointer',
	},
	
	langOff : {
		'color'          : light.primary.main,
		'border'         : `1px solid ${light.primary.main}`,
		'display'        : 'flex',
		'justifyContent' : 'center',
		'alignItems'     : 'center',
		'padding'        : '4px 6px',
		'borderRadius'   : '12px',
		'width'          : '32px',
		'cursor'         : 'pointer',
	},

	tosGrid : {
		maxHeight    : '250px',
		overflow     : 'hidden auto',
		border       : '1px solid #ccc',
		borderRadius : '4px',
		padding      : '12px',
		marginTop    : '12px',
	},
	banner : (src) => {
		if (!src) {
			return {};
		}
		return {
			background         : `url(${src})`,
			height             : '100px',
			backgroundSize     : 'contain',
			backgroundPosition : 'center',
			backgroundRepeat   : 'no-repeat',
		};
	},
	container : {
		padding : '36px 12px'
	},
	deleteButton : {
		...profileStyle.deleteButton
	}
};
