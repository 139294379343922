const paths = {
	landing        : '/',
	login          : '/login',
	profile        : '/profile',
	jclass         : '/jclass',
	apply          : '/apply/application_form',
	resetPassword  : '/reset_password',
	interview      : '/interview',
	zoom           : '/zoom',
	forgotPassword : '/forgot',
	onlineSession  : '/onlineSession',
	exam           : '/exam',
	onboarding     : '/onboarding',
	auth           : '/auth',
};

export default paths;
