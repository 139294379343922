import React                       from 'react';
import {Redirect, Link as Navlink} from 'react-router-dom';
import {withSnackbar}              from 'notistack';
import Grid                        from '@material-ui/core/Grid';
import Link                        from '@material-ui/core/Link';
import Typography                  from '@material-ui/core/Typography';
import FormHelperText              from '@material-ui/core/FormHelperText';
import IconButton                  from '@material-ui/core/IconButton';
import TextField                   from '@material-ui/core/TextField';
import Button                      from '@material-ui/core/Button';
import Person                      from '@material-ui/icons/Person';
import Lock                        from '@material-ui/icons/Lock';
import Visibility                  from '@material-ui/icons/Visibility';
import VisibilityOff               from '@material-ui/icons/VisibilityOff';

import utility                     from 'common/utility';
import apis                        from 'common/apis';
import {fastOffer, fastHost}       from 'common/config';
import path                        from 'common/path';
import Log                         from 'common/log';
import authentication              from 'common/auth';
import translations                from 'common/translations';
import Loader                      from 'components/atoms/Loader';
import authStore, {applicantInfo}  from 'redux/authStore';
import {twoFactAuth}               from 'redux/authStore';
import commonStore                 from 'redux/commonStore';
import { changeLanguage }          from 'redux/commonStore';
import Logo                        from 'assets/Logo.png';
import FastLogo                    from 'assets/Fast.png';
import {styles}                    from './style';

const log = Log ('Login', 'info');

class Login extends React.Component {
	
	constructor (props) {
		super (props);

		this.state = {
			email        : '',
			password     : '',
			showPassword : false,
			errors       : {
				email    : false,
				password : false
			},
			loginSuccess : false,
			loginErrMsg  : '',
			langCode     : 'en',
			isFastOffer  : false,
			isLoading    : false,
			isAuthenticated : false,
			authScreen    : false,
			applicantId   : "",
			authEnabled : false
		};
		this.notify = this.props.enqueueSnackbar;
	}

	componentDidMount = async () => {

		this.setState ({
			langCode : fastOffer ? 'jp' : 'en'
		});

		let isVerifed;
		try {
			isVerifed = await authentication.verifyUser ();
		}
		catch (err) {
			log.error ({err}, 'login authentication failed');
			return;
		}

		if (!isVerifed) {
			return;
		}

		authStore.dispatch (applicantInfo (isVerifed));
		this.setState ({
			isAuthenticated : true,
		});
	}

	handleChange = (event) => {
		
		const {name, value} = event.currentTarget;

		this.setState ({
			[name] : value,
			errors : {
				...this.state.errors,
				[name] : false,
			}
		});
	}

	handleEnterPress = (event) => {
		
		if (event.keyCode !== 13) {
			return;
		}

		this.onLogin ();
	}

	showPassword = () => {
		
		this.setState ({
			showPassword : true,
		});
	}

	hidePassword = () => {
	
		this.setState ({
			showPassword : false,
		});
	}

	onLogin = async () => {
		
		let email    = this.state.email;
		let password = this.state.password;
		let errors = {};

		if (!utility.validateEmail (email)) {
			errors.email = true;
		}

		if (!password || password.trim () === "") {
			errors.password = true;
		}

		if (Object.keys (errors).length) {
			this.setState ({
				errors : {
					...errors
				}
			});
			return;
		}

		let data = {
			email,
			password,
		};
		let result;

		this.setState ({
			isLoading : true,
		});

		try {
			result = await apis.login (data);
			log.info ({user : result}, 'getting user info');
		}

		catch (err) {
			log.error ({err}, 'failed to login');
			this.notify ('Something went wrong', {variant : 'error'});
			this.setState ({
				isLoading : false,
			});
			return;
		}

		if (result.errors) {
			this.setState ({
				loginErrMsg : result.errors,
				isLoading   : false,
			});
			return;
		}

		if (result.authy_enabled !== undefined) {
			authStore.dispatch (twoFactAuth ({authEnabled : result.authy_enabled, applicantId : result.applicant_id}));
			this.setState ({
				authScreen : true,
			});
			return;
		}

		/*** Maintaining users session in local storage***/
		let token   = result.access_token.token;
		let loginTs = new Date ().getTime (); //maintaining when the user first login to the browser.

		localStorage.setItem ('token', token);
		localStorage.setItem ('lts', loginTs);

		authStore.dispatch (applicantInfo (result.applicant));
		let isFastOffer =  result.applicant['is_fastoffer'];
		if (isFastOffer) {
			commonStore.dispatch (changeLanguage ('jp'));
		}
		this.setState ({
			loginSuccess : true,
			isLoading    : false,
		});
	}

	renderStartAdornment = (name) => {

		return (
			name === 'email' ? <Person style = {styles.icon}/> : <Lock style = {styles.icon}/>
		);
	}

	renderEndAdornment = () => {
		
		return (
			<IconButton
				size          = 'small'
				onMouseDown   = {this.showPassword}
				onMouseUp     = {this.hidePassword}
				onPointerDown = {this.showPassword}
				onPointerUp   = {this.hidePassword}
				style         = {styles.iconButton}
			>
				{!this.state.showPassword ? <Visibility style = {styles.icon}/> : <VisibilityOff style = {styles.icon}/>}
			</IconButton>
		);
	}

	redirectFastOffer = () => {
		window.location.replace (`${fastHost}/apply`);
	}

	render () {

		let langCode =  this.state.langCode;

		if (this.state.loginSuccess || this.state.isAuthenticated) {
			return (
				<Redirect to = {path.landing} push = {true}/>
			);
		}

		if (this.state.authScreen) {
			return (
				<Redirect to = {path.auth} push = {true}/>
			);
		}
		
		return (
			<Grid container spacing = {3} style = {styles.mainContainer} alignItems = 'center' justify = 'center'>
				<Grid item xs = {12} sm = {8} md = {6} lg = {4} xl = {4}>
					<Grid style = {styles.loginContainer}>
						<Grid container justify = 'center'>
							<img alt= 'Logo' src = {fastOffer ? FastLogo : Logo} height = {65} width = 'auto' className = 'mb-24'/>
						</Grid>
						<Typography variant = 'h4' align = 'center' className = 'mb-16'> {translations.login.login_to[langCode]} </Typography>
						<Grid style = {styles.inputContainer}>
							<TextField
								placeholder = {translations.login.email[langCode]}
								variant     = 'outlined'
								value       = {this.state.email}
								error       = {this.state.errors.email}
								fullWidth   = {true}
								name        = 'email'
								onChange    = {this.handleChange}
								type        = 'email'
								onKeyUp     = {this.handleEnterPress}
								InputProps  = {{
									style : styles.inputProps,
									startAdornment : this.renderStartAdornment ('email')
								}}
							/>
							{this.state.errors.email ? <FormHelperText error = {true}> {translations.login.invalid_email[langCode]} </FormHelperText> : null}
						</Grid>
						<Grid style = {styles.inputContainer}>
							<TextField
								placeholder = {translations.login.password[langCode]}
								variant     = 'outlined'
								value       = {this.state.password}
								error       = {this.state.errors.password}
								fullWidth   = {true}
								onChange    = {this.handleChange}
								type        = {this.state.showPassword ? 'text' : 'password'}
								name        = 'password'
								onKeyUp     = {this.handleEnterPress}
								InputProps  = {{
									style : styles.inputProps,
									startAdornment : this.renderStartAdornment ('password'),
									endAdornment : this.renderEndAdornment ()
								}}
							/>
							{this.state.errors.password ? <FormHelperText error = {true}> {translations.login.password_required[langCode]} </FormHelperText> : null}
						</Grid>
						<Button variant = 'contained' color = 'secondary' fullWidth onClick = {this.onLogin} disabled = {this.state.isLoading}>
							{this.state.isLoading ? <Loader /> : translations.login.login[langCode]}
						</Button>
						<Grid className = 'mt-12' container justify = 'center'>
							<Link  color = 'primary' component = {Navlink} to = {path.forgotPassword}> {translations.login.forgot_password[langCode]} </Link>
						</Grid>
						{fastOffer ?
							<Grid className = 'mt-12' container justify = 'center'>
								<Link  color = 'primary' onClick = {this.redirectFastOffer} style = {{cursor : 'pointer'}}> {translations.login.apply_fast_offer[langCode]} </Link>
							</Grid>
							: null}
					</Grid>
					{this.state.loginErrMsg ? <FormHelperText error = {true} style = {styles.loginErrMsg}> {this.state.loginErrMsg} </FormHelperText> : null}
				</Grid>
			</Grid>
		);
	}
}

export default withSnackbar(Login);
