import path from 'common/path';

const utility = {};

utility.validateEmail = (email) => {

	//eslint-disable-next-line
	let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

	if (!reg.test (email)) {
		return false;
	}
	return true;
};

utility.romanNumber = (num) => {
	if (typeof num !== 'number')
		return false;

	var digits = String(+num).split(""),
		key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
			"","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
			"","I","II","III","IV","V","VI","VII","VIII","IX"],
		roman_num = "",
		i = 3;
	while (i--)
		roman_num = (key[+digits.pop() + (i * 10)] || "") + roman_num;
	return (Array(+digits.join("") + 1).join("M") + roman_num).toLowerCase();
};

utility.validateTextField = (value) => {
	
	if (!value || value.trim () === '') {
		return false;
	}
	return true;
};

utility.validateNumber = (value) => {
	
	if (!value) {
		return false;
	}

	return true;
};

utility.validateCurrency = (value) => {

	let reg = /¥[^@\s]*$/;
	if (!reg.test (value)) {
		return false;
	}

	return true;
};

utility.validatePhoneNumber = (value) => {

	let reg = /^[0-9]{8,12}$/;
	if (!reg.test (value)) {
		return false;
	}

	return true;
};

utility.validateUrl = (value) => {

	let reg = /^(https?):\/\/[^\s$.?#].[^\s]*$/i;
	if (!reg.test (value)) {
		return false;
	}

	return true;
};

utility.getNameInitials = (name) => {
	
	if (!name) {
		return;
	}

	let _initials = name.split (" ");
	let initials;
	if (!_initials[1]) {
		initials = `${_initials[0][0]}${_initials[0][1]}`.toUpperCase ();
		return initials;
	}
	initials =  `${_initials[0][0]}${_initials[1][0]}`.toUpperCase ();
	return initials;
};

utility.getRandomNumber = (min, max) => {
	
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1) + min);
};

utility.sortData = (array, key, order) => {
 
	if (key === 'date') {
		return (array.sort ((item1, item2) => compareTime (item1, item2, order)));
	}
 
	function compareTime (item1, item2, order) {
 
		let item1Date = new Date (item1.date);
		let item2Date = new Date (item2.date);
 
		if(item1Date < item2Date){
			return order;
		}
		else if(item1Date > item2Date){
			return order = 0 - order;
		}
		else{
			return 0;
		}
	}
};

utility.autoSelectOptions = (array, name, value) => {
		
	if (!Array.isArray (array)) {
		return;
	}

	let options = array.map (item => {
		return {
			label : item[name],
			value : item[value],
			...item
		};
	});
	return options;
};

utility.redirectLogin = () => {
	setTimeout (()=> {window.location.pathname = path.login;}, 3000);
};

utility.scrollToTop = (elemId) => {

	let el = document.getElementById(elemId);
	if (!el) {
		return;
	}
	el.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
};

utility.arrayRange = (start, stop, step = 1) => {
	return Array.from(
		{ length: (stop - start) / step + 1 },
		(value, index) => start + index * step
	);
};

utility.generateRandomInteger = (n) => {
	const min = Math.pow(10, n - 1); // Minimum value with n digits
	const max = Math.pow(10, n) - 1; // Maximum value with n digits
	return Math.floor(Math.random() * (max - min + 1)) + min;
};

utility.sleep = (seconds) => {
	return new Promise(resolve => {
		setTimeout(resolve, seconds * 1000);
	});
};

utility.isValidURL = (url) => {
	// Regular expression for a valid URL
	const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

	// Test the provided URL against the regular expression
	return urlPattern.test(url);
};

utility.findKeyInDeepNestedObject = (obj, keyToFind) => {
	for (const key in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, key)) {
			if (key === keyToFind) {
				return obj[key]; // Key found, return its value
			} else if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
				// If the property is an object (but not an array), recursively search
				const result = utility.findKeyInDeepNestedObject(obj[key], keyToFind);
				if (result !== undefined) {
					return result; // Key found in nested object, return its value
				}
			}
		}
	}
	return undefined; // Key not found
};

utility.areStringsEqual = (str1, str2) => {
	const cleanStr1 = str1.replace(/[_\s]/g, '').toLowerCase();
	const cleanStr2 = str2.replace(/[_\s]/g, '').toLowerCase();

	return cleanStr1 === cleanStr2;
};


export default utility;
