export const styles = {

	parentContainer  : {
		position : 'relative'
	},

	imageContainer : (height, width) => {
		return {
			height   : height,
			width    : width,
			overflow : 'auto',
		};
	},

	imageBackground : (imgurl, zoomVal) => {
		return {
			height             : `${(100 + 10 * zoomVal)}%` ,
			width              : `${(100 + 10 * zoomVal)}%`,
			backgroundImage    : `url(${imgurl})`,
			backgroundSize     : 'contain',
			backgroundPosition : 'center',
			backgroundRepeat   : 'no-repeat',
		};
	},

	slider : {
		position : 'absolute',
		top      : '20%',
		right    : '1%',
		height   : '60%',
	},

	plus : {
		position  : 'absolute',
		top       : '10%',
		right     : '1.6%',
		fontSize  : '20px',
	},

	minus : {
		position  : 'absolute',
		top       : '80%',
		right     : '1.9%',
		fontSize  : '20px',
	}
};
