import React              from 'react';
import {withSnackbar}     from 'notistack';
import Grid               from '@material-ui/core/Grid';
import Hidden             from '@material-ui/core/Hidden';

import TopNav             from 'components/molecules/TopNav';
import MobileNav          from 'components/molecules/MobileNav';
import CompanyNotice      from './CompanyNotice';
import ApplicantNotice    from './ApplicantNotice';
import apis               from 'common/apis';
import {errorMsg}         from 'common/errors';
import utility            from 'common/utility';
import Log                from 'common/log';
import commonStore        from 'redux/commonStore';

const log = Log('Onboarding', 'info');

class Onboarding extends React.Component {

	constructor (props) {
		super (props);

		this.state = {
			mobileNav      : false,
			loading        : false,
			langCode       : 'en',
			companyData    : [],
			applicantData  : null,
			editableFields : {},
		};
		this.notify = this.props.enqueueSnackbar;
	}

	componentDidMount = () => {
		this.commonUnsub = commonStore.subscribe (this.getLanguage);
		this.getLanguage ();
		this.getDisplayData ();
		this.getEditData ();
		this.getOnboardingCompanyInfo();
	}

	componentWillUnmount = () => {
		this.commonUnsub ();
	}

	getLanguage = () => {
		let commonInfo = commonStore.getState ();
		let langCode = commonInfo.langCode;
		this.setState ({
			langCode
		});
	}

	getDisplayData = async() => {
		let result;
		try {
			result = await apis.getOnboardingDisplayData ();
			log.info ({result}, 'display data get ok');
		}
		catch (err) {
			log.error ({err}, 'error getting onboarding display data');
			if (err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.setState ({
				loading : false,
			});
			return;
		}
		this.setState ({
			applicantData : result,
			loading       : false,
		});
	}

	getEditData = async() => {
		let result;
		try {
			result = await apis.getOnboardingEditData ();
			log.info ({result}, 'onoarding edit data get ok');
		}
		catch (err) {
			log.error ({err}, 'error getting onboarding edit data');
			if (err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.setState ({
				loading : false,
			});
			return;
		}
		this.setState ({
			editableFields : result,
		});
	}

	getOnboardingCompanyInfo = async() => {
		let result;
		try {
			result = await apis.getOnboardingCompanyInfo ();
			log.info ({result}, 'onoarding company info data get ok');
		}
		catch (err) {
			log.error ({err}, 'error getting onboarding edit data');
			if (err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.setState ({
				loading : false,
			});
			return;
		}
		this.setState ({
			companyData : result.custom_fields,
			loading  :  false,
		});
	}

	handleMobileNav = () => {
		this.setState ({
			mobileNav : !this.state.mobileNav,
		});
	}

	render () {
		return (
			<Grid className='parent-content'>
				<TopNav name='' mobileNav={this.handleMobileNav} />
				<Hidden only={['md', 'lg', 'xl']}>
					{this.state.mobileNav ? <MobileNav /> : null}
				</Hidden>
				<Grid className={`actual-content ${this.state.mobileNav ? 'mobile-nav' : ''}`}>
					<Grid>
						<CompanyNotice langCode = {this.state.langCode} customFields = {this.state.companyData}/>
					</Grid>
					<Grid className = 'mt-24'>
						{this.state.applicantData ?
							<ApplicantNotice
								langCode             = {this.state.langCode}
								applicantData        = {this.state.applicantData.custom_fields}
								applicantId          = {this.state.applicantData.id}
								editableFields       = {this.state.editableFields}
								onSaveOk             = {this.getDisplayData}
								applicantDocument    = {this.state.applicantData.document_fields}
								applicantDownloadDoc = {this.state.applicantData.downloadable_documents}
							/> : null}
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

export default withSnackbar(Onboarding);
