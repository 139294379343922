const axios          = require('axios');
const {vimeo_config} = require ('./config');

const vimeo = {};

const defaultOptions = {
	timeout      : 60000,
	headers      : {'Content-Type' : 'application/json', 'Authorization' : `Bearer ${vimeo_config.access_token}`, 'Accept' : 'application/vnd.vimeo.*+json;version=3.4'},
	responseType : 'json',
	baseURL      : 'https://api.vimeo.com'
};

const _axios = axios.create (defaultOptions);

vimeo.get = function (url, options) {

	options = options || {};

	return new Promise (async (resolve, reject) => {

		try {
			let _config = {
				url          : url,
				method       : 'get',
				...options
			};

			let response = await _axios.request (_config);
			resolve (response.data);
		}
		catch (err) {
			reject (err);
		}
	});
};

vimeo.post = function (url, postData, options) {
	
	options = options || {};

	return new Promise (async (resolve, reject) => {

		try {
			let _config = {
				url         : url,
				method      : 'post',
				data        : postData,
				...options
			};

			let response = await _axios.request (_config);
			resolve (response.data);
		}
		catch (err) {
			reject (err);
		}
	});
};

vimeo.patch = function (url, postData, offset) {
	
	return new Promise (async (resolve, reject) => {
		try {
			let _config = {
				url     : url,
				timeout : 120000,
				method  : 'patch',
				headers : {'Tus-Resumable' : '1.0.0', 'Upload-Offset' : offset, 'Content-Type' : 'application/offset+octet-stream'},
				data    : postData,
			};
			let response = await _axios.request (_config);
			resolve ({data : response.data, headers : response.headers});
		}
		catch (err) {
			reject (err);
		}
	});
};

vimeo.head = function (url) {

	return new Promise (async (resolve, reject) => {
		try {
			let _config = {
				url     : url,
				method  : 'head',
				headers : {'Tus-Resumable' : '1.0.0', 'Accept' : 'application/vnd.vimeo.*+json;version=3.4'},
			};
			let response = await _axios.request (_config);
			resolve (response.headers);
		}
		catch (err) {
			reject (err);
		}
	});
};

export default vimeo;
