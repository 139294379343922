import {light} from 'styles/muiTheme/colors';

export const styles = {
	
	categortName : {
		backgroundColor : '#5367A0',
		color           : '#FFFFFF'
	},

	uploadButton : {
		height : '30px',
	},

	langOn : {
		'color'          : 'white',
		'border'         : `1px solid ${light.primary.main}`,
		'padding'        : '4px 6px',
		'display'        : 'flex',
		'justifyContent' : 'center',
		'alignItems'     : 'center',
		'borderRadius'   : '12px',
		'width'          : '32px',
		'background'     : light.primary.main,
		'cursor'         : 'pointer',
	},
	
	langOff : {
		'color'          : light.primary.main,
		'border'         : `1px solid ${light.primary.main}`,
		'display'        : 'flex',
		'justifyContent' : 'center',
		'alignItems'     : 'center',
		'padding'        : '4px 6px',
		'borderRadius'   : '12px',
		'width'          : '32px',
		'cursor'         : 'pointer',
	},
	langBtnGrid : {
		padding : '16px 2px',
	},
	deleteButton : {
		color : light.error.main,
		border : '1px solid',
		height : '28px',
		fontSize : 11,
	},
	required : {
		color : light.error.main
	},
	form : {
		height  : `calc(100% - 74px)`,
		overflow :'auto'
	}
};
