import React, { useState, useRef } from 'react';
import { withSnackbar }            from 'notistack';
import Grid                        from '@material-ui/core/Grid';
import TextField                   from '@material-ui/core/TextField';
import Button                      from '@material-ui/core/Button';
import CheckCircleIcon             from '@material-ui/icons/CheckCircle';

import Loader                      from 'components/atoms/Loader';
import vimeo                       from 'common/vapis';
import Log                         from 'common/log';
import utility                     from 'common/utility';
import { styles }                  from './style';

function VimeoVideoUpload ( props ) {

	const { videoName, enqueueSnackbar : notify, onUploadCompleted, fieldName } = props;
	const log = Log('Profile', 'info');

	const [ fileSize, setFileSize ]             = useState (null);
	const [ binaryData, setBinaryData ]         = useState (null);
	const [ videoUploading, setVideoUploading ] = useState (false);
	const [ isCompleted, setIsCompleted ]       = useState (false);

	const videoLink = useRef (null);

	const offset = 0;

	const handleFileChange = async (event) => {

		const reader = new FileReader();
		const fileSize = event.target.files[0].size;
		reader.onload = (r) => {
			setBinaryData (r.target.result);
			setFileSize(fileSize);
			setIsCompleted (false);
		};
		reader.readAsArrayBuffer(event.target.files[0]);
	};

	const uploadVideo = async() => {

		setVideoUploading (true);
		let result;
		let data = {
			upload : {
				approach : "tus",
				size     : fileSize,
			},
			name : videoName.toString() + "-" + fieldName,
		};

		try {
			result = await vimeo.post ('/me/videos', data);
			log.info ({result}, 'vimeo api success');
		}
		catch (err) {
			log.error ({err}, 'error making vimeo api call');
			setVideoUploading (false);
			notify ('Something went wrong while uploading video. Please try again', {variant : 'error'});
			return;
		}

		let videoUrl    = result.link;
		videoLink.current = videoUrl;
		let uploadUrl   = result.upload.upload_link;
		actualUpload (uploadUrl);
	};

	const actualUpload = (videoUrl) => {

		try {
			let uploaded = vimeo.patch (videoUrl, binaryData, offset);
			getUploadStatus(videoUrl);
			log.info ({uploaded}, 'uploaded successfully');
		}
		catch (err) {
			setVideoUploading (false);
			log.error ({err}, 'error uploading video');
			return;
		}
	};

	const getUploadStatus = async (videoUrl) => {

		let vidStatus;

		try {

			vidStatus = await vimeo.head (videoUrl);
			log.info ({vidStatus}, 'get head status');
		}

		catch (err) {
			log.error ({err}, 'error getting video status');
			setVideoUploading (false);
			return;
		}

		if (vidStatus['upload-length'] === vidStatus['upload-offset']) {
			notify ('Video uploaded successfully', {variant : 'success'});
			onUploadCompleted (fieldName, videoLink.current);
			setVideoUploading (false);
			setIsCompleted (true);
			return;
		}

		//let percentage = ((vidStatus['upload-offset']/vidStatus['upload-length'])*100).toFixed (2);
		utility.sleep (0.3);
		getUploadStatus (videoUrl);
	};

	return (
		<Grid container spacing = {2} alignItems='center' style = {{gap : 16, marginLeft: 0}}>
			<TextField
				type = 'file'
				name = 'video'
				onChange = {handleFileChange}
				inputProps = {{
					accept : "video/mp4,video/x-m4v,video/*"
				}}
			/>
			{!isCompleted ?
				<Button onClick = {uploadVideo} variant = 'outlined' className = 'ml-12' style = {styles.uploadButton}>
					{videoUploading ? <Loader color = 'primary' /> : 'Upload'}
				</Button> :
				<CheckCircleIcon style = {styles.completeIcon} />
			}
		</Grid>
	);
}

export default withSnackbar(VimeoVideoUpload);