/*This redux contains jclass related informations*/

import {createStore} from 'redux';

let initState = {
	myLessons      : [],
	selectedJclass : null,
};

export function myLessonsList (myLessons) {
	
	return {
		type       : 'LESSONS_INFO',
		myLessons  : myLessons,
	};
}

export function saveReflection (reflection, jclassId) {
	
	return {
		type        : 'NEW_REFLECTION',
		reflection  : reflection,
		jclassId    : jclassId,
	};
}

export function selectedJclass (jclassId) {
	return {
		type     : 'SELECTED_JCLASS',
		jclassId : jclassId,
	};
}

function jclassReducer (jclassStore = initState, action) {
	
	switch (action.type) {

		case 'LESSONS_INFO' :
			return {
				...jclassStore,
				myLessons : action.myLessons,
			};

		case 'NEW_REFLECTION' : {
			let jclassIndex = jclassStore.myLessons.findIndex (j => j.jclass_id === action.jclassId);
			if (jclassIndex < 0) {
				return {
					...jclassStore,
				};
			}
			let jclass = jclassStore.myLessons[jclassIndex];

			let lessonIndex = jclass.lessons.findIndex (lesson => lesson.lesson_id === action.reflection.reflection.lesson_id);
			if (lessonIndex < 0) {
				return {
					...jclassStore,
				};
			}
			let lesson = jclass.lessons[lessonIndex];
			lesson = {
				...lesson,
				...action.reflection.reflection,
			};
			jclass.lessons[lessonIndex] = lesson;
			jclassStore.myLessons[jclassIndex] = jclass;
			return {
				...jclassStore,
				myLessons : jclassStore.myLessons,
			};
		}

		case 'SELECTED_JCLASS' :
			return {
				...jclassStore,
				selectedJclass : action.jclassId,
			};

		default :
			return jclassStore;
	}
}

const jclassStore = createStore (jclassReducer, initState);

export default jclassStore;
