/*This redux contains interview related informations*/

import {createStore} from 'redux';

let initState = {
	interviews     : [],
};

export function interviewsList (interviews) {
	
	return {
		type        : 'INTERVIEWS_LIST',
		interviews  : interviews,
	};
}

export function updateRating (ratings, jobIndex) {
	
	return {
		type     : 'UPDATED_RATING',
		ratings  : ratings,
		jobIndex : jobIndex,
	};
}

export function updateReadyStatus (jobId, interviewId,readyStatus, jobIndex) {
	return {
		type : 'UPDATE_READY_STATUS',
		jobId : jobId,
		interviewId : interviewId,
		jobIndex : jobIndex,
		readyStatus: readyStatus,
	};
}

function interviewReducer (interviewStore = initState, action) {
	
	switch (action.type) {

		case 'INTERVIEWS_LIST' :
			return {
				...interviewStore,
				interviews : action.interviews,
			};

		case 'UPDATED_RATING' : {
			let interviewIndex = interviewStore.interviews[action.jobIndex].findIndex(i => i.job_id === action.ratings.job_id);
			if (interviewIndex < 0) {
				return;
			}
			let interview = interviewStore.interviews[action.jobIndex][interviewIndex];
			interview = {
				...interview,
				ratings : {
					...action.ratings.ratings,
				}
			};
			let interviews = interviewStore.interviews;
			interviews[action.jobIndex][interviewIndex] = interview;
			return {
				interviews : interviews,
			};
		}

		case 'UPDATE_READY_STATUS' : {
			let interviewIndex = interviewStore.interviews[action.jobIndex].findIndex(i => i.job_id === action.jobId);
			if (interviewIndex < 0) {
				return;
			}
			let interview = interviewStore.interviews[action.jobIndex][interviewIndex];
			if (!interview.interview_details) {
				return;
			}
			let interviewDetailsIndex = interview.interview_details.findIndex (i => i.id === action.interviewId);
			if (interviewDetailsIndex < 0){
				return;
			}
			let interviewDetail = interview.interview_details[interviewDetailsIndex];
			interviewDetail = {
				...interviewDetail,
				ready_status: action.readyStatus
			};

			interview.interview_details[interviewDetailsIndex] = interviewDetail;

			let interviews = interviewStore.interviews;
			interviews[action.jobIndex][interviewIndex] = interview;
			return {
				interviews : interviews,
			};
		}

		default :
			return interviewStore;
	}
}

const interviewStore = createStore (interviewReducer, initState);

export default interviewStore;
