import React                  from 'react';
import ReactHtmlParser        from 'react-html-parser';
import Paper                  from '@material-ui/core/Paper';
import Button                 from '@material-ui/core/Button';
import Grid                   from '@material-ui/core/Grid';
import Typography             from '@material-ui/core/Typography';
import Collapse               from '@material-ui/core/Collapse';

import translations           from 'common/translations';

function CompanyCard (props) {

	const {job, langCode} = props;
	const [view, setView] = React.useState (false);

	const onView = () => {
		setView (!view);
	};

	const jobDescription = job.job_details;

	const renderJobDescription = () => {

		const descriptionView = [];
		for  (let i = 0; i < jobDescription.length; i++) {
			let __categoryContent = jobDescription[i].category_content;
			let categoryContent = [];
			for (let j = 0; j < __categoryContent.length; j++) {
				if (Array.isArray (__categoryContent[j].question_ans) && !__categoryContent[j].question_ans.length) {
					continue;
				}
				if (!__categoryContent[j].question_ans) {
					continue;
				}
				categoryContent.push (
					<Grid key  = {j} className = 'mt-12'>
						<Typography variant = 'h6'> {__categoryContent[j].question_name}</Typography>
						<Typography variant = 'body2' style = {{opacity : 0.8, whiteSpace : 'pre-wrap'}}> {ReactHtmlParser(__categoryContent[j].question_ans)} </Typography>
					</Grid>
				);
			}
			if (!categoryContent.length) {
				continue;
			}
			
			descriptionView.push (
				<Grid key = {i} className = 'mt-16'>
					<Typography variant = 'subtitle1' align = 'center' style = {{backgroundColor : '#5367a0', color : '#fff'}}> {jobDescription[i].category_name} </Typography>
					{categoryContent}
				</Grid>
			);
		}
		return descriptionView;
	};

	return (
		<Paper variant = 'outlined' className = 'p-24 mt-24'>
			<Grid container spacing = {3}>
				<Grid item xs = {4} sm = {4} md = {3} lg = {2} xl = {2} container justify = 'center'>
					{job.job_logo ?
						<img src = {`${job.job_logo}`} alt = 'company_logo' width = {100} height = {100}/> :
						<div style = {{width : '100px', height : '100px', backgroundColor : '#eee', display : 'flex', justifyContent : 'center', alignItems : 'center'}}> {translations.profile.not_available[langCode]}</div> }
				</Grid>
				<Grid item xs = {8} sm = {8} md = {9} lg = {10} xl = {10} alignItems = 'center' container>
					<Grid item xs = {12} sm = {12} md = {6} lg = {6} xl = {6}>
						<Typography variant = 'h4'> {job.job_name} </Typography>
						<Button variant = 'outlined' color = 'secondary' onClick = {onView} size = 'small' className = 'mt-16'>
							{!view ? translations.home.view_description[langCode] : translations.home.hide_description[langCode]}
						</Button>
					</Grid>
					<Grid item xs = {12} sm = {12} md = {6} lg = {6} xl = {6} container justify = 'flex-end'>
						<Button variant = 'contained' color = 'primary' onClick = {() => props.startTest(job)}>
							{!job.submitted ? translations.home.submit_review[langCode] : translations.home.view[langCode]}
						</Button>
					</Grid>
				</Grid>
			</Grid>
			<Collapse in = {view} timeout = "auto" unmountOnExit>
				{renderJobDescription ()}
			</Collapse>
		</Paper>
	);
}

export default CompanyCard;
