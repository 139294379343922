/*This redux contains profile related informations*/

import {createStore} from 'redux';

let initState = {
	profile        : null,
	editableFields : null,
	profileFields  : null,
};

export function applicantProfile (profile) {
	
	return {
		type        : 'PROFILE_INFO',
		profile     : profile,
	};
}

export function applicantEditables (editable) {
	
	return {
		type           : 'EDITABLE_FIELDS',
		editableFields : editable,
	};
}

export function saveProfile (customFields) {
	
	return {
		type         : 'SAVE_PROFILE',
		customFields : customFields,
	};
}

export function applicantProfileFields (fields) {

	return {
		type : "PROFILE_FIELDS",
		fields,
	};
}

function profileReducer (profileStore = initState, action) {
	
	switch (action.type) {

		case 'PROFILE_INFO' :
			return {
				...profileStore,
				profile : action.profile,
			};

		case 'PROFILE_FIELDS' :
			return {
				...profileStore,
				profileFields : action.fields,
			};

		case 'EDITABLE_FIELDS' :
			return {
				...profileStore,
				editableFields : action.editableFields,
			};

		case 'SAVE_PROFILE' : {
			let __updatedEditables = {};
			for (let i = 0; i < action.customFields.length; i++) {
				__updatedEditables = {
					...__updatedEditables,
					...action.customFields[i].fields,
				};
			}

			return {
				...profileStore,
				profile : {
					...profileStore.profile,
					custom_fields : action.customFields,
				}
			};
		}

		default :
			return profileStore;
	}
}

const profileStore = createStore (profileReducer, initState);

export default profileStore;
