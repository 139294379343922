import React      from 'react';
import moment     from 'moment';
import Paper      from '@material-ui/core/Paper';
import Grid       from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider    from '@material-ui/core/Divider';
import Button     from '@material-ui/core/Button';
import translations from 'common/translations';
import "moment/locale/ja";

function EventCard (props) {
	
	const {event, langCode} = props;

	return (
		<Paper elevation = {4} className = 'p-12 mb-24' variant = 'outlined'>
			<Grid className = 'p-12'>
				<Typography variant = 'h4' align = 'center'> FAST OFFER International {event.name} </Typography>
			</Grid>
			<Divider />
			<Grid container spacing = {3} className = 'mt-4'>
				<Grid item xs = {7} sm = {8} md = {9} lg = {9} xl = {9}>
					<Typography variant = 'body1'> {translations.home.start_date[langCode]} {moment(moment (event.start_date)).locale(langCode === 'jp' ? 'ja' : langCode).format ('ll')}</Typography>
					<Typography variant = 'body1'> {translations.home.end_date[langCode]} {moment(moment (event.end_date)).locale(langCode === "jp" ? 'ja' : langCode).format ('ll')}</Typography>
					{/*
					<Typography variant = 'h6'> Qualification </Typography>
					{qualification.map ((items, index) => (
						<Grid key = {`q-${index}`} className = 'pl-24' container alignItems = 'baseline'>
							<Grid style = {styles.bulletsGrid}>
								<Bullets style = {styles.bullets}/>
							</Grid>
							<Grid style= {styles.qualificationGrid}>
								<Typography variant = 'body1'>
									{items}
								</Typography>
							</Grid>
						</Grid>
					))}
					*/}
				</Grid>
				<Grid item xs = {5} sm = {4} md = {3} lg = {3} xl = {3} container alignItems = 'flex-end' justify = 'flex-end'>
					<Button color = 'primary' variant = 'contained' size = 'large' onClick = {() => props.applyEvent (event.id)} disabled = {props.isApplied}>
						{props.isApplied ? translations.button.applied[langCode] : translations.button.apply_now[langCode]}
					</Button>
				</Grid>
			</Grid>
		</Paper>
	);
}

export default EventCard;
