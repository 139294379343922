import {createStore} from 'redux';

let initState = {
	langCode : 'en',
};

export function changeLanguage (langCode) {
	
	return {
		type      : 'LANGUAGE',
		langCode  : langCode,
	};
}

function commonReducer (commonStore = initState, action) {
	
	switch (action.type) {

		case 'LANGUAGE' :
			return {
				...commonStore,
				langCode : action.langCode,
			};
		default :
			return commonStore;
	}
}

const commonStore = createStore (commonReducer, initState);

export default commonStore;
