import React                                                        from 'react';
import ReactHtmlParser                                              from 'react-html-parser';
import { serialize }                                                from 'object-to-formdata';
import {withSnackbar}                                               from 'notistack';
import Grid                                                         from '@material-ui/core/Grid';
import swal                                                         from 'sweetalert';
import Button                                                       from '@material-ui/core/Button';
import Typography                                                   from '@material-ui/core/Typography';
import Paper                                                        from '@material-ui/core/Paper';
import Hidden                                                       from '@material-ui/core/Hidden';
import Divider                                                      from '@material-ui/core/Divider';
import TextField                                                    from '@material-ui/core/TextField';
import RadioGroup                                                   from '@material-ui/core/RadioGroup';
import Radio                                                        from '@material-ui/core/Radio';
import FormControlLabel                                             from '@material-ui/core/FormControlLabel';
import FormControl                                                  from '@material-ui/core/FormControl';
import FormGroup                                                    from '@material-ui/core/FormGroup';
import Checkbox                                                     from '@material-ui/core/Checkbox';

import AutoSelect                                                   from 'components/atoms/AutoSelect';
import Loader                                                       from 'components/atoms/Loader';
import TopNav                                                       from 'components/molecules/TopNav';
import MobileNav                                                    from 'components/molecules/MobileNav';
import VideoRecorder                                                from 'components/molecules/VideoRecorder';
import Log                                                          from 'common/log';
import apis                                                         from 'common/apis';
import utility                                                      from 'common/utility';
import path                                                         from 'common/path';
import {errorMsg}                                                   from 'common/errors';
import authStore                                                    from 'redux/authStore';
import Skeleton                                                     from './Skeleton';
import {styles}                                                     from './style';
import ViewQuestion                                                 from './ViewQuestion';
import Deadline                                                     from 'pages/Exams/Deadline';

const log  = Log ('OnlineSession', 'info');

class OnlineSession extends React.Component {

	constructor (props) {
		super (props);

		this.state = {
			selectedSession : null,
			sessions      : [],
			loading       : true,
			mobileNav     : false,
			redirectLogin : false,
			isFetched     : false,
			questionNo    : 0,
			questions     : [],
			showOuterText : false,
			documentField : null,
			applicantInfo : null,
			backLoading   : false,
		};
		this.notify = this.props.enqueueSnackbar;
	}

	componentDidMount = () => {
		this.applicantUnsub = this.getApplicantInfo;
		this.getOnlineSession ();
		this.getApplicantInfo ();
	}

	componentWillUnmount = () => {

	}

	getApplicantInfo = () => {
		let applicantInfo = authStore.getState ().applicant;
		this.setState ({
			applicantInfo,
		});
	}

	handleMobileNav = () => {
		
		this.setState ({
			mobileNav : !this.state.mobileNav,
		});
	}

	getOnlineSession = async (quesNo) => {

		let sessions;
		try {
			sessions = await apis.getOnlineSession ();
			log.info ({sessions}, 'sessions get ok');
		}
		catch (err) {
			log.error ({err}, 'error getting online sessions');
			if (err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify ('Something went wrong, Please try again.', {variant : 'error'});
			this.setState ({
				loading : false,
			});
			return;
		}
		if (!Array.isArray (sessions) || !sessions.length) {
			this.setState ({
				loading : false,
			});
			return;
		}

		let questions =  sessions[0].questions;

		this.setState ({
			loading : false,
			sessions,
			selectedSession : sessions[0], /*By default select the first session*/
			questions : questions,
			questionNo : quesNo ? quesNo : 0, /*0 for introduction page*/
			backLoading : false,
		});
	}

	getOptions = () => {
		let _options = [];
		this.state.sessions.forEach (ses => {
			_options.push ({
				name  : ses.title,
				value : ses.online_session_id,
			});
		});
		return _options;
	}

	handleSessionChange = (name, selected) => {
		let _sessions = this.state.sessions;
		let _selected = _sessions.find (s => s.online_session_id === selected.value);
		if (!_selected) {
			return;
		}
		this.setState ({
			selectedSession : _selected,
			questions : _selected.questions,
		});
	}

	getSelectValue = (option) => {
		return {
			label : option.title,
			value : option.online_session_id,
		};
	}

	onStartSession = () => {
		this.setState ({
			questionNo : 1,
		});
	}

	onSaveOnlineVideo = (recorder) => {
		if (!recorder) {
			return;
		}
		recorder.save ();
		let vEl = 	document.getElementById ('online_session_video');
		if (vEl) {
			vEl.id = '';
		}
	}

	handleQuestionChange = async () => {
		let question = this.state.questions[this.state.questionNo-1];
		let answer = question.answer;

		if (question.question_type === 'video') {
			this.setState ({
				questionNo    : this.state.questionNo === this.state.questions.length ? 0 : this.state.questionNo + 1,
				submitLoading : false,
				documentField : null,
				recordAgain : false,
			});
			return;
		}

		if (question.question_type === 'check_box') {
			let ans = [];
			for (let i = 0; i < question.answer.length; i++) {
				let _op = question.options.find (q => q.option_name === question.answer[i]);
				if (_op) {
					ans.push (_op.option_id);
				}
			}
			answer = ans.join (',');
		}

		if (question.question_type === 'radio_button') {
			let _op = question.options.find (q => q.option_name === question.answer[0]);
			if (_op) {
				answer = `${_op.option_id}`;
			}
		}

		if (question.question_type === 'drop_down') {
			let _op = question.options.find (q => q.option_name === question.answer[0]);
			if (_op) {
				answer = `${_op.option_id}`;
			}
		}
			
		let jsonData = {
			applicants_online_session : {
				online_session_id : this.state.selectedSession.online_session_id,
				question_id       : question.question_id,
				answer            : answer,
				document          : this.state.documentField || null,
			}
		};

		let data = serialize({
			...jsonData,
		});

		this.setState ({
			submitLoading : true,
		});

		let result;
		try {
			result = await apis.updateOnlineSession (data);
			log.info ({answer : result}, 'answer submitted ok');
		}
		catch (err) {
			log.error ({err}, 'err submitting answer');
			if (err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify ('Failed to submit answer, Please try again', {variant : 'error'});
			this.setState ({
				submitLoading : false,
				documentField : null
			});
			return;
		}

		this.setState ({
			questionNo : this.state.questionNo === this.state.questions.length ? 0 : this.state.questionNo + 1,
			submitLoading : false,
			documentField : null,
		});
	}

	handleChange = (event, que) => {

		let queIndex = this.state.questions.findIndex (q => q.question_id === que.question_id);
		if (queIndex < 0) {
			return;
		}

		que.answer = event.target.value;

		if (que.question_type === 'radio_button') {
			que.answer = [event.target.value];
		}

		if (que.question_type === 'document') {
			if (!event.target.files[0]) {
				return;
			}
			this.setState ({
				documentField : event.target.files[0],
			});
			return;
		}

		let _questions = this.state.questions;
		_questions[queIndex] = que;
		this.setState ({
			questions : _questions,
		});
	}

	handleCheckbox = (event, que) => {
		let {checked, value} = event.target;
		let queIndex = this.state.questions.findIndex (q => q.question_id === que.question_id);
		if (queIndex < 0) {
			return;
		}
		que.answer = checked ? [...que.answer, value] : que.answer.filter (item => item !== value);
		let _questions = this.state.questions;
		_questions[queIndex] = que;
		this.setState ({
			questions : _questions,
		});
	}

	handleDropDown = (name, selected, que) => {

		let queIndex = this.state.questions.findIndex (q => q.question_id === que.question_id);
		if (queIndex < 0) {
			return;
		}

		que.answer = [selected.value];

		let _questions = this.state.questions;
		_questions[queIndex] = que;

		this.setState ({
			questions : _questions,
		});
	}

	handleQuestionBack = () => {
		let prevQuesNo =  this.state.questionNo -1;
		let quest= this.state.questions[prevQuesNo -1];
		if (quest.question_type === 'video') {
			this.setState ({
				backLoading : true,
			});
			this.getOnlineSession (prevQuesNo);
		}
		this.setState ({
			questionNo : this.state.questionNo-1,
		});
	}

	onSubmitSession = async () => {

		swal ({
			title   : 'Submit',
			text    : 'Are you sure, you want to submit the session?',
			icon    : 'info',
			buttons : ['Cancel', 'OK']
		}).then (async (res) => {
			if (!res)  {
				return;
			}

			try {
				let jsonData = {
					applicants_online_session : {
						online_session_id : this.state.selectedSession.online_session_id,
						mark_as_done : true,
					}
				};
				let data = serialize ({
					...jsonData,
				});
				await this.handleQuestionChange ();
				await apis.updateOnlineSession (data);
				this.setState ({
					showOuterText : true,
				});
			}
			catch (err) {
				log.error ({err}, 'err submitting answer');
				if (err.response.status === 401) {
					this.notify (errorMsg.logoutMsg, {variant : 'error'});
					utility.redirectLogin ();
					return;
				}
				this.notify ('Failed to submit session, Please try again.', {variant : 'error'});
			}
		});
	
	}

	renderAnswers = (question) => {

		let questionType = question.question_type;

		switch (questionType) {
			case 'text' :
				return (
					<TextField
						value     = {question.answer || ''}
						variant   = 'outlined'
						placeholder = 'Write your answer'
						fullWidth
						onChange  = {(ev) => this.handleChange (ev, question)}
					/>
				);

			case 'text_area' :
				return (
					<TextField
						value     = {question.answer || ''}
						variant   = 'outlined'
						multiline = {true}
						placeholder = 'Write your answer'
						fullWidth
						rows      = {4}
						onChange  = {(ev) => this.handleChange (ev, question)}
					/>
				);

			case 'video' : {
				if (!this.state.applicantInfo) {
					return;
				}
				let payload = {
					request_type      : 'online_session',
					question_id       : question.question_id,
					online_session_id : this.state.selectedSession.online_session_id,
					applicant_id      : this.state.applicantInfo.id,
				};

				if (question.answer && !this.state.recordAgain) {
					return (
						<Grid>
							<Grid container justify='center'>
								<video width = "auto" height = "350" autoPlay controls style = {{maxWidth : '100%'}}>
									<source src = {question.answer} type="video/mp4" />
								</video>
							</Grid>
							<Grid container justify='center' className = 'mt-12'>
								<Button onClick = {() => this.setState ({recordAgain : true})} color = 'primary'> Record Again </Button>
							</Grid>
						</Grid>
					);
				}

				return (
					<Grid>
						<VideoRecorder
							elementId         = {`online-${question.question_id}`}
							width             = {720}
							height            = {480}
							payload           = {payload}
							requestType       = 'online_session'
							onSaveOnlineVideo = {this.onSaveOnlineVideo}
							timeLimit         = {`${question.timelimit}`}
						/>
						<Grid className = 'mt-16'>
							<Typography variant='body2' style = {{fontWeight : 'bold'}}> Instructions for students: </Typography>
							<ul style = {{listStyleType : 'inherit'}}>
								<li> Please press red ◉ icon on video to start the Video recording </li>
								<li> Once the video is recorded, you may play it back by pressing ▶ icon.</li>
								<li> In case you want to re-record the video, please press red icon and record it again.</li>
							</ul>
							<Typography variant = 'body2'>
									Note: Once the video is recorded and profile is SAVED, you cannot re-record. For more details contact AsiatoJapan support center
							</Typography>
						</Grid>
					</Grid>
				);
			}
			case 'number' :
				return (
					<TextField
						value     = {question.answer || ''}
						variant   = 'outlined'
						type        = 'number'
						placeholder = 'Write your answer'
						onChange  = {(ev) => this.handleChange (ev, question)}
					/>
				);

			case 'date' :
				return (
					<TextField
						type      = 'date'
						value     = {question.answer || ''}
						variant   = 'outlined'
						placeholder = 'Write your answer'
						fullWidth
						onChange  = {(ev) => this.handleChange (ev, question)}
					/>
				);

			case 'email' :
				return (
					<TextField
						type      = 'email'
						value     = {question.answer || ''}
						variant   = 'outlined'
						placeholder = 'Write your answer'
						fullWidth
						onChange  = {(ev) => this.handleChange (ev, question)}
					/>
				);

			case 'phone_number' :
				return (
					<TextField
						type      = 'text'
						value     = {question.answer || ''}
						variant   = 'outlined'
						placeholder = 'Write your answer'
						fullWidth
						onChange  = {(ev) => this.handleChange (ev, question)}
					/>
				);
			
			case 'url' :
				return (
					<TextField
						type      = 'text'
						value     = {question.answer || ''}
						variant   = 'outlined'
						placeholder = 'Write your answer'
						fullWidth
						onChange  = {(ev) => this.handleChange (ev, question)}
					/>
				);

			case 'location' :
				return (
					<TextField
						type      = 'text'
						value     = {question.answer || ''}
						variant   = 'outlined'
						placeholder = 'Write your answer'
						fullWidth
						onChange  = {(ev) => this.handleChange (ev, question)}
					/>
				);

			case 'currency' :
				return (
					<TextField
						type      = 'text'
						value     = {question.answer || ''}
						variant   = 'outlined'
						placeholder = 'Write your answer'
						fullWidth
						onChange  = {(ev) => this.handleChange (ev, question)}
					/>
				);

			case 'check_box' :
				return (
					<FormGroup row = {false}>
						{question.options.map ((item, index) => (
							<FormControlLabel
								label   = {item.option_name}
								key     = {index}
								control = {<Checkbox checked = {question.answer.includes(item.option_name)} onChange = {(ev) => this.handleCheckbox(ev, question)} value = {item.option_name}/>}/>
						))}
					</FormGroup>
				);

			case 'radio_button' :
				return (
					<FormControl component = "fieldset">
						<RadioGroup value = {question.answer[0] || ''} onChange = {(ev) => this.handleChange (ev, question)}>
							{question.options.map ((item, index) => (
								<FormControlLabel value = {item.option_name}  label = {item.option_name} control = {this.renderRadio ()} key = {index}/>
							))}
						</RadioGroup>
					</FormControl>
				);

			case 'drop_down' : {
				let answer = question.answer && question.answer[0];
				if (answer) {
					let option = question.options.find (o => o.option_name === answer);
					answer = option ? {label : option.option_name, value : option.option_name} : {};
				}

				return (
					<div style = {{width : '30%'}}>
						<AutoSelect
							options     = {utility.autoSelectOptions (question.options, 'option_name', 'option_name')}
							value       = {answer}
							searchable  = {false}
							onChange    = {(name, value) => this.handleDropDown(name, value, question)}
						/>
					</div>
				);
			}

			case 'document' :
				return (
					<TextField
						type = 'file'
						name = 'document'
						onChange = {(ev) => this.handleChange (ev, question)}
					/>
				);
			
			default :
				return;
		}
	}

	renderRadio = () => {
		return (
			<Radio color = 'primary' size = 'small'/>
		);
	}

	render () {

		let selectedSession = this.state.selectedSession;
		let question = this.state.questions[this.state.questionNo-1];

		if (selectedSession && this.state.showOuterText ) {
			return (
				<Grid className = 'parent-content'>
					<TopNav mobileNav = {this.handleMobileNav}/>
					<Hidden only = {['md', 'lg', 'xl']}>
						{this.state.mobileNav ? <MobileNav /> : null}
					</Hidden>
					<Grid className = {`actual-content ${this.state.mobileNav ? 'mobile-nav' : ''}`}>
						<Deadline
							btn    = 'Ok'
							msg    = {selectedSession.outro_text}
							to     = {path.landing}
						/>
					</Grid>
				</Grid>
			);
		}

		if (selectedSession && selectedSession.mark_as_done) {
			return (
				<Grid className = 'parent-content'>
					<TopNav mobileNav = {this.handleMobileNav}/>
					<Hidden only = {['md', 'lg', 'xl']}>
						{this.state.mobileNav ? <MobileNav /> : null}
					</Hidden>
					<Grid className = {`actual-content ${this.state.mobileNav ? 'mobile-nav' : ''}`}>
						<Grid>
							<AutoSelect
								options     = {utility.autoSelectOptions (this.state.sessions, 'title', 'online_session_id')}
								searchable  = {false}
								value       = {this.getSelectValue (selectedSession)}
								onChange    = {this.handleSessionChange}
								name        = 'onlineSession'
							/>
						</Grid>
						<ViewQuestion
							examData = {selectedSession}
						/>
					</Grid>
				</Grid>
			);
		}

		return (
			<Grid className = 'parent-content'>
				<TopNav mobileNav = {this.handleMobileNav}/>
				<Hidden only = {['md', 'lg', 'xl']}>
					{this.state.mobileNav ? <MobileNav /> : null}
				</Hidden>
				<Grid className = {`actual-content ${this.state.mobileNav ? 'mobile-nav' : ''}`}>
					{this.state.loading ? <Skeleton />
						: <Grid>
							{!selectedSession ?
								<Typography variant = 'h4' align = 'center' style = {{color : '#6b6b6b'}}> There are currently no active sessions. </Typography>
								:
								<Grid>
									{!this.state.questionNo ?


										<Grid>
											<AutoSelect
												options     = {utility.autoSelectOptions (this.state.sessions, 'title', 'online_session_id')}
												searchable  = {false}
												value       = {this.getSelectValue (selectedSession)}
												onChange    = {this.handleSessionChange}
												name        = 'onlineSession'
											/>
											<Paper variant = 'outlined' className = 'p-12 mt-24'>
												<Grid>
													<Typography variant = 'h5'> Introduction </Typography>
													<Divider className = 'mt-8 mb-8'/>
													<Typography variant = 'body1'> {ReactHtmlParser (selectedSession.intro_text)}</Typography>
												</Grid>
												{selectedSession.intro_video ?
													<Grid className = 'mt-12'>
														<Typography variant = 'h5'> Introduction Video </Typography>
														<Divider className = 'mt-8 mb-8'/>
														<Grid container justify = 'center'>
															<Grid item xs = {false} sm = {false} md = {2} lg = {3} xl = {4}>
															</Grid>
															<Grid item xs = {12} sm = {12} md = {8} lg = {6} xl = {4}>
																<video width = "100%" height = "300" autoPlay controls>
																	<source src = {selectedSession.intro_video} type="video/mp4" />
																</video>
															</Grid>
															<Grid item xs = {false} sm = {false} md = {2} lg = {3} xl = {4}>
															</Grid>
														</Grid>
													</Grid>
													: null}
												<Grid container justify = 'center' className = 'mt-24'>
													<Button onClick = {this.onStartSession} color = 'secondary' variant = 'contained' style = {styles.mainBtn}> Start </Button>
												</Grid>
											</Paper>
										</Grid>
										:
										<Paper variant = 'outlined' className = 'p-12'>
											<Grid container spacing = {2}>
												<Grid item sm = {5}>
													<Typography variant = 'h5'> Question {this.state.questionNo}. </Typography>
												</Grid>
												<Grid item sm = {7}>
													<Typography variant = 'h5' align = 'right'> {this.state.questionNo} of {this.state.questions.length} </Typography>
												</Grid>
											</Grid>
											<Divider className = 'mt-8 mb-8'/>
											<Grid className = 'mt-12'>
												<Typography variant = 'body1'> {ReactHtmlParser (question.question_name)} </Typography>
											</Grid>
											{question.display_image ?
												<>
													<Grid container justify = 'center'>
														<Grid item xs = {false} sm = {false} md = {2} lg = {3} xl = {4}>
														</Grid>
														<Grid item xs = {12} sm = {12} md = {8} lg = {6} xl = {4}>
															<img alt = 'question-img' src = {question.display_image} width = '100%' height = '300'/>
														</Grid>
														<Grid item xs = {false} sm = {false} md = {2} lg = {3} xl = {4}>
														</Grid>
													</Grid>
													<Divider className = 'mt-8 mb-8'/>
												</>
												:
												null}

											{question.display_video ?
												<Grid container justify = 'center'>
													<Grid item xs = {false} sm = {false} md = {2} lg = {3} xl = {4}>
													</Grid>
													<Grid item xs = {12} sm = {12} md = {8} lg = {6} xl = {4}>
														<video width = "100%" height = "300" autoPlay controls>
															<source src = {question.display_video} type="video/mp4" />
														</video>
													</Grid>
													<Grid item xs = {false} sm = {false} md = {2} lg = {3} xl = {4}>
													</Grid>
												</Grid>
												:
												null}
											<Divider className = 'mt-8 mb-8'/>
											<Grid className = 'mt-24'>
												{this.renderAnswers (question)}
											</Grid>
											<Divider className = 'mt-8 mb-8'/>
											<Grid container alignItems = 'center' className = 'mt-24'>
												<Grid item sm = {6} container>
													<Button onClick = {this.handleQuestionBack} color = 'secondary' variant = 'contained' style = {styles.mainBtn}>
														{this.state.backLoading ? <Loader /> : 'Back'}
													</Button>
												</Grid>
												<Grid item sm = {6} container justify = 'flex-end'>
													{this.state.questionNo === this.state.questions.length ?
														<Button
															onClick = {this.onSubmitSession}
															color = 'primary'
															variant = 'contained'
															style = {styles.mainBtn}
															id = {question.question_type === 'video' ? 'online_session_video' : ''}
														>
															{this.state.submitLoading ? <Loader /> : 'Submit'}
														</Button>
														:
														<Button
															onClick = {this.handleQuestionChange}
															color = 'secondary'
															variant = 'contained'
															style = {styles.mainBtn}
															id = {question.question_type === 'video' ? 'online_session_video' : ''}
														>
															{this.state.submitLoading ? <Loader /> : 'Next'}
														</Button>
													}
												</Grid>
											</Grid>
										</Paper>
									}
								</Grid>
							}
						</Grid>
					}
				</Grid>
			</Grid>
		);
	}
}

export default withSnackbar(OnlineSession);
