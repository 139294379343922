import React                        from 'react';
import {Link as RouterLink}         from 'react-router-dom';
import {withSnackbar}               from 'notistack';
import ReactHtmlParser              from 'react-html-parser';
import Grid                         from '@material-ui/core/Grid';
import Link                         from '@material-ui/core/Link';
import Paper                        from '@material-ui/core/Paper';
import Hidden                       from '@material-ui/core/Hidden';
import Typography                   from '@material-ui/core/Typography';
import Breadcrumbs                  from '@material-ui/core/Breadcrumbs';

import TopNav                       from 'components/molecules/TopNav';
import MobileNav                    from 'components/molecules/MobileNav';
import path                         from 'common/path';
import Log                          from 'common/log';
import apis                         from 'common/apis';
import utility                      from 'common/utility';
import {errorMsg}                   from 'common/errors';
import Skeleton                     from './Skeleton';

const log = Log ('Zoom', 'info');

class Zoom extends React.Component {

	constructor (props) {
		super (props);

		this.state = {
			loading     : false,
			mobileNav   : false,
			isFetched   : false,
			zoomContent : [],
		};
		this.notify = this.props.enqueueSnackbar;
	}

	componentDidMount = () => {
		
		this.getZoomContent ();
	}

	getZoomContent = async () => {
		
		this.setState ({
			loading : true,
		});
		let result;

		try {
			result = await apis.getZoomContent ();
			log.info ({zoom : result}, 'zoom get ok');
		}
		catch (err) {
			log.error ({err}, 'error getting zoom content');
			if (err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.setState ({
				loading : false,
			});
			return;
		}

		if (result.errors) {
			this.notify (result.errors, {variant : 'error'});
			return;
		}
		this.setState ({
			zoomContent : result.content,
			loading     : false,
			isFetched   : true,
		});
	}

	render () {

		return (
			<Grid className = 'parent-content'>
				<TopNav name = '' mobileNav = {this.handleMobileNav}/>
				<Hidden only = {['md', 'lg', 'xl']}>
					{this.state.mobileNav ? <MobileNav /> : null}
				</Hidden>
				<Grid className = {`actual-content ${this.state.mobileNav ? 'mobile-nav' : ''}`}>
					<Breadcrumbs>
						<Link color="inherit" component = {RouterLink} to = {path.interview}>
							Interview
						</Link>
						<Link color="inherit" component = {RouterLink} to = {path.zoom}>
							Zoom Guide
						</Link>
					</Breadcrumbs>
					<Paper variant = 'outlined' className = 'p-24 mt-12'>
						<Typography variant = 'h4'> Zoom Guide </Typography>
						{this.state.loading ?
							<Skeleton />
							:
							<div> {ReactHtmlParser (this.state.zoomContent)}</div>
						}
					</Paper>
				</Grid>
			</Grid>
		);
	}
}

export default withSnackbar(Zoom);
