import React    from 'react';
import Grid     from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

function SkeletonLoader () {
	
	return (
		<Grid>
			<Skeleton variant = 'rect' height = {300} width = {1200}/>
		</Grid>
	);
}

export default SkeletonLoader;
