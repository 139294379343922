import React                 from 'react';
import { Link as RouterLink} from 'react-router-dom';
import Typography            from '@material-ui/core/Typography';
import Paper                 from '@material-ui/core/Paper';
import Button                from '@material-ui/core/Button';
import Grid                  from '@material-ui/core/Grid';

import path                  from 'common/path';

function Header ({langCode}) {

	if (langCode === 'en')
	return (
		<React.Fragment>
			<Paper variant = 'outlined' className = 'p-24'>
				<Typography variant = 'h4' align = 'center'> DO NOTE THAT ALL THE INTERVIEW TIMINGS ARE IN LOCAL/BROWSER TIME </Typography>
			</Paper>
			<Paper variant = 'outlined' className = 'p-24 mt-12'>
				<Typography variant = 'subtitle2'>
					We also require you to rate and rank the companies that will be interviewing you.
				</Typography>
				<Typography variant = 'body2'>
					Rate (in numerals). If you have 4 interviews, please rate 1 for the company that you are most interested to join, 2 four the company that you are next interested in, 3 .., and 4
					for the company that you are least interested in. The numbers should not repeat itself (i.e., you should not have two companies of the same rate).
					<br/>
					Rank (A, B, C). Please rank A for the company you will join if given an offer. B for the company that you might consider if given an offer, and C for the company,
					that you would not join even if given an offer.
					<br/>
					Do let us know your reasons for your choices.
				</Typography>
				<Grid container justify = 'center'>
					<Button variant = 'contained' color = 'secondary' component = {RouterLink} to = {path.zoom}> Zoom Guide </Button>
				</Grid>
			</Paper>
			<Paper variant = 'outlined' className = 'p-24 mt-12'>
				<Typography variant = 'h4' align = 'center'> Your Interviews </Typography>
			</Paper>
		</React.Fragment>
	);

	return (
		<React.Fragment>
			<Paper variant = 'outlined' className = 'p-24'>
				<Typography variant = 'h4' align = 'center'> ＜注意＞日時はご自身のパソコンのタイムゾーンが自動的に反映されておりますので、お間違えのないようお願いします。 </Typography>
			</Paper>
			<Paper variant = 'outlined' className = 'p-24 mt-12'>
				<Typography variant = 'subtitle2'>
					面接が入っている企業の志望順位、度合いを付けて下さい
				</Typography>
				<Typography variant = 'body2'>
					<br/>
					１．数字での志望順位
					<br/>
					1が志望順位が一番高く、２、3と志望順位が低くなるように順位付け下さい。同じ順位を使用するのは1回のみです。複数企業に2を付けるなど異なる企業に同じ数字は使用できません
					<br/>
					<br/>
					２．ABCでの志望度合い
					<br/>
					ABCを使って志望度合いを付けてください。
					<br/>
					これは複数企業で同じアルファベットを付けても構いません。
					<br/>
					A：内定がもらえたら入社したい企業
					<br/>
					B：内定がもらえたら入社を前向きに検討する企業
					<br/>
					C：内定をもらえても入社はしない予定の企業

					<br/>
				</Typography>
				<Grid container justify = 'center'>
					<Button variant = 'contained' color = 'secondary' component = {RouterLink} to = {path.zoom}> Zoomガイド </Button>
				</Grid>
			</Paper>
			<Paper variant = 'outlined' className = 'p-24 mt-12'>
				<Typography variant = 'h4' align = 'center'> 実施予定の面接企業 </Typography>
			</Paper>
		</React.Fragment>
	)
}

export default Header;
