import path         from './path';
import translations       from 'common/translations';

/*To add a new navigations tab, just add here*/

export const navigations = [
	{
		name : {
			en : translations.nav.home.en,
			jp : translations.nav.home.jp,
		},
		link : path.landing,
	},
	{
		name : {
			en : translations.nav.profile.en,
			jp : translations.nav.profile.jp,
		},
		link : path.profile,
	},
	{
		name : {
			en : translations.nav.japanese_class.en,
			jp : translations.nav.japanese_class.jp,
		},
		link : path.jclass,
	},
	{
		name : {
			en : translations.nav.interview.en,
			jp : translations.nav.interview.jp,
		},
		link : path.interview
	},
	{
		name : {
			en : translations.nav.online_session.en,
			jp : translations.nav.online_session.jp,
		},
		link : path.onlineSession,
	},
	{
		name : {
			en : translations.nav.onboarding.en,
			jp : translations.nav.onboarding.jp,
		},
		link : path.onboarding,
	},
];

export const fastOfferNavigations = [
	{
		name : {
			en : translations.nav.home.en,
			jp : translations.nav.home.jp,
		},
		link : path.landing,
	},
	{
		name : {
			en : translations.nav.profile.en,
			jp : translations.nav.profile.jp,
		},
		link : path.profile,
	},
	{
		name : {
			en : translations.nav.interview.en,
			jp : translations.nav.interview.jp,
		},
		link : path.interview
	},
];

/* URL of the server where all the api calls are made*/

export const hostname = process.env.REACT_APP_BASE_URL;

export const fastOffer = window.location.hostname.includes('fast') && !window.location.hostname.includes('intl') ? true : false;

export const fastHost = "https://fastoffer.co.jp/jp";
/* Vimeo account credentials*/

export const vimeo_config = {
	access_token    : '6dc7e31c5cb4d71305e753e854a55f2b',
	clients_secrets : "m4w41PB0him2devIMHrKxEuxqUKlxqtzXpXSP2JgY9/jiLynl0dPk+sUc3sHVsqNf/q4hNr5+WNGXXXoM84BB/hfzDyV6Rk7RLpFqhTOfntEEbiu5L/CbTi95lQK4fve",
	client_id       : "74199fc3f487d9160373f0ff5de9305f9d5750d8",
};

export const int_result = {
	"〇"               : 'Pass',
	"△"                : 'Pending',
	"×"                : 'Not a match',
	"今日結果は出ない" : 'Pending',
};

export const language = [
	{
		name : "Japanese",
		langCode : 'jp'
	},
	{
		name : "English",
		langCode : 'en'
	}
];
