import React             from 'react';
import {Route, Switch}   from 'react-router-dom';

import Login             from 'pages/Login';
import Auth              from 'pages/Auth';
import Landing           from 'pages/Landing';
import Profile           from 'pages/Profile';
import JapaneseClass     from 'pages/JapaneseClasses';
import Apply             from 'pages/Apply';
import ResetPassword     from 'pages/ResetPassword';
import ForgotPassword    from 'pages/ForgotPassword';
import Interview         from 'pages/Interview';
import Zoom              from 'pages/Zoom';
import Exams             from 'pages/Exams';
import OnlineSession     from 'pages/OnlineSession';
import Onboarding        from 'pages/Onboarding';
import path              from 'common/path';
import PrivateRoute      from './PrivateRoutes';
import Error404          from 'components/molecules/404';

function Routes () {

	return (
		<Switch>
			<Route        exact = {true} path = {path.login}                    component = {Login}/>
			<Route        exact = {true} path = {path.auth}                     component = {Auth}/>
			<Route        exact = {true} path = {`${path.apply}/:form_number`}  component = {Apply}/>
			<Route        exact = {true} path = {path.resetPassword}            component = {ResetPassword}/>
			<Route        exact = {true} path = {path.forgotPassword}           component = {ForgotPassword}/>
			<PrivateRoute exact = {true} path = {path.landing}                  component = {Landing}/>
			<PrivateRoute exact = {true} path = {path.profile}                  component = {Profile}/>
			<PrivateRoute exact = {true} path = {path.jclass}                   component = {JapaneseClass}/>
			<PrivateRoute exact = {true} path = {path.interview}                component = {Interview}/>
			<PrivateRoute exact = {true} path = {path.zoom}                     component = {Zoom}/>
			<PrivateRoute exact = {true} path = {path.onlineSession}            component = {OnlineSession}/>
			<PrivateRoute exact = {true} path = {path.onboarding}               component = {Onboarding}/>
			<PrivateRoute exact = {true} path = {`${path.exam}/:examId`}        component = {Exams}/>
			<Route        exact = {true} path = '*'                             component = {Error404}/>
		</Switch>
	);
}

export default Routes;
