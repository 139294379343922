import React                                                        from 'react';
import swal                                                         from 'sweetalert';
import ReactHtmlParser                                              from 'react-html-parser';
import {withSnackbar}                                               from 'notistack';
import Grid                                                         from '@material-ui/core/Grid';
import Typography                                                   from '@material-ui/core/Typography';
import Hidden                                                       from '@material-ui/core/Hidden';

import TopNav                                                       from 'components/molecules/TopNav';
import MobileNav                                                    from 'components/molecules/MobileNav';
import TestModal                                                    from 'components/molecules/TestModal';
import Log                                                          from 'common/log';
import apis                                                         from 'common/apis';
import utility                                                      from 'common/utility';
import {errorMsg}                                                   from 'common/errors';
import eventStore, {eventsList, appliedEventsList, newAppliedEvent} from 'redux/eventStore';
import authStore                                                    from 'redux/authStore';
import commonStore                                                  from 'redux/commonStore';
import translations                                                 from 'common/translations';
import EventCard                                                    from './EventCard';
import Skeleton                                                     from './Skeleton';
import JobCard                                                      from './JobCard';

const log  = Log ('Landing', 'info');

class Landing extends React.Component {

	constructor (props) {
		super (props);

		this.state = {
			events        : [],
			jobReview     : [],
			appliedEvents : [],
			submitLoading : false,
			jobLoading    : false,
			loading       : false,
			mobileNav     : false,
			redirectLogin : false,
			isFetched     : false,
			isFastOffer   : false,
			testModal     : null,
			langCode      : 'en',
			homePageContent : '',
		};
		this.notify = this.props.enqueueSnackbar;
	}

	componentDidMount = () => {

		this.commonUnsub = commonStore.subscribe (this.getLanguage);
		this.eventsUnsub = eventStore.subscribe (this.getEvents);
		this.profileUnsub = authStore.subscribe (this.getProfile);
		this.getLanguage ();
		this.getEvents ();
		this.getJobReview ();
		this.getProfile ();
		this.getHomePageContent ();
	}

	componentWillUnmount = () => {

		this.eventsUnsub ();
		this.profileUnsub ();
		this.commonUnsub ();
	}

	getLanguage = () => {
		let commonInfo = commonStore.getState ();
		let langCode = commonInfo.langCode;
		this.setState ({
			langCode
		});
	}

	handleMobileNav = () => {
		
		this.setState ({
			mobileNav : !this.state.mobileNav,
		});
	}

	getProfile () {
		let profileInfo = authStore.getState ().applicant;
		if (!profileInfo) {
			return;
		}
		let isFastOffer = profileInfo? profileInfo['is_fastoffer'] : false;
		this.setState ({
			isFastOffer,
		});
	}

	getHomePageContent = async () => {
		let result;
		try {
			result = await apis.getHomePageContent ();
			log.info ({homeContent : result}, 'homepage content get ok');
		}
		catch (err) {
			log.error ({err}, 'error getting homepage content');
			if (err.response && err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.setState ({
				loading : false,
			});
			return;
		}
		this.setState ({
			homePageContent : result,
		});
	}

	getEvents = async () => {

		let eventsInfo    = eventStore.getState ();
		let events        = eventsInfo.events;
		let appliedEvents = eventsInfo.appliedEvents;
		if (!events.length && !this.state.isFetched) {
			this.setState ({
				loading : true,
			});
			try {
				events = await apis.getEvents ();
				appliedEvents = await apis.getAppliedEvents ();
				log.info ({events, appliedEvents}, 'getting events details');
			}
			catch (err) {
				log.error ({err}, 'error getting events info');
				if (err.response.status === 401) {
					this.notify (errorMsg.logoutMsg, {variant : 'error'});
					utility.redirectLogin ();
					return;
				}
				this.setState ({
					loading : false,
				});
				return;
			}
			if (!appliedEvents.errors) {
				eventStore.dispatch (appliedEventsList (appliedEvents.events));
				this.setState ({
					appliedEvents : appliedEvents.events,
				});
			}
			eventStore.dispatch (eventsList (events));

			this.setState ({
				events,
				isFetched : true,
				loading   : false,
			});
			return;
		}
		this.setState ({
			events,
			appliedEvents,
		});
	}

	getJobReview = async() => {
		this.setState ({
			jobLoading : true,
		});
		let _jobs = [];
		try {
			_jobs = await apis.getMyJobReviews ();
			log.info ({jobs : _jobs}, 'get jobs ok');
		}
		catch (err) {
			log.error ({err}, 'error getting events info');
			if (err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.setState ({
				loading : false,
			});
			return;
		}
		if(!Array.isArray (_jobs)) {
			this.setState ({
				jobLoading : false,
			});
			return;
		}
		this.setState ({
			jobReview : _jobs,
			jobLoading : false
		});
	}

	onApplyEvent = async (id) => {
		
		let data = {
			sgwj_event_id : id,
		};

		let result;

		try {
			result = await apis.sgwjApplyEvent (data);
		}
		catch (err) {
			log.error ({err}, 'error applying event');
			if (err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			return;
		}

		if (result.errors) {
			this.notify (result.errors, {variant : 'error'});
			return;
		}
		this.notify (result.message, {variant : 'success'});
		eventStore.dispatch (newAppliedEvent (data.sgwj_event_id));
	}

	onStartTest = (job) => {
		this.setState ({
			testModal : job,
		});
	}

	handleCheckbox = (event, que) => {
		let {checked, value} = event.target;
		let _job = this.state.testModal;
		let questions = _job.questions;
		let queIndex = questions.findIndex (q => q.question_id === que.question_id);
		if (queIndex < 0) {
			return;
		}
		que.answer = checked ? [...que.answer, value] : que.answer.filter (item => item !== value);
		questions[queIndex] = que;
		_job.questions = questions;
		
		this.setState ({
			testModal : _job,
		});
	}

	handleDropDown = (name, selected, que) => {

		let _job = this.state.testModal;
		let questions = _job.questions;
		let queIndex = questions.findIndex (q => q.question_id === que.question_id);
		if (queIndex < 0) {
			return;
		}

		que.answer = selected.value;

		questions[queIndex] = que;
		_job.questions = questions;
		this.setState ({
			testModal : _job,
		});
	}

	handleChange = (event, que) => {

		let _job = this.state.testModal;
		let questions = _job.questions;
		let queIndex = questions.findIndex (q => q.question_id === que.question_id);
		if (queIndex < 0) {
			return;
		}

		if (que.question_type === 'radio_button') {
			que.answer = [event.target.value];
		}

		if (que.question_type === 'free_text') {
			que.answer = event.target.value;
		}

		questions[queIndex] = que;
		_job.questions = questions;
		this.setState ({
			testModal : _job,
		});
	}

	submitAnswer = async () => {
		if (!this.state.testModal) {
			return;
		}
		let _job = this.state.testModal;
		let question = _job.questions;

		let dataArray = [];
		for (let i = 0; i < question.length; i++) {
			let answer = question[i].answer;

			if (question[i].question_type === 'check_box') {
				let ans = [];
				for (let j = 0; j < question[i].answer.length; j++) {
					let _op = question[i].options.find (q => q.option_name === question[i].answer[j]);
					if (_op) {
						ans.push (_op.option_id);
					}
				}
				answer = ans.join (',');
			}

			if (question[i].question_type === 'radio_button') {
				let _op = question[i].options.find (q => q.option_name === question[i].answer[0]);
				if (_op) {
					answer = `${_op.option_id}`;
				}
			}

			if (question[i].question_type === 'drop_down') {
				let _op = question[i].options.find (q => q.option_name === question[i].answer);
				if (_op) {
					answer = `${_op.option_id}`;
				}
			}

			if (question[i].is_mandatory) {
				if (Array.isArray(answer) && !answer.length) {
					this.notify(translations.message.fill_all_mandatory_fields[this.state.langCode], { variant: 'error' });
					return;
				}
				if (!answer)  {
					this.notify(translations.message.fill_all_mandatory_fields[this.state.langCode], { variant: 'error' });
					return;
				}
			}

			let jsonData = {
				applicants_job_review : {
					job_review_id     : _job.job_review_id,
					question_id       : question[i].question_id,
					answer            : answer,
				}
			};

			dataArray.push (jsonData);
		}

		this.setState ({
			submitLoading : true,
		});

		let result;
		try {
			for(let i = 0; i< dataArray.length; i++) {
				result = await apis.updateJobReview (dataArray[i]);
				log.info ({answer : result}, 'answer submitted ok');
			}
		}
		catch (err) {
			log.error ({err}, 'err submitting answer');
			if (err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify (translations.message.fail_to_submit_answer[this.state.langCode], {variant : 'error'});
			this.setState ({
				submitLoading : false,
			});
			return;
		}

		let finalSubmit;
		let finalData = {
			applicants_job_review : {
				job_review_id  : _job.job_review_id,
				mark_as_done  : true,

			}
		};

		try {
			finalSubmit = await apis.updateJobReview (finalData);
			if (finalSubmit.success)
				this.notify (finalSubmit.message, {variant : 'success'});
		}
		catch (err) {
			log.error ({err}, 'err submitting answer');
			if (err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify (translations.message.fail_to_submit_answer[this.state.langCode], {variant : 'error'});
			this.setState ({
				submitLoading : false,
			});
			return;
		}

		this.getJobReview ();
		this.setState ({
			submitLoading : false,
			testModal : null,
		});
	}

	onSubmit = () => {
		swal ({
			title   : translations.button.submit[this.state.langCode],
			text    : translations.message.are_you_sure_to_submit[this.state.langCode],
			icon    : 'info',
			buttons : [translations.button.cancel[this.state.langCode], translations.button.ok[this.state.langCode]]
		}).then (async (res) => {
			if (!res)  {
				return;
			}
			this.submitAnswer ();
		});
	}


	renderEventCards = () => {
	
		if (this.state.isFastOffer) {
			return;
		}
		let eventsCard = [];
		let events = this.state.events;

		for (let i = 0; i < events.length; i++) {
			let isApplied = this.state.appliedEvents.find (e => e.id === events[i].id);
			eventsCard.push (
				<EventCard
					key        = {events[i].id}
					event      = {events[i]}
					applyEvent = {this.onApplyEvent}
					isApplied  = {isApplied ? true : false}
					langCode   = {this.state.langCode}
				/>
			);
		}

		if (!eventsCard.length) {
			return (
				<Typography variant = 'h4' align = 'center' style = {{color : '#6b6b6b'}}> {translations.home.no_active_event[this.state.langCode]}  </Typography>
			);
		}
		return eventsCard;
	}

	renderJobCards = () => {
	
		let langCode =  this.state.langCode;
		let jobsCard = [];
		let jobReview = this.state.jobReview;

		for (let i = 0; i < jobReview.length; i++) {
			jobsCard.push (
				<JobCard
					key        = {jobReview[i].job_review_id}
					job        = {jobReview[i]}
					startTest  = {this.onStartTest}
					langCode   = {this.state.langCode}
				/>
			);
		}

		if (!jobsCard.length) {
			return (
				<Typography variant = 'h4' align = 'center' style = {{color : '#6b6b6b'}}> {translations.home.no_job_msg[langCode]} </Typography>
			);
		}
		return jobsCard;
	}

	render () {

		const {applicant} = this.state;

		return (
			<Grid className = 'parent-content'>
				<TopNav name = {applicant ? applicant.name : ''} mobileNav = {this.handleMobileNav}/>
				<Hidden only = {['md', 'lg', 'xl']}>
					{this.state.mobileNav ? <MobileNav /> : null}
				</Hidden>
				<Grid className = {`actual-content ${this.state.mobileNav ? 'mobile-nav' : ''}`}>
					{this.state.isFastOffer ? <Typography variant = 'h4' align = 'center' className = 'mb-48'> {ReactHtmlParser(this.state.homePageContent.content)} </Typography> : null}
					{this.state.loading ? <Skeleton /> : this.renderEventCards ()}
					{this.state.jobLoading ? <Skeleton /> : this.renderJobCards ()}
				</Grid>
				{this.state.testModal ?
					<TestModal
						questions      = {this.state.testModal.questions}
						jobId          = {this.state.testModal.job_review_id}
						handleChange   = {this.handleChange}
						handleCheckbox = {this.handleCheckbox}
						handleDropDown = {this.handleDropDown}
						submitAnswer   = {this.onSubmit}
						submitLoading  = {this.state.submitLoading}
						submitted      = {this.state.testModal.submitted}
						onCancel       = {() => this.setState ({testModal : null})}
					/>
					: null}
			</Grid>
		);
	}
}

export default withSnackbar(Landing);
