import React    from 'react';
import Grid     from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

import utility  from 'common/utility';

function SkeletonLoader () {
	
	const rowsNumber = utility.getRandomNumber (6, 10);
	const rows = [];

	for (let i = 0; i < rowsNumber; i++) {
		rows.push (
			<Grid container spacing = {3} alignItems = 'center' key = {i}>
				<Grid container item xs = {4} sm = {2} md = {2} lg = {2} xl = {2}>
					<Skeleton variant = 'text' width = {utility.getRandomNumber (40, 130)}/>
				</Grid>
				<Grid item xs = {8} sm = {10} md = {10} lg = {10} xl = {10} className = 'pl-8'>
					<Skeleton variant = 'text' width = {utility.getRandomNumber (70, 400)}/>
				</Grid>
			</Grid>
		);
	}

	return (
		<Grid>
			{rows}
		</Grid>
	);
}

export default SkeletonLoader;
