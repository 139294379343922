import React            from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

function Loader (props) {

	const {size, color} = props;

	return (
		<CircularProgress
			size  = {size || 20}
			color = {color}
			{...props}
		/>
	);
}

export default Loader;
