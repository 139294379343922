import React                       from 'react';
import { Redirect }                from 'react-router-dom';
import {withSnackbar}              from 'notistack';
import Grid                        from '@material-ui/core/Grid';
import swal                        from 'sweetalert';

import utility                     from 'common/utility';
import {errorMsg}                  from 'common/errors';
import Log                         from 'common/log';
import apis                        from 'common/apis';
import path                        from 'common/path';
import examStore                   from 'redux/examStore';
import QuestionPage                from './Question';
import Instruction                 from './Instruction';
import { styles }                  from './style';
import Deadline                    from './Deadline';
import Skeleton                    from './Skeleton';
import TopBar                      from './TopBar';
import ViewQuestion                from './ViewQuestion';

const log = Log('Profile', 'info');

class Exams extends React.Component {
	constructor (props) {
		super (props);

		this.state = {
			examId               : '',
			examData             : null,
			questions            : [],
			backLoading          : false,
			currentQuestionIndex : null,
			currentQuestionData  : null,
			examStarted          : false,
			viewExam             : false,
			redirectJClass       : false,
			isCompleted          : false,
			validity             : false,
			questionLoading      : false,
			showOuterText        : false,
			exitTest             : false,
		};
		this.notify = this.props.enqueueSnackbar;
	}

	componentDidMount () {
		let params = this.props.match.params;
		let examId = params?.examId;
		this.getExamData (examId);
	}

	handleChange = (event, questionGroupId, questionId) => {
		let currentQuestionData = this.state.currentQuestionData;
		if (!currentQuestionData || currentQuestionData.question_group_data.question_group_id !== questionGroupId) {
			return;
		}

		let questionDataIndex = currentQuestionData.question_group_data.questions_data.findIndex (q => q.question_id === questionId);
		if (questionDataIndex < 0)  {
			return;
		}

		let que = currentQuestionData.question_group_data.questions_data[questionDataIndex];

		if (!que) {
			return;
		}

		if (que.question_type === 'radio_button') {
			que.answer = [event.target.value];
		}

		if (que.question_type === 'free_text') {
			que.answer = event.target.value;
		}

		if (que.question_type === 'image') {
			if (!event.target.files[0]) {
				return;
			}
			que.answer_image = event.target.files[0];
		}

		let _questionsData = currentQuestionData.question_group_data.questions_data;
		_questionsData[questionDataIndex] = que;

		currentQuestionData.question_group_data.questions_data = _questionsData;

		this.setState ({
			currentQuestionData,
		});
	}

	handleCheckbox = (event, questionGroupId, questionId) => {
		let currentQuestionData = this.state.currentQuestionData;
		if (!currentQuestionData || currentQuestionData.question_group_data.question_group_id !== questionGroupId) {
			return;
		}

		let questionDataIndex = currentQuestionData.question_group_data.questions_data.findIndex (q => q.question_id === questionId);
		if (questionDataIndex < 0)  {
			return;
		}

		let que = currentQuestionData.question_group_data.questions_data[questionDataIndex];

		if (!que) {
			return;
		}

		let {checked, value} = event.target;

		que.answer = checked ? [...que.answer, value] : que.answer.filter (item => item !== value);

		let _questionsData = currentQuestionData.question_group_data.questions_data;
		_questionsData[questionDataIndex] = que;

		currentQuestionData.question_group_data.questions_data = _questionsData;

		this.setState ({
			currentQuestionData,
		});
	}

	getExamData = async (examId) => {
		this.setState ({
			loading : false,
		});

		let data = {
			applicants_exam : {
				exam_id : examId,
			}
		};
		let examData;
		try {
			examData = await apis.getExam (data);
			log.info ({examData}, 'exam data get ok');
		}
		catch (err) {
			log.error ({err}, 'error getting lessons info');
			if (err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify ('Something went wromg, Please try again', {variant : 'error'});
			this.setState ({
				loading : false,
				redirectJClass : true,
			});
			return;
		}
		if (examData.errors) {
			this.notify (examData.errors, {variant : 'error'});
			this.setState ({
				loading : false,
			});
			return;
		}
		this.setState ({
			questions : examData.questions,
			examData,
			loading : false,
			examId : Number(examId),
			viewExam : !examData.validity ? true : false,
			isCompleted : examData.mark_as_done ? true : false,
		});
	}

	onStartExam = async () => {
		let questions = this.state.questions;
		let question;

		/*Check for the first not submitted question*/
		if (!questions.length) {
			return;
		}
		let currentQuestionIndex = 0;
		question = questions[0];
		this.getQuestionData (question.question_group_id, currentQuestionIndex);
	}

	getQuestionData = async(questionId, currentQuestionIndex) => {
		/*Check the question first in the redux store*/
		let examInfo = examStore.getState ();
		let __questions = examInfo.questions;
		let question = __questions.find (q => q.question_group_data.question_group_id === questionId);

		this.setState ({
			currentQuestionIndex : currentQuestionIndex,
		});
		if (!question) {
			this.setState ({
				questionLoading : true,
			});
			let data = {
				applicants_exam : {
					exam_id     : this.state.examId,
					question_group_id : questionId,
				}
			};
			try {
				question = await apis.getQuestionData (data);
				log.info ({question}, 'question get ok');
			}
			catch (err) {
				log.error ({err}, 'error getting lessons info');
				if (err.response.status === 401) {
					this.notify (errorMsg.logoutMsg, {variant : 'error'});
					utility.redirectLogin ();
					return;
				}
				this.setState ({
					questionLoading : false,
				});
				return;
			}
			this.setState ({
				currentQuestionData : question,
				questionLoading : false,
			});
		}
		this.setState ({
			currentQuestionData : question,
			questionLoading : false,
		});
	}

	handleQuestionChange = async (where, remainingTime) => {
		/*where ->  next or back*/
		let currentQuestionData = this.state.currentQuestionData;
		if (!currentQuestionData) {
			return;
		}

		if (currentQuestionData.question_group_data.is_submitted) {
			let currentQuestionIndex = where === 'next' ? this.state.currentQuestionIndex+1 : this.state.currentQuestionIndex - 1;
			let question = this.state.questions[currentQuestionIndex];
			if (!question) {
				return;
			}
			this.getQuestionData (question.question_group_id, currentQuestionIndex);
			return;
		}

		if (!currentQuestionData.question_group_data.confirmation_screen || where === 'back') {
			this.setState ({
				submitLoading : where === 'next' ? true : false,
				backLoading : where === 'back' ? true : false,
			});
			let res = await this.submitAnswer (currentQuestionData, where, remainingTime);
			let currentQuestionIndex = where === 'next' ? this.state.currentQuestionIndex+1 : this.state.currentQuestionIndex - 1;
			let question = this.state.questions[currentQuestionIndex];
			if (!question) {
				this.setState ({
					submitLoading : false,
					backLoading : false,
				});
				return;
			}
			if (!res){
				this.getQuestionData (question.question_group_id, currentQuestionIndex);
				return;
			}
			this.getQuestionData (question.question_group_id, currentQuestionIndex);
			return;
		}
		
		if (where === 'next') {
			swal ({
				title   : 'Submit',
				text    : 'Are you sure, you want to submit the answer?',
				icon    : 'info',
				buttons : ['Cancel', 'OK']
			}).then (async (sub) => {
				if (!sub)  {
					this.setState ({
						submitLoading : false,
						backLoading : false,
					});
					return;
				}
				let res = await this.submitAnswer (currentQuestionData, where, remainingTime);
				let currentQuestionIndex = where === 'next' ? this.state.currentQuestionIndex+1 : this.state.currentQuestionIndex - 1;
				let question = this.state.questions[currentQuestionIndex];
				if (!question) {
					this.setState ({
						submitLoading : false,
						backLoading : false,
					});
					return;
				}
				if (!res){
					this.getQuestionData (question.question_group_id, currentQuestionIndex);
					return;
				}
				this.getQuestionData (question.question_group_id, currentQuestionIndex);
				return;
			});
		}
	}

	handleExitTest = () => {

		swal ({
			title   : 'Exit',
			text    : 'Are you sure, you want to exit the test?',
			icon    : 'warning',
			buttons : ['Cancel', 'OK']
		}).then (sub => {
			if (!sub) {
				return;
			}
			this.setState ({
				exitTest : !this.state.exitTest,
			});
		});
	}

	onExitTest = async (remainingTime) => {
		let currentQuestionData = this.state.currentQuestionData;
		if (!currentQuestionData) {
			return;
		}

		let res = await this.submitAnswer (currentQuestionData, 'exit', remainingTime);
		if (!res) {
			this.setState ({
				exitTest : false,
			});
			return;
		}

		this.setState ({
			redirectJClass : true,
		});
	}

	submitAnswer = async (currentQuestionData, where, remainingTime) => {
		let questionsData = currentQuestionData.question_group_data.questions_data;
		if (!Array.isArray(questionsData) || !questionsData.length ) {
			this.setState ({
				submitLoading : false,
				backLoading : false,
			});
			return;
		}

		for (let i = 0; i< questionsData.length; i++) {

			let question = questionsData[i];
			let answer = question.answer;
			if (question.question_type === 'check_box') {
				let ans = [];
				for (let i = 0; i < question.answer.length; i++) {
					let _op = question.options.find (q => q.option_name === question.answer[i]);
					if (_op) {
						ans.push (_op.option_id);
					}
				}
				answer = ans.join (',');
			}

			if (question.question_type === 'radio_button') {
				let _op = question.options.find (q => q.option_name === question.answer[0]);
				if (_op) {
					answer = `${_op.option_id}`;
				}
			}

			let data = {
				applicants_exam : {
					exam_id        : this.state.examId,
					question_id    : question.question_id,
					answer         : answer,
					remaining_time : remainingTime || null,
				}
			};

			let result;
			try {
				result = await apis.updateQuestionData (data);
				log.info ({result}, 'answer submitted ok');
			}
			catch (err) {
				log.error ({err}, 'error submiting answer');
				if (err.response.status === 401) {
					this.notify (errorMsg.logoutMsg, {variant : 'error'});
					utility.redirectLogin ();
					return;
				}
				this.setState ({
					submitLoading : false,
					backLoading : false,
				});
				return;
			}
			if (result.errors) {
			/*Show error notification only when moving formward*/
				if (where === 'next') {
					this.notify (result.errors, {variant : 'error'});
				}
				this.setState ({
					submitLoading : false,
					backLoading : false,
				});
				return true;
			}
		}
		this.setState ({
			submitLoading : false,
			backLoading : false,
		});
		return true;
	}

	onSubmitExam = async () => {
		
		/*First submit the final question*/
		await this.submitAnswer (this.state.currentQuestionData, 'next');

		let data = {
			applicants_exam : {
				exam_id : this.state.examId,
				mark_as_done : true,
			}
		};
		this.setState ({
			submitLoading : true,
		});
		let result;
		try {
			result = await apis.updateQuestionData (data);
			log.info ({result}, 'exams submitted ok');
		}
		catch (err) {
			log.error ({err}, 'error submiting exams');
			if (err.response.status === 401) {
				this.notify (errorMsg.logoutMsg, {variant : 'error'});
				utility.redirectLogin ();
				return;
			}
			this.notify ('Something went wrong', {variant : 'error'});
			this.setState ({
				submitLoading : false,
			});
			return;
		}
		if (result.message) {
			this.notify (result.message, {variant : 'success'});
		}
		this.setState ({
			submitLoading : false,
			showOuterText : true,
		});
	}

	render () {
		let examData = this.state.examData;
		let currentQuestionData = this.state.currentQuestionData;

		if (this.state.redirectJClass) {
			return (
				<Redirect to = {path.jclass}/>
			);
		}

		if (examData && examData.validity === false) {
			return (
				<Grid className = 'parent-content'>
					<TopBar endTime = {examData ? examData.end_date : ''} title = {examData ? examData.title : ''}/>
					<Grid container spacing = {3} style = {styles.mainContainer}>
						<Grid item xs = { false } sm = { false } md = { false } lg = { 2 } xl = { 2 } className = 'p-0'>
						</Grid>
						<Grid item xs = { 12 } sm = { 12 } md = { 12 } lg = { 8 } xl = { 8 }>
							<ViewQuestion
								examData = {examData}
							/>
						</Grid>
						<Grid item xs = { false } sm = { false } md = { false } lg = { 2 } xl = { 2 } className = 'p-0'>
						</Grid>
					</Grid>
				</Grid>
			);
		}

		if (this.state.isCompleted) {
			return (
				<Deadline
					msg = "Thank you, we have received your submission. Please wait, the result will be published soon."
					btn = 'Back'
					to  = {path.jclass}
				/>
			);
		}

		if (this.state.showOuterText) {
			return (
				<Deadline
					msg = {examData.outro_text}
					btn = 'Ok'
					to  = {path.jclass}
				/>
			);
		}

		return (
			<Grid className = 'parent-content'>
				<TopBar endTime = {examData ? examData.end_date : ''} title = {examData ? examData.title : ''} onExitTest = {this.handleExitTest}/>
				{!examData ?
					<Grid container spacing = {3} style = {styles.mainContainer}>
						<Grid item xs = { false } sm = { false } md = { false } lg = { 2 } xl = { 2 } className = 'p-0'>
						</Grid>
						<Grid item xs = { 12 } sm = { 12 } md = { 12 } lg = { 8 } xl = { 8 } container alignItems = 'center'>
							<Skeleton />
						</Grid>
						<Grid item xs = { false } sm = { false } md = { false } lg = { 2 } xl = { 2 } className = 'p-0'>
						</Grid>
					</Grid>
					:
					<Grid container spacing = {3} style = {styles.mainContainer}>
						<Grid item xs = { false } sm = { false } md = { false } lg = { 2 } xl = { 2 } className = 'p-0'>
						</Grid>
						<Grid item xs = { 12 } sm = { 12 } md = { 12 } lg = { 8 } xl = { 8 } container alignItems = 'center'>
							{!currentQuestionData ? <Instruction introText = {examData.intro_text} introVideo = {examData.intro_video} onStartExam = {this.onStartExam}/> : null}
							{currentQuestionData ?
								<QuestionPage
									questionNo           = {this.state.currentQuestionIndex + 1}
									totalQuestion        = {this.state.questions.length}
									questionName         = {currentQuestionData.question_group_data.question_group_name}
									questionType         = {currentQuestionData.question_group_data.question_type}
									displayImage         = {currentQuestionData.question_group_data.display_image}
									displayVideo         = {currentQuestionData.question_group_data.display_video}
									questionGroupId      = {currentQuestionData.question_group_data.question_group_id}
									isSubmitted          = {currentQuestionData.question_group_data.is_valid}
									remainingTime        = {currentQuestionData.question_group_data.remaining_time}
									timelimit            = {currentQuestionData.question_group_data.timelimit}
									questionsData        = {currentQuestionData.question_group_data.questions_data || []}
									handleQuestionChange = {this.handleQuestionChange}
									onSubmitExam         = {this.onSubmitExam}
									submitLoading        = {this.state.submitLoading}
									handleQuestionBack   = {this.handleQuestionChange}
									handleChange         = {this.handleChange}
									handleCheckbox       = {this.handleCheckbox}
									backLoading          = {this.state.backLoading}
									questionLoading      = {this.state.questionLoading}
									exitTest             = {this.state.exitTest}
									onExitTest           = {this.onExitTest}
								/>
								: null}
						</Grid>
						<Grid item xs = { false } sm = { false } md = { false } lg = { 2 } xl = { 2 } className = 'p-0'>
						</Grid>
					</Grid>
				}
			</Grid>
		);
	}
}

export default withSnackbar(Exams);
