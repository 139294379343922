import xhr from './xhr';

const apis = {};
const api_prefix = '/api/v1';

/** <-----------Auth APIS---------------- */

apis.enableAuthy = async(data) => {
	let result = await xhr.post (`${api_prefix}/auth/enable_authy.json`, data);
	return result;
};

apis.login = async (data) => {
	let result  = await xhr.post (`${api_prefix}/auth/login.json`, data);
	return result;
};

apis.verifyToken = async (data) => {
	let result = await xhr.post (`${api_prefix}/auth/verify_token.json`, data);
	return result;
};

apis.sendToken = async (data) => {
	let result  =  await xhr.post (`${api_prefix}/auth/send_token.json`, data);
	return result;
};

/* <---------Login Apis---------->*/

apis.logout = async () => {
	let result = await xhr.post (`${api_prefix}/applicants/logout.json`);
	return result;
};

apis.resetPassword = async (data) => {
	let result = await xhr.post (`${api_prefix}/applicants/reset_password_logged_in.json`, data);
	return result;
};

/* <---------Events Apis---------->*/

apis.getEvents = async () => {
	let result = await xhr.get (`${api_prefix}/sgwj_events.json`);
	return result;
};

apis.getAppliedEvents = async () => {
	let result = await xhr.get (`${api_prefix}/applicants/applied_events.json`);
	return result;
};

apis.sgwjApplyEvent = async (data) => {
	let result = await xhr.post (`${api_prefix}/sgwj_applications.json`, data);
	return result;
};

apis.getHomePageContent =  async () => {
	let result  = await xhr.get (`${api_prefix}/static_pages/get_homepage_content.json`);
	return result;
};

/* <---------Profile Apis---------->*/

apis.getProfile = async () => {
	let result = await xhr.get (`${api_prefix}/applicants/my_profile.json`);
	return result;
};

apis.editProfile = async () => {
	let result = await xhr.get (`${api_prefix}/applicants/edit_profile.json`);
	return result;
};

apis.saveProfile = async (data, applicantId) => {
	let result = await xhr.putform (`${api_prefix}/applicants/${applicantId}.json`, data);
	return result;
};

apis.getDownloadList = async () => {
	let result =  await xhr.get (`${api_prefix}/applicants/get_downloadable_documents_list.json`);
	return result;
};

apis.getDownloadLink = async (data) => {
	let result =  await xhr.post (`${api_prefix}/applicants/get_download_url_for_document.json`, data);
	return result;
};

apis.getProfileFields = async () => {
	let result =  await xhr.get (`${api_prefix}/applicants/get_my_profile_fields.json`);
	return result;
};

/* <---------JClass Apis---------->*/

apis.myLessons = async (data) => {
	let result = await xhr.get (`${api_prefix}/applicants/my_lessons.json`, data);
	return result;
};

apis.saveReflection = async (data) => {
	let result = await xhr.post (`${api_prefix}/comment_applicants/reflection.json`, data);
	return result;
};

/* <---------Apply Apis---------->*/

apis.verifyEmail = async (data) => {
	let result = await xhr.post (`${api_prefix}/applicants/check_applicant_record.json`, data);
	return result;
};

apis.getTemplates = async () => {
	let result = await xhr.get (`${api_prefix}/registration_templates.json`);
	return result;
};

apis.getForm = async (data) => {
	let result = await xhr.post (`${api_prefix}/registration_templates/get_form_data.json`, data);
	return result;
};

apis.registerApplicant = async (data) => {
	let result = await xhr.postform (`${api_prefix}/applicants.json`, data);
	return result;
};

apis.getCountryList = async () => {
	let result = await xhr.get (`${api_prefix}/countries.json`);
	return result;
};

apis.getUniversityList = async () => {
	let result = await xhr.get (`${api_prefix}/universities.json`);
	return result;
};

apis.initialResetPassword = async (data) => {
	let result =  await xhr.post (`${api_prefix}/reset_password.json`, data);
	return result;
};

apis.getTos = async () => {
	let result = await xhr.get (`${api_prefix}/static_pages/get_tos.json`);
	return result;
};

apis.getFastTos = async () => {
	let result = await xhr.get (`${api_prefix}/static_pages/get_tos_fastoffer.json`);
	return result;
};

/* <---------Interview Apis---------->*/

apis.getInterviews = async () => {
	let result = await xhr.get (`${api_prefix}/applicants/my_interviews.json`);
	return result;
};

apis.imready = async (data) => {
	let result = await xhr.post (`${api_prefix}/interviews/ready.json`, data);
	return result;
};

apis.confirmDateAndTime = async (data) => {
	let result = await xhr.post (`${api_prefix}/interviews/confirm_interview_time.json`, data);
	return result;
};

apis.submitRating = async (data) => {
	let result = await xhr.post(`${api_prefix}/ratings/submit_ratings.json`, data);
	return result;
};

apis.submitSelection = async (data) => {
	let result = await xhr.put (`${api_prefix}/selections/update.json`, data);
	return result;
};

/* <---------Zoom Apis---------->*/

apis.getZoomContent = async () => {
	let result = await xhr.get (`${api_prefix}/static_pages/get_zoom_guide.json`);
	return result;
};

/* <---------Forgot Password Apis---------->*/

apis.forgotPassword = async (data) => {
	let result = await xhr.post (`${api_prefix}/request_password_reset_email.json`, data);
	return result;
};

/* <---------Forgot Password Apis---------->*/

apis.getOnlineSession = async () => {
	let result = await xhr.get (`${api_prefix}/applicants_online_sessions/my_online_sessions.json`);
	return result;
};

apis.updateOnlineSession = async (data) => {
	let result = await xhr.putform (`${api_prefix}/applicants_online_sessions/update.json`, data);
	return result;
};

/* <---------Job review Apis---------->*/

apis.getMyJobReviews = async () => {
	let result = await xhr.get (`${api_prefix}/applicants_job_reviews/my_job_reviews.json`);
	return result;
};

apis.updateJobReview = async (data) => {
	let result = await xhr.put (`${api_prefix}/applicants_job_reviews/update.json`, data);
	return result;
};

/* <---------Question Data---------->*/

apis.getExam = async (data) => {
	let result = await xhr.post (`${api_prefix}/applicants_exams/get_exam_data.json`, data);
	return result;
};

apis.getQuestionData = async (data) => {
	let result = await xhr.post(`${api_prefix}/applicants_exams/get_question_data.json`, data);
	return result;
};

apis.updateQuestionData = async (data) => {
	let result = await xhr.put (`${api_prefix}/applicants_exams/update.json`, data);
	return result;
};

/* <----------Onboarding ------------>*/

apis.getOnboardingDisplayData = async () => {
	let result = await xhr.get (`${api_prefix}/applicants/onboarding_display_data.json`);
	return result;
};

apis.getOnboardingEditData = async () => {
	let result = await xhr.get (`${api_prefix}/applicants/onboarding_edit_data.json`);
	return result;
};

apis.getOnboardingCompanyInfo = async () => {
	let result = await xhr.get (`${api_prefix}/applicants/onboarding_company_info.json`);
	return result;
};

export default apis;
