/*This redux contains exams related informations*/

import {createStore} from 'redux';

let initState = {
	exam : null,
	questions : [],
};

export function newExam (exam) {
	
	return {
		type   : 'NEW_EXAM',
		exam   : exam,
	};
}

export function updateQuestions (question) {
	
	return {
		type    : 'UPDATE_QUESTION',
		question : question,
	};
}

export function clearExamStore () {
	
	return {
		type : 'CLEAR'
	};
}

function examReducer (examStore = initState, action) {
	
	switch (action.type) {

		case 'NEW_EXAM' :
			return {
				...examStore,
				exam : action.exam,
			};

		case 'UPDATE_QUESTION' : {
			let queIndex = examStore.questions.findIndex (q => q.question_data.question_id === action.question.question_data.question_id);
			if (queIndex < 0) {
				return{
					...examStore,
					questions : [...examStore.questions, action.question],
				};
			}
			let _questions = examStore.questions;
			_questions[queIndex] = action.question;
			return {
				...examStore,
				questions : _questions,
			};
		}

		case 'CLEAR':
			return initState;

		default :
			return examStore;
	}
}

const examStore = createStore (examReducer, initState);

export default examStore;
