import React                               from 'react';
import {NavLink}                           from 'react-router-dom';
import Grid                                from '@material-ui/core/Grid';
import Link                                from '@material-ui/core/Link';
import Paper                               from '@material-ui/core/Paper';

import commonStore                         from 'redux/commonStore';
import authStore                           from 'redux/authStore';
import path                                from 'common/path';
import {navigations, fastOfferNavigations} from 'common/config';
import {styles}                            from './style';

function MobileNav () {

	const [routes, setRoutes]       = React.useState ([]);
	const [langCode, setLangCode]   = React.useState ('en');


	const getLanguage = () => {
		let commonInfo = commonStore.getState ();
		let langCode = commonInfo.langCode;
		setLangCode (langCode);
	};

	React.useEffect (() => {
		getLanguage ();
		return commonUnsub;
	}, []);

	React.useEffect (() => {
		getProfile();
	}, [langCode]);

	const getProfile = () => {
		let profileInfo = authStore.getState ().applicant;
		let isFastOffer = profileInfo && profileInfo['is_fastoffer'];
		let isOnboarded = profileInfo && profileInfo['is_onboarded'];
		let __navigations = [...navigations];
		let onboardingPath = __navigations.find (nav => nav.link === path.onboarding);

		if (isFastOffer) {
			if (isOnboarded) {
				setRoutes([...fastOfferNavigations, onboardingPath]);
				return;
			}
			setRoutes (fastOfferNavigations);
			return;
		}
		if (isOnboarded) {
			setRoutes (__navigations);
			return;
		}
		setRoutes (__navigations.filter(nav => nav.link !== path.onboarding));
	};

	const commonUnsub  = commonStore.subscribe (getLanguage);


	return (
		<Grid>
			<Paper variant = 'outlined' style = {styles.paper}>
				{routes.map ((nav, index) => (
					<Grid className = 'pt-8 pb-8' style = {styles.grid} key = {index}>
						<Link component = {NavLink} exact = {true} to = {nav.link} style = {styles.navLink}> {nav.name[langCode]} </Link>
					</Grid>
				))}
			</Paper>
		</Grid>
	);
}

export default MobileNav;
