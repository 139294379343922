import React                 from 'react';
import {Redirect}            from 'react-router-dom';
import {withSnackbar}        from 'notistack';
import swal                  from 'sweetalert';
import IconButton            from '@material-ui/core/IconButton';
import Grid                  from '@material-ui/core/Grid';
import Typography            from '@material-ui/core/Typography';
import InputAdornment        from '@material-ui/core/InputAdornment';
import TextField             from '@material-ui/core/TextField';
import FormHelperText        from '@material-ui/core/FormHelperText';
import Button                from '@material-ui/core/Button';
import Visibility            from '@material-ui/icons/Visibility';
import VisibilityOff         from '@material-ui/icons/VisibilityOff';

import Loader                from 'components/atoms/Loader';
import Log                   from 'common/log';
import apis                  from 'common/apis';
import {fastOffer}           from 'common/config';
import translations          from 'common/translations';
import Logo                  from 'assets/Logo.png';
import FastLogo              from 'assets/Fast.png';
import path                  from 'common/path';
import utility               from 'common/utility';
import {styles}              from './style';

const log = Log ('ResetPassword', 'info');

class ResetPassword extends React.Component {
	constructor (props) {
		super (props);

		this.state = {
			isDisable   : false,
			newPass     : {
				value : '',
				show  : false,
				error : false,
			},
			confirmPass : {
				value : '',
				show  : false,
				error : false,
			},
			resetToken : '',
			redirectLogin : false,
			registration : false,
			langCode : 'en',
		};
		this.notify = this.props.enqueueSnackbar;
	}

	componentDidMount = () => {
		
		let urlString    = window.location.href;
		let url          = new URL(urlString);
		let token        = url.searchParams.get ('reset_password_token');
		let registration = url.searchParams.get ('registration');

		if (!token) {
			this.setState ({
				redirectLogin : true,
			});
			return;
		}

		this.setState ({
			resetToken : token,
			registration : registration === "true" ? true : false,
			langCode : fastOffer ? 'jp' : 'en'
		});
	}

	handleTextChange = (event) => {
	
		const {name, value} = event.currentTarget;

		this.setState ({
			[name] : {
				value : value,
				error : false,
			}
		});
	}

	onPressEnter =  (event) => {

		if (event.keyCode !== 13) {
			return;
		}

		this.onResetPassword ();
	}

	onCloseModal = () => {
		
		this.props.closeModal();
	}

	hidePassword = (event) => {
		
		const {name} = event.currentTarget;
		this.setState ({
			[name] : {
				...this.state[name],
				show : false,
			}
		});
	}

	showPassword = (event) => {
		
		const {name} = event.currentTarget;
		this.setState ({
			[name] : {
				...this.state[name],
				show : true,
			}
		});
	}

	onResetPassword = async () => {

		let newPassword = this.state.newPass.value;
		let confirmPass = this.state.confirmPass.value;

		if (!utility.validateTextField (newPassword)) {
			this.setState ({
				newPass : {
					...this.state.newPass,
					error : true,
				}
			});
		}

		if (!utility.validateTextField (confirmPass)) {
			
			this.setState ({
				confirmPass : {
					...this.state.confirmPass,
					error : true,
				}
			});
			return;
		}

		if (newPassword !== confirmPass) {
			
			this.notify ('Both password needs to be same', {variant : 'error'});
			this.setState ({
				confirmPass : {
					...this.state.confirmPass,
					value : '',
				},
				newPass : {
					...this.state.newPass,
					value : '',
				}
			});
			return;
		}

		let data = {
			applicant : {
				reset_password_token : this.state.resetToken,
				new_password : this.state.newPass.value,
				registration : this.state.registration || false,
			}
		};

		this.setState ({
			isDisable : true,
		});

		let result;
		try {
			result = await apis.initialResetPassword (data);
			log.info ({result}, 'reset password ok');
		}

		catch (err) {
			log.error ({err}, 'error updating password');
			this.setState ({
				isDisable : false,
			});
			return;
		}

		if (result.errors) {
			this.notify (result.errors, {variant : 'error'});
			this.setState ({
				isDisable : false,
			});
			return;
		}

		this.setState ({
			isDisable : false,
		});

		swal ({
			title  : result.message,
			text   : 'Please login to continue...',
			icon   : 'success',
			button : 'Ok'
		}).then (() => {
			this.setState ({
				redirectLogin : true,
			});
		});
	}

	renderShowButton = (name) => {
		
		return (
			<InputAdornment position = "end">
				<IconButton
					size        = 'small'
					onMouseUp   = {this.hidePassword}
					onMouseDown = {this.showPassword}
					name        = {name}
				>
					{this.state[name].show ? <Visibility /> : <VisibilityOff />}
				</IconButton>
			</InputAdornment>
		);
	}

	render () {
		
		let langCode =  this.state.langCode;

		if (this.state.redirectLogin) {
			return (
				<Redirect to = {path.login}/>
			);
		}

		return (
			<Grid container spacing = {3} style = {styles.mainContainer} alignItems = 'center' justify = 'center'>
				<Grid item xs = {12} sm = {8} md = {6} lg = {4} xl = {4}>
					<Grid style = {styles.loginContainer}>
						<Grid container justify = 'center'>
							<img alt= 'Logo' src = {fastOffer ? FastLogo : Logo} height = {65} width = 'auto' className = 'mb-24'/>
						</Grid>
						<Typography variant = 'h4' align = 'center' className = 'mb-16'>
							{this.state.registration ? translations.reset.set_account_pass[langCode] : translations.reset.reset_account_pass[langCode]}
						</Typography>
						<Grid style = {styles.inputContainer}>
							<TextField
								autoFocus   = {true}
								margin      = "dense"
								variant     = 'outlined'
								type        = {!this.state.newPass.show ? 'password' : 'text'}
								placeholder = {translations.reset.new_password[langCode]}
								name        = 'newPass'
								onKeyUp     = {this.onPressEnter}
								onChange    = {this.handleTextChange}
								value       = {this.state.newPass.value}
								error       = {this.state.newPass.error}
								fullWidth   = {true}
								InputProps  = {{
									endAdornment : this.renderShowButton('newPass')
								}}
							/>
							{this.state.newPass.error ? <FormHelperText error = {true}> {translations.reset.new_pass_empty[langCode]} </FormHelperText> : null}
						</Grid>
						<Grid style = {styles.inputContainer}>
							<TextField
								margin      = "dense"
								variant     = 'outlined'
								type        = {!this.state.confirmPass.show ? 'password' : 'text'}
								placeholder = {translations.reset.confirm_pass[langCode]}
								name        = 'confirmPass'
								onKeyUp     = {this.onPressEnter}
								onChange    = {this.handleTextChange}
								value       = {this.state.confirmPass.value}
								error       = {this.state.confirmPass.error}
								fullWidth   = {true}
								InputProps  = {{
									endAdornment : this.renderShowButton('confirmPass')
								}}
							/>
							{this.state.confirmPass.error ? <FormHelperText error = {true}> {translations.reset.confirm_pass_empty[langCode]} </FormHelperText> : null}
						</Grid>
						<Grid container justify = 'center'>
							<Button color = "secondary"   onClick = {this.onResetPassword} disabled = {this.state.isDisable} variant = 'contained'>
								{this.state.isDisable ? <Loader size = {20} loading = {true} /> : this.state.registration ? translations.reset.set_pass[langCode] : translations.reset.reset_pass[langCode]}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

export default withSnackbar(ResetPassword);
